import { CheckIcon } from "@chakra-ui/icons"
import {
  Box,
  Center,
  Flex,
  Heading,
  Text,
  Icon,
  List,
  ListItem,
  ListIcon,
  Link,
} from "@chakra-ui/react"
import { StripeProductType } from "api/subscriptions"
import AButton from "components/AButton"
import { useId } from "react"
import { IconType } from "react-icons"
import { MdOutlineGroupAdd, MdOutlinePerson } from "react-icons/md"
import formatCurrency from "utils/price/formatCurrency"
import getPriceAndPerSeat from "utils/price/getPriceAndPerSeat"

// CONSTANTS
export type SubscriptionContentType = {
  titleEnd: string
  description?: string
  icon?: IconType | null
  list: string[]
  refund?: string
}

type VariantType = "individual" | "center"

// COMPONENTS
interface StripeProductCardProps {
  product: StripeProductType
  isSelected: boolean
  onSelect: (product: StripeProductType) => void
  variant: VariantType
  isConnected: boolean
  onOpen: () => void
  subscribe: (product: StripeProductType, seats?: number) => void
  isGroup?: boolean
  content: SubscriptionContentType
  isKineOrPodo?: boolean
}

const StripeProductCard = ({
  product,
  isSelected,
  onSelect,
  variant,
  isConnected,
  onOpen,
  subscribe,
  isGroup = false,
  content,
  isKineOrPodo = false,
}: StripeProductCardProps) => {
  const headingId = useId()
  const title = `Forfait ${content.titleEnd}`

  const { price, perSeat } = getPriceAndPerSeat(product, variant, isKineOrPodo)

  return (
    <Flex
      as="button"
      aria-labelledby={headingId}
      w="50%"
      key={product.id}
      flexDir={"column"}
      alignItems={"center"}
      justifyContent="space-between"
      borderRadius={12}
      position="relative"
      border={"3px solid"}
      borderColor={isSelected ? "primary.500" : "primary.100"}
      h="fit-content"
      cursor="auto"
    >
      <Box
        w="100%"
        bg="primary.300"
        borderTopLeftRadius={isSelected ? 6 : 12}
        borderTopRightRadius={isSelected ? 6 : 12}
        borderLeft="1px solid"
        borderRight="1px solid"
        borderTop="1px solid"
        borderColor="primary.500"
        p={4}
        pr={8}
        gap={2}
      >
        <Flex align="center">
          {content.icon && (
            <Icon fontSize={20} as={content.icon} color="tertiary.700" mr={2} />
          )}
          <Heading
            id={headingId}
            as="span"
            fontSize={18}
            color="tertiary.700"
            textAlign="center"
            whiteSpace="nowrap"
          >
            {title}
          </Heading>
        </Flex>
        {content.description && (
          <Text textAlign="start" fontSize="16" color="tertiary.700">
            {content.description}
          </Text>
        )}
      </Box>
      <Flex
        flexDir="column"
        alignItems="start"
        justifyContent="space-between"
        h="100%"
        w="100%"
        p={6}
        borderBottomLeftRadius={isSelected ? 6 : 12}
        borderBottomRightRadius={isSelected ? 6 : 12}
        borderLeft="1px solid"
        borderRight="1px solid"
        borderBottom="1px solid"
        borderColor="primary.500"
        bg="rgba(255, 255, 255, 0.6)"
      >
        <Box mb={6}>
          <Flex
            alignItems="baseline"
            gap={2}
            whiteSpace="nowrap"
            textDecoration={content.refund ? "line-through" : "none"}
            color={content.refund ? "common.500" : "inherit"}
          >
            {variant === "center" && (
              <Text fontSize={18} fontWeight={700}>
                Dès
              </Text>
            )}
            <Heading as="span" fontSize={26} fontWeight={700}>
              {/* we always want default price and currency */}
              {price}
              {formatCurrency(product.price.currency)}
            </Heading>
            <Text fontSize={18} fontWeight={700}>
              {" "}
              / mois
            </Text>
          </Flex>
          {content.refund && (
            <Text
              fontSize={26}
              textAlign="left"
              fontWeight={700}
              color="secondary.900"
            >
              {content.refund} / mois
            </Text>
          )}
          {perSeat && !isKineOrPodo && (
            <Text fontSize={18} mt={4}>
              {/* we always want default price and currency */}
              Soit {perSeat}
              {formatCurrency(product.price.currency)} / mois / thérapeute
            </Text>
          )}
          {isKineOrPodo && perSeat && (
            <Text fontSize={18} mt={2} fontWeight={700} textAlign="left">
              Prix par utilisateur
            </Text>
          )}
          {content.refund && (
            <Text fontSize={18} mt={2} color="secondary.900" textAlign="left">
              après remboursement via le dispositif FAMI.{" "}
              <Link
                href="https://www.ameli.fr/rhone/masseur-kinesitherapeute/exercice-liberal/vie-cabinet/forfait-d-aide-la-modernisation-et-l-informatisation-du-cabinet"
                target="_blank"
                textDecoration="underline"
              >
                En savoir plus
              </Link>
            </Text>
          )}
        </Box>
        {isKineOrPodo && content.refund && (
          <Text align="start" fontSize={16} fontWeight="bold" mb={2}>
            Toutes les fonctionnalités du forfait classique, mais aussi :
          </Text>
        )}
        {variant === "individual" ? (
          <List spacing={2}>
            {content.list.map((item, index) => (
              <ListItem textAlign="left" key={index}>
                <ListIcon as={CheckIcon} color="secondary.800" />
                {item}
              </ListItem>
            ))}
          </List>
        ) : (
          <>
            {!isKineOrPodo && (
              <Text align="start" fontSize={16} fontWeight="bold">
                Toutes les fonctionnalités du forfait Individuel, mais aussi :
              </Text>
            )}
            <List spacing={2}>
              {content.list.map((item, index) => (
                <ListItem textAlign="left" key={index}>
                  <ListIcon as={CheckIcon} color="secondary.800" />
                  {item}
                </ListItem>
              ))}
            </List>
          </>
        )}
        <AButton
          text={isConnected ? "Choisir" : "Essayer gratuitement"}
          px={isConnected ? 135 : 49}
          ml="50%"
          transform="translateX(-50%)"
          mt={6}
          onClick={() => {
            if (isGroup) {
              onSelect(product)
              onOpen()
            } else subscribe(product)
          }}
        />
      </Flex>
    </Flex>
  )
}

export default StripeProductCard
