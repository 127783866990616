import { useAppContext } from "AppContext"

const useJobIsKineOrPodo = () => {
  const { user } = useAppContext()
  const kineJobKey = "kine"
  const podoJobKey = "podo"

  return user?.osteo?.jobs?.some(
    (job) => job.key === kineJobKey || job.key === podoJobKey
  )
}

export default useJobIsKineOrPodo
