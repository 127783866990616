import { StripeProductType } from "api/subscriptions"
import formatPrice from "utils/price/formatPrice"
import {
  getCenterPerSeatPrice,
  getCenterPrice,
} from "utils/price/getCenterPrice"

const getPriceAndPerSeat = (
  product: StripeProductType,
  variant: "individual" | "center",
  isKineOrPodo = false
) => {
  if (variant === "center") {
    const price = getCenterPrice(product, isKineOrPodo ? 1 : 2)
    const perSeat = getCenterPerSeatPrice(product)
    return {
      price: formatPrice(price),
      perSeat: formatPrice(perSeat),
    }
  }
  const priceFloat =
    product.price.recurring.interval === "year"
      ? product.price.unit_amount / 100 / 12
      : product.price.unit_amount / 100

  return {
    price: formatPrice(priceFloat),
    perSeat: undefined,
  }
}

export default getPriceAndPerSeat
