import React, { useEffect, useState } from "react"
import * as Yup from "yup"

import {
  Modal,
  ModalOverlay,
  ModalContent,
  Button,
  FormControl,
  Textarea,
  Box,
  Heading,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Switch,
  FormErrorMessage,
  Image,
} from "@chakra-ui/react"
import {
  SelectedExerciceType,
  SelectedVideoOrderType,
} from "api/appointmentReason"
import { Field, Form, Formik } from "formik"
import {
  getAllTrainingVideoExerciceType,
  TrainingVideoExerciceType,
} from "api/trainingVideoExerciceType"
import ANumberInput from "components/Field/ANumberInput"
import { useQuery } from "@tanstack/react-query"
import { ChevronDownIcon } from "@chakra-ui/icons"
import AButton from "components/AButton"
import { TrainingVideoPrograms } from "api/program"

interface AddExerciceModalProps {
  video?: SelectedExerciceType
  videoGroupId: number
  isOpen: boolean
  onClose: () => void
  //onAddExercice: (value: number) => void
  setSelectedExercices?: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  setSelectedVideoOrder?: React.Dispatch<
    React.SetStateAction<SelectedVideoOrderType[]>
  >
  setFieldValue?: (field: string, value: any) => void
  isOpenedInProgram?: boolean
  videoProgram?: TrainingVideoPrograms
  isEditExercice?: boolean
}

const AddExerciceModal: React.FC<AddExerciceModalProps> = ({
  videoGroupId,
  isOpen,
  onClose,
  video,
  //onAddExercice,
  setSelectedExercices,
  setSelectedVideoOrder,
  isOpenedInProgram = false,
  videoProgram,
  setFieldValue,
  isEditExercice = false,
}) => {
  const { data: trainingVideoExerciceType } = useQuery(
    ["trainingVideoExerciceType"],
    getAllTrainingVideoExerciceType
  )
  const getInitialValue = (
    key: "repetitions" | "restDuration" | "series"
  ): number => {
    if (isExerciceFromProgram) return videoProgram?.[key] ?? 10

    if (video?.[key] !== undefined) return video[key] ?? 10

    if (hasConfig) return config[key]

    if (key === "series") return 1
    if (key === "repetitions") return 10
    if (key === "restDuration") return 30
    return 10
  }

  const config = video !== undefined ? video.config : undefined

  const hasConfig = config !== undefined && config !== null
  const isExerciceFromProgram = isOpenedInProgram && videoProgram !== undefined
  const defaultSeries = getInitialValue("series")
  const [series, setSeries] = useState<number>(defaultSeries)

  const [repetitions, setRepetitions] = useState<number>(
    getInitialValue("repetitions")
  )

  const [restDuration, setRestDuration] = useState<number>(
    getInitialValue("restDuration")
  )
  const [
    selectedTrainingVideoExerciceType,
    setSelectedTrainingVideoExerciceType,
  ] = useState<TrainingVideoExerciceType>()

  const defaultCanUseWeight = isOpenedInProgram
    ? videoProgram?.weight !== null
    : video?.canUseWeight !== undefined
    ? video?.canUseWeight
    : hasConfig
    ? config.canUseWeight
    : false
  const [canUseWeight, setCanUseWeight] = useState<boolean>(defaultCanUseWeight)

  const defaultWeight = isOpenedInProgram
    ? videoProgram?.weight ?? 0
    : video?.weight !== null && video?.weight !== undefined
    ? video.weight
    : hasConfig
    ? config.weightInKg ?? 0
    : 0
  const [weight, setWeight] = useState<number>(defaultWeight)

  const isVideoAsymmetrical = isOpenedInProgram
    ? videoProgram?.side !== undefined
    : video
    ? video.isAsymmetrical !== null
      ? video.isAsymmetrical
      : false
    : false

  const defaultSide = isExerciceFromProgram
    ? videoProgram.side
    : isVideoAsymmetrical
    ? "both"
    : null
  const [side, setSide] = useState<"both" | "left" | "right" | null>(
    defaultSide
  )

  useEffect(() => {
    if (isExerciceFromProgram && videoProgram.type) {
      const currentType = trainingVideoExerciceType?.find(
        (type) => type.id === videoProgram.type.id
      )
      if (currentType) {
        return setSelectedTrainingVideoExerciceType(currentType)
      }
    }

    if (video?.type) {
      const currentType = trainingVideoExerciceType?.find(
        (type) => type.id === video.type!.id
      )
      if (currentType) {
        return setSelectedTrainingVideoExerciceType(currentType)
      }
    }

    if (config?.types) {
      const currentType = trainingVideoExerciceType?.find(
        (type) => type.id === config.types[0].id
      )
      if (currentType) {
        return setSelectedTrainingVideoExerciceType(currentType)
      }
    }

    if (trainingVideoExerciceType && trainingVideoExerciceType.length > 0)
      return setSelectedTrainingVideoExerciceType(trainingVideoExerciceType[0])
  }, [trainingVideoExerciceType])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="blackAlpha.400" />
      <ModalContent
        maxW="356"
        maxH="90vh"
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "primary.300",
            borderRadius: "24px",
          },
        }}
      >
        <Formik
          validationSchema={Yup.object().shape({
            recommendation: Yup.string().optional(),
          })}
          initialValues={{
            recommendation: videoProgram?.recommendation ?? "",
          }}
          onSubmit={({ recommendation }, { setSubmitting }) => {
            if (!selectedTrainingVideoExerciceType) {
              return
            }

            if (setFieldValue) {
              setFieldValue("series", series)
              setFieldValue(
                "repetitions",
                selectedTrainingVideoExerciceType.key === "time" ||
                  selectedTrainingVideoExerciceType.key === "untilFailure"
                  ? null
                  : repetitions
              )
              setFieldValue("restDuration", restDuration)
              setFieldValue("type", selectedTrainingVideoExerciceType)
              setFieldValue("side", side)
              setFieldValue(
                "weight",
                canUseWeight ? (weight !== 0 ? weight : null) : null
              )
              setFieldValue("canUseWeight", canUseWeight)
              setFieldValue("recommendation", recommendation)
              setFieldValue(
                "duration",
                selectedTrainingVideoExerciceType.key === "time"
                  ? repetitions
                  : null
              )
              onClose()
              return
            }

            if (video === undefined || !setSelectedExercices) {
              return
            }
            if (isEditExercice) {
              setSelectedExercices((prev) =>
                prev.map((exercice) => {
                  if (exercice.id === video.id) {
                    return {
                      ...video,
                      duration:
                        selectedTrainingVideoExerciceType.key === "time"
                          ? repetitions
                          : null,
                      videoGroup: videoGroupId,
                      series,
                      repetitions:
                        selectedTrainingVideoExerciceType.key === "time" ||
                        selectedTrainingVideoExerciceType.key === "untilFailure"
                          ? null
                          : repetitions,
                      restDuration,
                      type: selectedTrainingVideoExerciceType,
                      side,
                      weight: weight !== 0 ? weight : null,
                      canUseWeight,
                      recommendation,
                    }
                  }
                  return exercice
                })
              )

              setSubmitting(false)
              onClose()
              return
            }

            setSelectedExercices((prev) => [
              ...prev,
              {
                ...video,
                duration:
                  selectedTrainingVideoExerciceType.key === "time"
                    ? repetitions
                    : null,
                videoGroup: videoGroupId,
                series,
                repetitions:
                  selectedTrainingVideoExerciceType.key === "time" ||
                  selectedTrainingVideoExerciceType.key === "untilFailure"
                    ? null
                    : repetitions,
                restDuration,
                type: selectedTrainingVideoExerciceType,
                side,
                weight: weight !== 0 ? weight : null,
                canUseWeight,
                recommendation,
              },
            ])
            if (setSelectedVideoOrder) {
              setSelectedVideoOrder((prev) => [
                ...prev,
                {
                  id: video.id,
                  type: "video",
                },
              ])
            }
            setSubmitting(false)
            onClose()
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Box
                pos="relative"
                borderRadius={9}
                bg="white"
                w={352}
                py="16px"
                px="16px"
              >
                <Box
                  w={"full"}
                  h={"160px"}
                  overflow={"hidden"}
                  borderRadius={"8px"}
                  mb={2}
                >
                  <Image
                    src={
                      isOpenedInProgram
                        ? videoProgram?.trainingVideo?.bunnyCDN?.previewWebP ??
                          videoProgram?.trainingVideoUploaded?.bunnyCDN.preview
                        : video?.bunnyCDN?.previewWebP
                    }
                    alt={
                      isOpenedInProgram
                        ? videoProgram?.trainingVideo?.title ??
                          videoProgram?.trainingVideoUploaded?.title
                        : video?.title
                    }
                    width="100%"
                    height="100%"
                    objectFit="cover"
                  />
                </Box>
                <Heading fontSize={18} fontWeight="bold">
                  {isOpenedInProgram
                    ? videoProgram?.trainingVideo?.group.find(
                        (g) => g.id === videoGroupId
                      )?.title ?? videoProgram?.trainingVideoUploaded?.title
                    : video?.group.find((g) => g.id === videoGroupId)?.title}
                </Heading>
                <Text fontSize={14} mb={4}>
                  niveau{" "}
                  {isOpenedInProgram
                    ? videoProgram?.trainingVideo?.level ??
                      videoProgram?.trainingVideoUploaded?.level
                    : video?.level}
                </Text>
                <Flex mb={2}>
                  <Field name="recommendation">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={
                          form.errors.recommendation &&
                          form.touched.recommendation
                        }
                        mb={1}
                      >
                        <Textarea
                          borderColor="#CBCCCF"
                          placeholder="Recommandation optionnelle pour le patient"
                          rows={2}
                          {...field}
                        />
                        <FormErrorMessage>
                          {form.errors.recommendation}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                {isVideoAsymmetrical && (
                  <Flex
                    justifyContent="space-between"
                    mb={4}
                    w="full"
                    gap={2}
                    bg={"common.200"}
                    p={1}
                    borderRadius={"4px"}
                  >
                    {(["left", "right", "both"] as const).map((option) => (
                      <Button
                        key={option}
                        onClick={() => setSide(option)}
                        bg={side === option ? "white" : "none"}
                        w={1 / 3}
                        _focus={{ boxShadow: "none" }}
                        _active={{ bg: "none" }}
                        _hover={{ bg: side === option ? "white" : "none" }}
                      >
                        {option === "left"
                          ? "Gauche"
                          : option === "right"
                          ? "Droite"
                          : "Les deux"}
                      </Button>
                    ))}
                  </Flex>
                )}
                <Flex justifyContent="space-between" mb={2}>
                  <Text>Séries</Text>
                  <ANumberInput
                    name="series"
                    min={1}
                    max={10}
                    step={1}
                    defaultValue={defaultSeries}
                    value={series}
                    setValue={setSeries}
                  />
                </Flex>
                <Flex justifyContent="space-between" mb={2}>
                  <Menu>
                    <MenuButton
                      w="fit-content"
                      px="8px"
                      py="4px"
                      border="1px solid #CBCCCF"
                      rounded="4px"
                      mb={2}
                      type="button"
                    >
                      <Flex alignItems="center" gap="6px">
                        <Text fontSize="14px" textAlign="left">
                          {selectedTrainingVideoExerciceType?.title}
                        </Text>
                        <ChevronDownIcon boxSize="20px" />
                      </Flex>
                    </MenuButton>
                    <MenuList>
                      {trainingVideoExerciceType?.map((currType, index) => (
                        <MenuItem
                          fontSize="16px"
                          key={index + 1}
                          onClick={() =>
                            setSelectedTrainingVideoExerciceType(currType)
                          }
                        >
                          {currType.title}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                  {selectedTrainingVideoExerciceType?.key !==
                    "untilFailure" && (
                    <ANumberInput
                      name="repetitions"
                      value={repetitions}
                      setValue={setRepetitions}
                      defaultValue={getInitialValue("repetitions")}
                    />
                  )}
                </Flex>
                <Flex justifyContent="space-between" mb={4}>
                  <Text>Repos (en secondes)</Text>

                  <ANumberInput
                    name="restDuration"
                    value={restDuration}
                    setValue={setRestDuration}
                    defaultValue={getInitialValue("restDuration")}
                  />
                </Flex>
                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text>Compatible avec une charge</Text>
                  <Switch
                    checked={canUseWeight}
                    defaultChecked={defaultCanUseWeight}
                    onChange={() => setCanUseWeight((prev) => !prev)}
                    colorScheme="orange"
                    name="canUseWeight"
                  />
                </Flex>

                {canUseWeight && (
                  <Flex justifyContent="space-between" mb={4}>
                    <Text>Poids (en kg)</Text>

                    <ANumberInput
                      name="weight"
                      value={weight}
                      setValue={setWeight}
                      defaultValue={defaultWeight}
                      step={1}
                    />
                  </Flex>
                )}
                <AButton
                  type="submit"
                  isLoading={isSubmitting}
                  text={
                    isOpenedInProgram || video?.repetitions
                      ? "Modifier"
                      : "Ajouter"
                  }
                  variant="custom"
                  w="100%"
                  bg="primary.200"
                  _hover={{ bg: "primary.300" }}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default AddExerciceModal
