import { Button, Flex } from "@chakra-ui/react"
import {
  FAVORIS_TAB,
  PROGRAMME_TAB,
  VIDEOS_TAB,
} from "pages/NewAppointment/components/SelectExercices/SelectExercices"
import UploadExercice from "pages/NewProgram/components/UploadExercice"
import React, { FC } from "react"

interface TabExercicesProps {
  selectedTab: number
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>
  isPageExercies?: boolean
  isCreatingProgram?: boolean
  isForKineOrPodo?: boolean
  appointmentReason?: number
}

const TabExercices: FC<TabExercicesProps> = ({
  selectedTab,
  setSelectedTab,
  isPageExercies = false,
  isCreatingProgram = false,
  isForKineOrPodo = false,
  appointmentReason,
}) => {
  return (
    <Flex
      justifyContent="space-between"
      top={isPageExercies ? "" : "-64px"}
      position={isPageExercies ? "relative" : "absolute"}
      w={isPageExercies ? "full" : "calc(100% - 120px)"}
    >
      <Flex gap="24px" left={0}>
        <Button
          p="16px"
          borderBottom="1px solid"
          borderRadius={0}
          textColor={selectedTab === VIDEOS_TAB ? "common.800" : "common.500"}
          bg="none"
          px={0}
          borderColor={
            selectedTab === VIDEOS_TAB ? "primary.600" : "transparent"
          }
          onClick={() => setSelectedTab(VIDEOS_TAB)}
          _hover={{ bg: "transparent", opacity: 0.7 }}
        >
          Vidéos
        </Button>
        {!isCreatingProgram && (
          <Button
            p="16px"
            borderBottom="1px solid"
            borderRadius={0}
            textColor={
              selectedTab === PROGRAMME_TAB ? "common.800" : "common.500"
            }
            bg="none"
            px={0}
            borderColor={
              selectedTab === PROGRAMME_TAB ? "primary.600" : "transparent"
            }
            onClick={() => setSelectedTab(PROGRAMME_TAB)}
            _hover={{ bg: "transparent", opacity: 0.7 }}
          >
            Programme
          </Button>
        )}
        <Button
          p="16px"
          borderBottom="1px solid"
          borderRadius={0}
          textColor={selectedTab === FAVORIS_TAB ? "common.800" : "common.500"}
          bg="none"
          px={0}
          borderColor={
            selectedTab === FAVORIS_TAB ? "primary.600" : "transparent"
          }
          onClick={() => setSelectedTab(FAVORIS_TAB)}
          _hover={{ bg: "transparent", opacity: 0.7 }}
        >
          Favoris
        </Button>
      </Flex>

      <UploadExercice appointmentReason={appointmentReason} />
    </Flex>
  )
}

export default TabExercices
