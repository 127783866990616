import axiosInstance from "api/axiosInstance"

export type ProNotificationType = {
  id: number
  text: string
  hasRead: boolean
  link?: string
  patient?: number
  osteo?: number
  createdAt: string
}

export const getProNotifications = async () => {
  const response = await axiosInstance.get("/pro-notification/getNotifications")
  return response.data
}

export const readProNotification = async (
  proNotification: ProNotificationType
) => {
  const response = await axiosInstance.put(
    "/pro-notification/readNotification",
    { notificationId: proNotification.id }
  )
  return response.data
}

export const readAllProNotifications = async () => {
  const response = await axiosInstance.put(
    "/pro-notification/readAllNotifications"
  )
  return response.data
}
