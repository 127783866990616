import {
  Box,
  Flex,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
} from "@chakra-ui/react"
import { Link as RouterLink } from "react-router-dom"

// Composant pour les titres d'articles
const ArticleTitle = ({ number, title }: { number: string; title: string }) => (
  <Heading as="h2" size="lg" mb={4} mt={8}>
    ARTICLE {number}. {title}
  </Heading>
)

// Composant pour les définitions
const Definition = ({
  term,
  children,
}: {
  term: string
  children: React.ReactNode
}) => (
  <ListItem mb={4}>
    <Text as="span" fontWeight="bold">
      « {term} »
    </Text>{" "}
    : {children}
  </ListItem>
)

const Section = ({
  children,
  mb = 8,
}: {
  children: React.ReactNode
  mb?: number
}) => <Box mb={mb}>{children}</Box>

const SubSection = ({
  title,
  children,
  mb = 4,
}: {
  title?: string
  children: React.ReactNode
  mb?: number
}) => (
  <Box mb={mb}>
    {title && (
      <Text fontWeight="bold" mb={2}>
        {title}
      </Text>
    )}
    {children}
  </Box>
)

// Composant principal
export default function TermsOfService() {
  return (
    <Flex direction="column" maxW="6xl" mx="auto" p={6}>
      {/* En-tête */}
      <Heading as="h1" size="xl" mb={8} textAlign="center">
        Conditions Générales de Services
      </Heading>

      {/* Introduction */}
      <Box mb={8}>
        <Text fontWeight="bold" fontSize="xl" mb={4} textAlign="center">
          Bienvenue sur Andrew
        </Text>

        <Text mb={4} textTransform="uppercase">
          LE PARTENAIRE RECONNAIT AVOIR PRIS CONNAISSANCE DES PRESENTES
          CONDITIONS GENERALES DE SERVICES (CI-APRES LES « CGS ») PREALABLEMENT
          A SON INSCRIPTION AUX SERVICES ET S'ENGAGE A LES RESPECTER.
        </Text>

        <Text mb={8} textTransform="uppercase">
          TOUTE INSCRIPTION PAR LE PARTENAIRE IMPLIQUE L'ACCEPTATION SANS
          RESERVE PAR CELUI-CI DES PRESENTES CONDITIONS GENERALES DE SERVICES.
        </Text>
      </Box>

      {/* Préambule */}
      <Box mb={8}>
        <Heading as="h2" size="lg" mb={4}>
          PRÉAMBULE
        </Heading>
        <Text mb={4}>
          La société ANDREWAPP, société par actions simplifiée, immatriculée au
          RCS de LYON sous le n° 952 463 636 et dont le siège social est situé
          au 32 RUE DU BŒUF 69005 (ci-après « ANDREWAPP ») édite et exploite
          l'application Andrew accessible à l'adresse suivante :{" "}
          <Link href="https://www.andrewapp.fr" color="blue.500" isExternal>
            https://www.andrewapp.fr
          </Link>{" "}
          (ci-après « l'Application »).
        </Text>
        <Text>
          L'Application permet à des thérapeutes partenaires d'ANDREWAPP
          (ci-après les « Partenaires ») de créer un parcours d'exercices à
          destination de leurs patients, utilisateurs de l'Application et
          précédemment reçus en consultation par les Partenaires (ci-après les «
          Patients »).
        </Text>
      </Box>

      {/* Article 1 - Définitions */}
      <Section>
        <ArticleTitle number="1" title="DÉFINITIONS" />
        <Text mb={4}>
          Les termes, mentionnés ci-dessous, ont dans les présentes Conditions
          Générales de Services, la signification suivante :
        </Text>
        <UnorderedList spacing={3} styleType="none" pl={0}>
          <Definition term="Abonnement">
            désigne la souscription par le Partenaire aux Services proposés par
            ANDREWAPP et tels que détaillés dans les présentes Conditions
            Générales de Services.
          </Definition>

          <Definition term="Anomalie">
            désigne toute non-conformité de l'Application par rapport à sa
            documentation, répétitive et reproductible.
          </Definition>

          <Definition term="Anomalie Bloquante">
            désigne une Anomalie qui empêche l'utilisation de tout ou partie des
            fonctionnalités essentielles de l'Application.
          </Definition>

          <Definition term="Anomalie Non Bloquante">
            désigne toute Anomalie autre que celles définies dans les Anomalies
            Bloquantes, notamment celles qui empêchent l'utilisation normale de
            tout ou partie des fonctionnalités non essentielles de l'Application
            ou qui peuvent être contournés.
          </Definition>

          <Definition term="Application">
            désigne l'application Andrew accessible pour les Partenaires et les
            Patients par la souscription par un Partenaire d'un Abonnement
            auprès d'ANDREWAPP. L'Application est en accès gratuit et librement
            téléchargeable.
          </Definition>

          <Definition term="Backoffice">
            désigne l'interface professionnelle réservée aux Partenaires et
            accessible sur le Site d'Andrew à l'adresse suivante :{" "}
            <Link
              as={RouterLink}
              to="https://pro.andrewapp.fr"
              color="blue.500"
              target="_blank"
            >
              www.pro.andrewapp.fr
            </Link>
          </Definition>

          <Definition term="Compte Utilisateur">
            désigne l'espace à partir duquel les Patients accèdent à
            l'Application. L'accès se fait via les Identifiants.
          </Definition>

          <Definition term="Compte Partenaire">
            désigne l'espace à partir duquel les Partenaires accèdent au
            Backoffice. L'accès se fait via les Identifiants.
          </Definition>

          <Definition term="Conditions Générales de Services ou CGS">
            désigne les présentes conditions contractuelles acceptées par les
            Partenaires lors de leur inscription et encadrant l'utilisation des
            Services.
          </Definition>

          <Definition term="Conditions Générales d'Utilisation ou CGU">
            désigne les conditions contractuelles acceptées par les Patients
            lors de leur inscription sur l'Application et encadrant leur
            utilisation de l'Application.
          </Definition>

          <Definition term="Contenu">
            désigne l'ensemble des photographies, textes, vidéo, fichiers,
            audio, podcast ou tout autre contenu accessibles à partir de
            l'Application.
          </Definition>

          <Definition term="Jours Ouvrés">
            désigne les jours habituellement travaillés en France, c'est-à-dire
            du lundi au vendredi, à l'exclusion des jours fériés, entre 9h et 18
            heures.
          </Definition>

          <Definition term="Patient">
            désigne le patient auquel le Partenaire propose un suivi
            post-consultation via l'Application.
          </Definition>

          <Definition term="Partenaire">
            désigne les ostéopathes, kinésithérapeutes, podologues et/ou autres
            professionnels de santé ayant souscrit aux SERVICES Andrew et
            acceptant les présentes Conditions Générales de Services.
          </Definition>

          <Definition term="Prestataire de Services de paiement ou PSP">
            désigne la société, détentrice d'un agrément bancaire, fournissant
            des services de paiement à ANDREWAPP afin de leur permettre
            d'encaisser les paiements des Partenaires. Le Prestataire de
            Services de Paiement de l'Opérateur est Stripe Payments Europe Ltd.,
            immatriculée sous le numéro 985420235 (DUNS number) dont le siège
            social est C/O A & L Goodbody, Ifsc, North Wall Quay, Dublin, D01
            H104, Ireland.
          </Definition>

          <Definition term="Profil du Partenaire">
            désigne le profil du Partenaire tel qu'accessible par lui et
            paramétrable depuis le Backoffice.
          </Definition>

          <Definition term="Profil Patient">
            désigne le profil Patient créé par un Partenaire dans son
            Backoffice.
          </Definition>

          <Definition term="Identifiants">
            désigne l'adresse email et le mot de passe renseignés par les
            Partenaires lors de l'inscription, puis lors de chaque connexion à
            leur Compte Partenaire sur le Backoffice.
          </Definition>

          <Definition term="Parties">
            désigne ensemble, d'une part la société ANDREWAPP et d'autre part le
            Partenaire ayant accepté les présentes CGS. Au singulier, désigne
            une seule des deux Parties.
          </Definition>

          <Definition term="Services">
            désigne l'ensemble des fonctionnalités et services proposés par
            ANDREWAPP aux Partenaires, incluant l'accès au Backoffice. Ils
            comprennent notamment le Service d'envoi d'exercices et de contenus
            personnalisé aux Patients, le télésoin et le suivi du Patient. La
            liste exhaustive des Services proposés par ANDREWAPP figure sur le
            Site et peut être consultée par le Partenaire à tout moment.
          </Definition>

          <Definition term="Site">
            désigne le site internet d'Andrew accessible à partir de l'URL{" "}
            <Link as={RouterLink} to="/" color="blue.500">
              www.andrewapp.fr
            </Link>{" "}
            et à partir duquel les Partenaires peuvent créer un Compte
            Partenaire et se connecter au Backoffice.
          </Definition>
        </UnorderedList>
      </Section>

      <Section>
        <ArticleTitle number="2" title="OBJET ET ACCEPTATION DES CGS" />
        <Text mb={4}>
          Les présentes Conditions Générales de Services ont pour objet de
          déterminer les conditions dans lesquelles le Partenaire bénéficie d'un
          abonnement aux Services via l'utilisation du Backoffice, et peut
          également faire bénéficier ses Patients de l'Application.
        </Text>

        <Text mb={4}>
          Ainsi, les Partenaires s'engagent à lire attentivement les présentes
          CGS et sont invités à les télécharger, les imprimer et à en conserver
          une copie.
        </Text>

        <Text mb={4}>
          Il est précisé que les présentes CGS, disponibles dans en pied de page
          du Backoffice au moyen d'un lien hypertexte, peuvent ainsi être
          consultées à tout moment.
        </Text>

        <Text mb={4}>
          Le Partenaire reconnaît avoir reçu d'ANDREWAPP toutes les informations
          et conseils lui permettant de bien connaître la teneur des Services,
          d'apprécier leur adéquation à ses besoins et ainsi d'accepter les
          Conditions Générales de Services en connaissance de cause.
        </Text>

        <Text mb={4}>
          Les présentes CGS, qui excluent tout lien de subordination, ne
          confèrent en aucun cas au Partenaire la qualité de salarié,
          mandataire, agent ou représentant d'ANDREWAPP. Les Parties déclarent
          en outre que les présentes CGS ne peuvent en aucun cas être
          considérées comme un acte constitutif de personne morale ou d'une
          entité juridique quelconque, et que toute forme « d'affectio
          societatis » est formellement exclue de leurs relations.
        </Text>
      </Section>

      {/* Article 3 */}
      <Section>
        <ArticleTitle number="3" title="MODIFICATION DES CGS" />
        <Text mb={4}>
          ANDREWAPP se réserve la possibilité de modifier à tout moment les
          présentes CGS.
        </Text>

        <Text mb={4}>
          Ces modifications seront notifiées au Partenaire sur un support
          durable au moins trente (30) jours avant l'entrée en vigueur des
          changements. En cas de modifications substantielles des présentes, il
          convient de distinguer les hypothèses suivantes :
        </Text>

        <UnorderedList mb={4} spacing={3}>
          <ListItem>
            Soit le Partenaire consent auxdites modifications substantielles,
            auquel cas celles-ci entreront automatiquement en vigueur à la date
            prévue dans la notification,
          </ListItem>
          <ListItem>
            Soit le Partenaire refuse les modifications substantielles, auquel
            cas il pourra résilier son Abonnement avant l'expiration du délai de
            préavis. Cette résiliation prend effet dans les quinze (15) jours
            suivant la réception de la notification par le Partenaire de sa
            décision de résilier son Abonnement.
          </ListItem>
        </UnorderedList>

        <Text mb={4}>
          Le Partenaire accepte expressément que son silence consécutif à
          l'information donnée au sujet de la modification des présentes CGS
          pendant un délai de quinze (15) jours soit considéré comme une
          acceptation des modifications apportées.
        </Text>

        <Text mb={4}>
          La résiliation des Services ne pourra être réalisée par le Partenaire
          dans l'hypothèse où :
        </Text>

        <UnorderedList mb={4} spacing={3}>
          <ListItem>
            ANDREWAPP est assujettie à une obligation légale ou réglementaire de
            changer ses conditions générales ;
          </ListItem>
          <ListItem>
            ANDREWAPP doit exceptionnellement changer ses conditions générales
            pour faire face à un danger imminent et imprévu afin de protéger les
            Partenaires et/ou les Patients contre la fraude, des logiciels
            malveillants, des spams ou tout risque en matière de cybersécurité.
          </ListItem>
        </UnorderedList>
      </Section>

      {/* Article 4 */}
      <Section>
        <ArticleTitle number="4" title="MODALITES D'ACCES AUX SERVICES" />

        <SubSection title="4.1. Modalités d'inscription des Partenaires">
          <Text mb={4}>
            Pour avoir accès au Backoffice et être référencé sur l'Application,
            le Partenaire devra notamment :
          </Text>

          <UnorderedList spacing={3} mb={4}>
            <ListItem>
              Disposer de l'ensemble des qualifications et autorisations
              requises pour exercer la profession d'ostéopathe, de
              kinésithérapeute et/ou de podologue en conformité avec la
              règlementation en vigueur au jour de son inscription, puis tout
              long de l'utilisation des Services.
            </ListItem>
            <ListItem>
              Se rendre sur le Site d'Andrew, accessible à partir de l'URL :{" "}
              <Link as={RouterLink} to="/" color="blue.500">
                www.andrewapp.fr
              </Link>
            </ListItem>
            <ListItem>
              Renseigner les informations suivantes :
              <UnorderedList pl={4} mt={2}>
                <ListItem>Nom et prénom</ListItem>
                <ListItem>Email</ListItem>
                <ListItem>Numéro de téléphone</ListItem>
                <ListItem>Adresse de lieu d'exercice</ListItem>
                <ListItem>URL de son profil Doctolib</ListItem>
                <ListItem>Mot de passe</ListItem>
                <ListItem>Informations bancaires</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              Accepter les présentes Conditions Générales de Services et la
              Politique de confidentialité.
            </ListItem>
          </UnorderedList>

          <Text mb={4}>
            Les présentes Conditions Générales de Services entreront en vigueur
            sous réserve de l'acceptation par ANDREWAPP de l'inscription du
            Partenaire.
          </Text>

          <Text mb={4}>
            En cas de refus de l'inscription du Partenaire par ANDREWAPP, cette
            dernière informera le Partenaire par e-mail.
          </Text>

          <Text mb={4}>
            ANDREWAPP se réserve le droit de refuser l'inscription d'un
            Partenaire ne satisfaisant pas aux exigences des présentes,
            notamment au regard des conditions de qualifications et compétences
            professionnelles précitées.
          </Text>
        </SubSection>

        <SubSection title="4.2. Modalités de sélection des Partenaires">
          <Text mb={4}>
            Le Backoffice étant dédié au suivi de consultations ostéopathiques,
            de kinésithérapie et/ou de podologie, les Partenaires reconnaissent
            être informés que la détention des compétences et qualités
            professionnelles requises par lesdites professions est une condition
            essentielle à l'accès au Backoffice par les Partenaires.
          </Text>

          <Text mb={4}>
            Ainsi, un thérapeute qui souhaite s'inscrire aux Services et
            bénéficier d'un accès au Backoffice en tant que Partenaire s'engage
            à :
          </Text>

          <UnorderedList spacing={3} mb={4}>
            <ListItem>Se conformer aux exigences des présentes CGS ;</ListItem>
            <ListItem>
              Fournir et maintenir à jour l'ensemble des informations demandées
              par ANDREWAPP et permettant de vérifier son statut et sa
              conformité aux obligations légales.
            </ListItem>
            <ListItem>
              Dans le cadre de l'usage des Services, respecter à tout moment les
              obligations imposées aux Partenaires au titre des obligations
              déontologiques, réglementaires et légales.
            </ListItem>
            <ListItem>
              Respecter les règles d'usage en matière de communication en ligne,
              tout particulièrement à l'égard du Patient et de tout représentant
              d'ANDREWAPP.
            </ListItem>
          </UnorderedList>
        </SubSection>
      </Section>
      <Section>
        <ArticleTitle number="5" title="SERVICES" />

        <Text mb={4}>
          Les différentes fonctionnalités sont listées sur le site d'ANDREWAPP
          et peuvent évoluer à tout moment, au choix d'ANDREWAPP.
        </Text>

        <Text mb={4}>
          Les fonctionnalités sont divisées en deux catégories :
        </Text>

        <UnorderedList mb={4} spacing={3}>
          <ListItem>
            Les fonctionnalités communes à tout type de Partenaires, accessibles
            à tous les Partenaires quelle que soit leur profession ;
          </ListItem>
          <ListItem>
            Les fonctionnalités accessibles uniquement aux Partenaires
            professionnels de santé.
          </ListItem>
        </UnorderedList>

        <Text mb={4}>
          Parmi les fonctionnalités communes à tous les Partenaires, les
          fonctionnalités suivantes sont proposées par ANDREWAPP :
        </Text>

        <SubSection title="Service de suivi du Patient">
          <Text mb={4}>
            Le Service de suivi du Patient permet aux Partenaires de réaliser un
            suivi post-consultation de leurs Patients. Aussi, dès la première
            consultation, les Partenaires peuvent, depuis leur Backoffice :
          </Text>

          <UnorderedList spacing={3} mb={4}>
            <ListItem>
              Créer des Profils Patients pour permettre à leurs Patients
              préalablement reçus en consultation et souhaitant bénéficier de
              l'Application, de recevoir un lien d'activation et de créer leur
              Compte Utilisateur. Le Partenaire est invité à compléter les
              informations suivantes relatives à son Patient :
              <UnorderedList pl={4} mt={2}>
                <ListItem>Nom et prénom</ListItem>
                <ListItem>Email</ListItem>
                <ListItem>Code postal (facultatif)</ListItem>
                <ListItem>Numéro de téléphone (facultatif)</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              Saisir des informations relatives à la pathologie et au parcours
              de soin du Patient dès la phase de consultation. Le Partenaire est
              seul responsable du choix du parcours soin créé via son
              Backoffice.
            </ListItem>
          </UnorderedList>

          <Text mb={4}>
            Le Partenaire est entièrement responsable de l'exactitude des
            données de ses Patients renseignées lors de la création d'un Profil
            Patient, puis lors du suivi de ses Patients dans le Backoffice.
          </Text>

          <Text mb={4}>
            Le Partenaire s'engage, avant toute création de Profil Patient à
            obtenir leur consentement écrit pour le traitement de leurs données
            à caractère personnel et si le Partenaire exerce dans le cadre d'un
            centre médical, pour l'accès auxdites données par les autres
            professionnels du centre.
          </Text>

          <Text mb={4}>
            L'accès par le Patient aux services proposés par l'Application est
            conditionné à l'acceptation des Conditions Générales d'Utilisation
            de l'Application par ledit Patient.
          </Text>

          <Text mb={4}>
            Aussi, dès acceptation des CGU par le Patient et, ainsi, création de
            son Compte Utilisateur, le Partenaire peut, depuis son Backoffice :
          </Text>

          <UnorderedList spacing={3} mb={4}>
            <ListItem>
              Suggérer des Contenus choisis auprès du Patient utilisateur sur la
              base d'un catalogue de Contenus
            </ListItem>
            <ListItem>
              Proposer des Contenus spécifiques et un planning d'exercices
              auprès du Patient reçu en consultation
            </ListItem>
            <ListItem>
              Délivrer des conseils de suivi ou des rappels auprès des Patients
              reçus en consultation
            </ListItem>
            <ListItem>
              Adapter les Contenus proposés à chaque Patient au fil des
              consultations en cabinet et des réponses formulées par le Patient
              lors des questionnaires de suivi
            </ListItem>
            <ListItem>
              Réaliser un suivi de l'évolution des symptômes du Patient suite à
              la consultation et à la réalisation des exercices conseillés
            </ListItem>
            <ListItem>
              Réaliser un suivi de l'évolution du stress du Patient
            </ListItem>
          </UnorderedList>

          <Text mb={4}>
            Le Backoffice permet aux Partenaires de se voir proposer des
            Contenus qu'ils peuvent proposer auprès de leurs Patients
            utilisateurs de l'Application.
          </Text>

          <Text mb={4}>
            Pour se voir proposer des Contenus spécifiques du catalogue, le
            Partenaire peut renseigner les informations suivantes au sein du
            Profil Patient sur la base des informations recueillies en
            consultation :
          </Text>

          <UnorderedList spacing={3} mb={4}>
            <ListItem>Symptomatologie</ListItem>
            <ListItem>Zone de plainte</ListItem>
            <ListItem>Motif de la consultation</ListItem>
            <ListItem>
              Détermination de l'état actuel du patient via un questionnaire de
              santé (intensité et fréquence de la douleur, mobilité, stress)
            </ListItem>
          </UnorderedList>

          <Text mb={4} fontWeight="medium">
            Le Contenu spécifique sélectionné par le Partenaire depuis son
            Backoffice pour le compte de ses Patients sera accessible par
            lesdits Patients depuis l'Application pendant une durée de trois (3)
            mois.
          </Text>
        </SubSection>

        <SubSection title="Prise de rendez-vous en ligne">
          <Text mb={4}>
            Les Patients peuvent, via l'Application, prendre rendez-vous en
            ligne avec les différents Partenaires accessibles via l'Application
            au sein de l'encart Doctolib.
          </Text>

          <Text mb={4}>
            Pour permettre à ses Patients de prendre rendez-vous en ligne et
            gérer ces rendez-vous, le Partenaire doit :
          </Text>

          <UnorderedList spacing={3} mb={4}>
            <ListItem>
              Disposer d'un compte Doctolib permettant aux Patients de réserver
              des rendez-vous en ligne
            </ListItem>
            <ListItem>
              Communiquer, lors de son inscription, l'URL de son profil
              Doctolib, dans le champ dédié à cet effet
            </ListItem>
          </UnorderedList>

          <Text mb={4}>
            Pour prendre rendez-vous en ligne avec un Partenaire via
            l'Application, le Patient doit choisir le centre thérapeutique ou
            cabinet auprès duquel il souhaite prendre rendez-vous. Les critères
            de référencement applicables par la suite sont ceux définis par
            Doctolib. Les règles de référencement applicables sur le site de
            Doctolib sont consultables à l'adresse suivante :{" "}
            <Link
              as={RouterLink}
              to="https://www.doctolib.fr"
              color="blue.500"
              target="_blank"
            >
              www.doctolib.fr
            </Link>
            . Le Partenaire s'engage à avoir accepté les termes et conditions
            applicables à son utilisation de DOCTOLIB.
          </Text>

          <Text mb={4}>
            ANDREWAPP ne saurait en aucun cas être responsable des règles de
            référencement ou toutes autres règles appliquées par DOCTOLIB, ou de
            toute conséquence née de l'utilisation de Doctolib pour la prise de
            rendez-vous en ligne.
          </Text>
        </SubSection>
      </Section>

      <Section>
        <ArticleTitle number="6" title="CONNEXION AU BACKOFFICE" />

        <Text mb={4}>
          Le Partenaire peut se connecter à son Compte Partenaire dans le
          Backoffice en renseignant ses Identifiants.
        </Text>

        <Text mb={4}>
          Les deux éléments constituant ses Identifiants sont l'adresse email
          d'inscription et le mot de passe renseigné lors de l'inscription. Le
          Partenaire sera seul responsable de l'utilisation de ses Identifiants
          ou des actions faites par l'intermédiaire de son compte.
        </Text>

        <Text mb={4}>
          Le Partenaire est seul responsable de la confidentialité de ses
          Identifiants et s'engage à veiller à leur sécurité et leur
          confidentialité.
        </Text>

        <Text mb={4}>
          Le Partenaire s'engage à informer immédiatement ANDREWAPP de toute
          perte/usurpation de ses Identifiants.
        </Text>

        <Text mb={4}>
          En aucun cas, ANDREWAPP ne saurait être tenue responsable en cas
          d'usurpation de l'identité d'un Partenaire. Tout accès et actions
          effectués à partir du Compte d'un Partenaire seront présumés être
          effectués par ce Partenaire.
        </Text>

        <Text mb={4}>
          Toute perte, détournement, ou utilisation non autorisée des
          Identifiants d'un Partenaire et leurs conséquences relèvent de sa
          seule responsabilité.
        </Text>

        <Text mb={4}>
          Dans le cas où un Partenaire divulguerait ou utiliserait ses
          Identifiants de façon contraire à leur destination, ANDREWAPP pourra
          alors suspendre l'accès aux Services incluant le Backoffice, le
          Partenaire étant tenu d'en avertir ANDREWAPP sans délai, par message
          électronique adressé à l'adresse suivante :{" "}
          <Link href="mailto:contact@andrewapp.fr" color="blue.500">
            contact@andrewapp.fr
          </Link>
        </Text>
      </Section>

      {/* Article 7 */}
      <Section>
        <ArticleTitle number="7" title="MODALITES FINANCIERES" />

        <Text mb={4}>
          Lors de l'inscription, le Partenaire a le choix de souscrire un
          Abonnement mensuel ou annuel, selon les conditions tarifaires
          détaillées en Annexe 1 « Conditions tarifaires ».
        </Text>

        <Text mb={4}>
          Le paiement s'effectue au moment de l'inscription, à échoir, par carte
          bancaire (CB, Visa, MasterCard, American Express) via le prestataire
          de service de paiement Stripe, auprès duquel le Partenaire renseigne
          directement ses coordonnées bancaires.
        </Text>

        <Text mb={4}>
          En acceptant les présentes CGS, le Partenaire accepte le prélèvement
          correspondant au prix d'Abonnement tel que détaillé en Annexe 1 «
          Conditions tarifaires » et conformément au mandat de prélèvement
          accepté auprès du PSP.
        </Text>

        <Text mb={4}>
          La durée de l'Abonnement se renouvelle automatiquement par tacite
          reconduction pour une durée identique (mensuelle ou annuelle suivant
          l'Abonnement choisi) sauf résiliation par l'Abonné dans les conditions
          précisées à l'Article 15 ci-après.
        </Text>

        <Text mb={4}>
          Le Paiement s'effectue par l'intermédiaire du Prestataire de Services
          de paiement (PSP). Pour pouvoir payer et bénéficier des Services le
          Partenaire devra accepter les Conditions Générales du PSP.
        </Text>

        <Text mb={4}>
          Il est entendu entre les Parties que les présentes CGS et les
          conditions générales du PSP sont des contrats interdépendants. Ainsi
          ANDREWAPP pourra librement, de plein droit et sans délai, mettre fin
          aux présentes dans l'hypothèse où le PSP mettrait fin à ses relations
          contractuelles avec le Partenaire.
        </Text>

        <Text mb={4}>Tout paiement par compensation est exclu.</Text>

        <Text mb={4}>
          En cas de défaut ou de retard de paiement après mise en demeure restée
          sans réponse dans un délai de trente (30) jours, ANDREWAPP se réserve
          la possibilité d'activer sa procédure de recouvrement.
        </Text>

        <Text mb={4}>
          Dans ce cas, toute somme impayée portera automatiquement intérêt au
          jour le jour jusqu'à la date de son paiement intégral en principal,
          intérêts, frais et accessoires, à un taux égal à trois fois (3) le
          taux de l'intérêt légal en vigueur, et ce, sans aucune formalité
          préalable, et nonobstant les dommages-intérêts.
        </Text>

        <Text mb={4}>
          En outre, le Partenaire sera de plein droit débiteur, à l'égard
          d'ANDREWAPP, d'une indemnité forfaitaire pour frais de recouvrement
          d'un montant de 40 euros. Lorsque des frais de recouvrement exposés
          sont supérieurs au montant de cette indemnité forfaitaire, ANDREWAPP
          pourra demander une indemnisation complémentaire, sur justification.
        </Text>

        <Text mb={4}>
          Le défaut de paiement du Partenaire entraîne automatiquement une
          suspension des Services jusqu'au règlement complet des sommes dues et
          ce par carte bancaire, virement bancaire ou prélèvement automatique.
        </Text>

        <Text mb={4}>
          Tous les frais d'impayés suite à un rejet bancaire du règlement d'un
          Partenaire resteront à la charge financière dudit Partenaire.
        </Text>
      </Section>

      {/* Article 8 */}
      <Section>
        <ArticleTitle number="8" title="OBLIGATIONS D'ANDREWAPP" />

        <Text mb={4}>
          ANDREWAPP s'engage à réaliser les Services dans le respect des règles
          applicables, telles que résultant des règles de l'art, normes
          européennes, lois, décrets, arrêtés et textes législatifs,
          réglementaires ou administratifs nationaux, locaux ou professionnels.
        </Text>

        <Text mb={4}>
          Il est expressément convenu entre les Parties qu'ANDREWAPP est soumise
          à une obligation générale de moyens et qu'elle n'est tenue par aucune
          obligation de résultat ou de moyens renforcés d'aucune sorte.
        </Text>

        <SubSection title="Hébergement - disponibilité">
          <Text mb={4}>
            ANDREWAPP s'engage à mettre tous les moyens en œuvre pour assurer
            une continuité d'accès et d'utilisation du Backoffice.
          </Text>

          <Text mb={4}>
            A ce titre, ANDREWAPP héberge les données du Backoffice auprès d'un
            hébergeur de données de santé certifié HDS dans les conditions
            précisées en Annexe 3 des présentes.
          </Text>

          <Text mb={4}>
            ANDREWAPP attire toutefois l'attention des Partenaires sur le fait
            que les protocoles actuels de communication via Internet ne
            permettent pas d'assurer de manière certaine et continue la
            transmission des échanges électroniques et Contenus.
          </Text>

          <Text mb={4}>
            ANDREWAPP ne saurait être tenue responsable des perturbations,
            coupures et anomalies qui ne sont pas de son fait et qui
            affecteraient les transmissions par le réseau Internet et plus
            généralement par le réseau de communication, quelles qu'en soient
            l'importance et la durée.
          </Text>

          <Text mb={4}>
            Les Partenaires peuvent remonter les Anomalies à ANDREWAPP, dans les
            conditions prévues à l'Article 16 « Assistance - Support Partenaire
            ».
          </Text>
        </SubSection>

        <SubSection title="Maintenance">
          <Text mb={4}>
            ANDREWAPP s'engage à mettre tout en œuvre pour faire effectuer les
            corrections techniques à apporter au Backoffice concernant les
            éventuelles Anomalies, notamment celles impactant la sécurité
            informatique du Backoffice.
          </Text>

          <Text mb={4}>
            La maintenance corrective vise la correction des Anomalies
            intervenues lors de l'utilisation de l'Application. La maintenance
            corrective n'est pas applicable en cas de :
          </Text>

          <UnorderedList spacing={2} mb={4}>
            <ListItem>
              Refus du Partenaire de collaborer avec ANDREWAPP notamment en
              répondant aux questions et demandes de renseignement
            </ListItem>
            <ListItem>
              Utilisation du Backoffice non conforme à sa destination et aux
              instructions données par ANDREWAPP
            </ListItem>
            <ListItem>
              Installation de tous logiciels, progiciels ou système
              d'exploitation incompatibles avec le Backoffice
            </ListItem>
            <ListItem>
              Défaillance des réseaux de communication électronique
            </ListItem>
            <ListItem>
              Acte volontaire de dégradation, malveillance, sabotage
            </ListItem>
            <ListItem>Détérioration due à un cas de force majeure</ListItem>
            <ListItem>
              Manquement du Partenaire à ses obligations contractuelles
            </ListItem>
          </UnorderedList>
        </SubSection>

        <SubSection title="Evolution du Backoffice">
          <Text mb={4}>
            Il est expressément convenu entre les Parties que l'Application, le
            Backoffice et les Services objets des présentes pourront faire
            l'objet d'évolutions décidées par ANDREWAPP en vue de s'adapter aux
            évolutions des technologies ou pour optimiser ses Services.
          </Text>
        </SubSection>

        <SubSection title="Sécurité">
          <Text mb={4}>ANDREWAPP s'engage à tout mettre en œuvre pour :</Text>

          <UnorderedList spacing={2} mb={4}>
            <ListItem>
              Assurer une sécurité logique et physique de ses systèmes
              d'information
            </ListItem>
            <ListItem>
              Réduire au minimum le risque d'une infraction de sécurité
            </ListItem>
          </UnorderedList>

          <Text mb={4}>
            ANDREWAPP s'engage ainsi à protéger les Contenus ou Données fournis
            par le Partenaire.
          </Text>
        </SubSection>

        <SubSection title="Conservation des données">
          <Text mb={4}>
            ANDREWAPP respecte les durées légales de conservation des données du
            Partenaire.
          </Text>

          <Text mb={4}>
            En application de l'article L. 123-22 du Code de commerce, les
            données du Partenaire seront ainsi conservées pendant dix (10) ans à
            des fins probatoires et cinq (5) ans pour prouver l'existence de la
            relation contractuelle en application de l'article 2224 du Code
            civil.
          </Text>

          <Text mb={4}>
            En cas de résiliation de l'Abonnement, les modalités de suppression
            des Contenus sont détaillées à l'Article 15.
          </Text>
        </SubSection>
      </Section>
      <Section>
        <ArticleTitle number="9" title="OBLIGATIONS DU PARTENAIRE" />

        <SubSection title="Obligations générales">
          <Text mb={4}>
            Lors de l'utilisation du Backoffice chaque Partenaire s'engage à ne
            pas porter atteinte à l'ordre public et à se conformer aux lois et
            règlements en vigueur, à respecter les droits des tiers et les
            dispositions des présentes CGS.
          </Text>

          <Text mb={4}>Chaque Partenaire a pour obligation de :</Text>

          <UnorderedList spacing={3} mb={4}>
            <ListItem>
              Se comporter de façon loyale et raisonnable à l'égard d'ANDREWAPP,
              des Patients et des tiers
            </ListItem>
            <ListItem>
              Être honnête et sincère dans les informations fournies
            </ListItem>
            <ListItem>
              Utiliser le Backoffice conformément à son objet tel que décrit
              dans les présentes CGS
            </ListItem>
            <ListItem>
              Ne pas détourner la finalité du Backoffice pour commettre des
              crimes, délits ou contraventions réprimés par le code pénal ou par
              toute autre disposition légale ou réglementaire
            </ListItem>
            <ListItem>
              Respecter les droits de propriété intellectuelle d'ANDREWAPP et
              des tiers portant sur les éléments de l'Application et les
              Contenus
            </ListItem>
            <ListItem>
              S'assurer avant de recommander l'utilisation des différents
              Contenus à ses Patients de l'adéquation desdits Contenus aux
              pathologies et à l'état de santé des Patients
            </ListItem>
            <ListItem>
              Veiller en tout temps à utiliser l'Application, ses
              fonctionnalités et Contenus conformément aux règles de sa
              profession
            </ListItem>
            <ListItem>
              Créer un contenu compatible avec l'état actuel de la science
            </ListItem>
            <ListItem>
              Respecter à tout moment et en tout point la réglementation qui lui
              est applicable, et notamment les éventuels codes de déontologies
              applicables à sa profession ainsi que le Code de santé publique
            </ListItem>
          </UnorderedList>
        </SubSection>

        <SubSection title="Obligations spécifiques">
          <Text mb={4}>
            L'accès aux Services est strictement restreint aux Partenaires
            disposant de la qualité d'ostéopathe, de kinésithérapeute, de
            podologue et/ou de professionnel de la santé au sens de la
            règlementation française en vigueur.
          </Text>

          <Text mb={4}>
            Le Partenaire est informé que selon sa profession, renseignée lors
            de son inscription, il pourra avoir accès à certaines
            fonctionnalités uniquement et non à l'ensemble des fonctionnalités.
          </Text>

          <Text mb={4}>
            Ainsi, si le Partenaire déclare ne pas être un professionnel de
            santé, il ne pourra avoir accès aux fonctionnalités qui leur sont
            réservées.
          </Text>

          <Text mb={4}>
            Le Partenaire s'engage notamment à disposer de l'ensemble des
            qualifications et autorisations requises pour exercer la profession
            d'ostéopathe, de kinésithérapeute, de podologue et/ou de
            professionnel de la santé en conformité avec la règlementation en
            vigueur au jour de son inscription, puis tout long de l'utilisation
            de l'Application.
          </Text>

          <Text mb={4}>
            Si ces conditions venaient à ne plus être remplies pour quelque
            cause que ce soit, le Partenaire s'engage à en informer ANDREWAPP
            sans délai par email à l'adresse suivante :{" "}
            <Link href="mailto:contact@andrewapp.fr" color="blue.500">
              contact@andrewapp.fr
            </Link>
            . Dans cette hypothèse, ANDREWAPP se réserve le droit de suspendre
            le Compte Partenaire à tout moment.
          </Text>
        </SubSection>
      </Section>

      {/* Article 10 */}
      <Section>
        <ArticleTitle number="10" title="RESPONSABILITE" />

        <SubSection title="Principes généraux">
          <Text mb={4}>ANDREWAPP décline toute responsabilité notamment :</Text>

          <UnorderedList spacing={3} mb={4}>
            <ListItem>
              En cas d'impossibilité d'accéder temporairement aux Services pour
              des opérations de maintenance technique ou d'actualisation des
              informations publiées
            </ListItem>
            <ListItem>
              En cas de dysfonctionnements ou d'interruptions des réseaux de
              transmission ou du matériel informatique du Partenaire utilisant
              le Backoffice ou du Patient utilisateur de l'Application
            </ListItem>
            <ListItem>
              En cas d'attaque virale, intrusion illicite dans un système de
              traitement automatisé de données
            </ListItem>
            <ListItem>
              En cas d'utilisation anormale ou d'une exploitation illicite des
              Services par un Patient, un Partenaire ou un tiers
            </ListItem>
            <ListItem>
              Relativement au contenu des sites internet tiers vers lesquels
              renvoient des liens hypertextes présents sur l'Application et le
              Backoffice (notamment DOCTOLIB, STRIPE ou LINKELLO)
            </ListItem>
            <ListItem>
              En cas de non-respect des présentes CGS imputable au Partenaire
            </ListItem>
            <ListItem>
              En cas de retard ou d'inexécution de ses obligations, lorsque la
              cause du retard ou de l'inexécution est liée à un cas de force
              majeure telle qu'elle est définie à l'Article 11 des présentes CGS
            </ListItem>
            <ListItem>
              En cas de cause étrangère non imputable à ANDREWAPP
            </ListItem>
            <ListItem>
              En cas d'agissement illicite d'un Partenaire, ou d'inexécution
              contractuelle dont un Partenaire se serait rendu coupable
            </ListItem>
            <ListItem>
              Au regard du parcours soin créé dans le Backoffice (parcours
              classique ou hors nomenclature), la sélection relevant uniquement
              du choix du Partenaire
            </ListItem>
            <ListItem>
              Concernant les Contenus visionnés/utilisés/conseillés/prescrits
              aux Patients
            </ListItem>
            <ListItem>
              En cas de réclamation des Patients relatifs au traitement de leurs
              données à caractère personnel, le consentement devant être
              collecté par le Partenaire préalablement à la création du Compte
              Patient
            </ListItem>
          </UnorderedList>

          <Text fontWeight="bold" mb={4}>
            IMPORTANT
          </Text>

          <Text mb={4}>
            ANDREWAPP décline toute responsabilité quant au caractère adapté des
            Contenus proposés par les Partenaires et des conseils délivrés pour
            un Patient donné.
          </Text>

          <Text mb={4}>
            Il est clairement entendu que le caractère adapté d'un Contenu doit
            être évalué pour chaque Patient par son thérapeute Partenaire en
            lien avec la consultation réalisée précédemment en cabinet et les
            consultations de suivi.
          </Text>

          <Text mb={4}>
            A ce titre, le Partenaire reste seul responsable des Contenus qu'il
            souhaite recommander à ses Patients utilisateurs de l'Application,
            laquelle ne saurait constituer une aide au diagnostic, ni à la
            prescription.
          </Text>
        </SubSection>

        <SubSection title="Statut d'hébergeur au sens de la LCEN">
          <Text mb={4}>
            Le Partenaire reconnaît qu'ANDREWAPP peut avoir la qualité
            d'hébergeur au sens de l'article 6 I 2° de la loi du 21 juin 2004
            pour la confiance dans l'économie numérique dite LCEN.
          </Text>

          <Text mb={4}>
            La notification des Contenus manifestement illicites par un Patient,
            un Partenaire ou tout autre tiers doit se faire par :
          </Text>

          <UnorderedList spacing={3} mb={4}>
            <ListItem>
              Courrier électronique à l'adresse :{" "}
              <Link href="mailto:contact@andrewapp.fr" color="blue.500">
                contact@andrewapp.fr
              </Link>{" "}
              ou au 07 89 34 42 76
            </ListItem>
            <ListItem>
              Courrier en recommandé avec avis de réception à : ANDREWAPP 32 rue
              du Bœuf 69005 LYON
            </ListItem>
          </UnorderedList>
        </SubSection>

        <SubSection title="Litige entre Patient et Partenaire">
          <Text mb={4}>
            Il est précisé que tout litige survenant entre un Patient et un
            Partenaire devra être traité entre eux.
          </Text>

          <Text mb={4}>
            Lorsque le Patient contacte ANDREWAPP par email à l'adresse
            contact@andrewapp.fr ou par téléphone au 07 89 34 42 76 afin de
            formuler une réclamation à propos d'un Partenaire, ANDREWAPP
            répercutera la réclamation sous 24 heures ouvrées par email auprès
            du Partenaire concerné qui sera seul responsable du traitement du
            litige.
          </Text>

          <Text mb={4}>
            En tout état de cause, la Partie concernée par la réclamation du
            Patient s'engage à répondre à cette réclamation dans les meilleurs
            délais.
          </Text>

          <Text mb={4}>
            Soucieuse de son image, ANDREWAPP invite le Partenaire à faire ses
            meilleurs efforts pour résoudre amiablement tout litige avec les
            Patients dans lequel il serait impliqué.
          </Text>
        </SubSection>
      </Section>
      <Section>
        <ArticleTitle number="11" title="FORCE MAJEURE" />

        <Text mb={4}>
          La responsabilité d'ANDREWAPP ne pourra pas être mise en œuvre si la
          non-exécution ou le retard dans l'exécution de l'une de ses
          obligations décrites dans les présentes CGS découle d'un cas de force
          majeure.
        </Text>

        <Text mb={4}>
          Il y a force majeure en matière contractuelle lorsqu'un évènement
          échappant au contrôle du débiteur, qui ne pouvait être raisonnablement
          prévu lors de la conclusion des CGS et dont les effets ne peuvent être
          évités par des mesures appropriées, empêche l'exécution de son
          obligation par le débiteur.
        </Text>

        <Text mb={4}>
          Si l'empêchement est temporaire, l'exécution de l'obligation est
          suspendue à moins que le retard qui en résulterait ne justifie la
          résolution des CGS. Si l'empêchement est définitif, les CGS sont
          résolues de plein droit et les Parties sont libérées de leurs
          obligations dans les conditions prévues aux articles 1351 et 1351-1 du
          Code civil.
        </Text>

        <Text mb={4}>
          En cas de survenance d'un évènement revêtant les caractéristiques de
          la force majeure, ANDREWAPP s'efforcera d'informer le Partenaire dès
          que possible.
        </Text>
      </Section>

      {/* Article 12 */}
      <Section>
        <ArticleTitle number="12" title="PROPRIETE INTELLECTUELLE" />

        <SubSection title="Titularité des droits de propriété intellectuelle des Partenaires">
          <Text mb={4}>
            Dans le cadre de l'utilisation du Backoffice, les Partenaires sont
            susceptibles de présenter des photographies, marques, logos, dessins
            et autres modèles leur appartenant ou appartenant à des tiers.
          </Text>

          <Text mb={4}>
            Tout Partenaire déposant un Contenu par l'intermédiaire du
            Backoffice garantit qu'il a le droit de faire une représentation de
            tous les Contenus et en tiendra ANDREWAPP indemne de toute
            réclamation/condamnation intervenue de ce fait.
          </Text>

          <Text mb={4}>
            En tout état de cause, ANDREWAPP ne saurait en aucun cas être tenue
            responsable d'un acte de contrefaçon, compte tenu de sa simple
            qualité d'hébergeur des Contenus publiés par les Partenaires.
          </Text>

          <Text mb={4}>
            Le Partenaire garantit à ANDREWAPP la jouissance paisible de tout
            élément publié dans le Backoffice.
          </Text>

          <Text mb={4}>
            À ce titre, le Partenaire s'engage, pour ces éléments, à assumer
            l'entière responsabilité de toute réclamation, revendication, action
            ou recours à l'encontre d'ANDREWAPP et/ou d'un/des Patient(s)
            émanant de tout tiers, en relation avec l'utilisation desdits
            éléments, et prendra à sa charge tous frais et tous dommages et
            intérêts qui pourraient en résulter, notamment du fait d'une
            décision de justice, y compris non définitive, ou d'un accord
            transactionnel.
          </Text>
        </SubSection>

        <SubSection title="Titularité des droits de propriété intellectuelle d'ANDREWAPP">
          <Text mb={4}>
            Le Partenaire reconnait les droits de propriété intellectuelle
            d'ANDREWAPP sur le Backoffice, ses composantes et les Contenus y
            afférent et renonce à contester ces droits sous quelle que forme que
            ce soit.
          </Text>

          <Text mb={4}>
            Les marques, logos, slogans, graphismes, photographies, animations,
            vidéos, solutions logicielles et textes et tout autre Contenu sur le
            Backoffice, à l'exception des Contenus de tiers, identifiés comme
            tel, et des Contenus de Partenaires, sont la propriété
            intellectuelle exclusive d'ANDREWAPP et ne peuvent être reproduits,
            utilisés ou représentés sans autorisation expresse sous peine de
            poursuites judiciaires.
          </Text>

          <Text mb={4}>
            En particulier, ANDREWAPP interdit expressément en tant que
            producteur de base de données :
          </Text>

          <UnorderedList spacing={3} mb={4}>
            <ListItem>
              L'extraction, par transfert permanent ou temporaire de la totalité
              ou d'une partie qualitativement ou quantitativement substantielle
              du contenu de sa base de données sur un autre support, par tout
              moyen et sous quelque forme que ce soit ;
            </ListItem>
            <ListItem>
              La réutilisation, par la mise à la disposition au public de la
              totalité ou d'une partie qualitativement ou quantitativement
              substantielle du contenu de la base, quelle qu'en soit la forme ;
            </ListItem>
            <ListItem>
              La reproduction, l'extraction ou la réutilisation, par tout moyen,
              y compris les méthodes assimilables au scraping des contenus
              (photographies, description etc…) publiés par ANDREWAPP.
            </ListItem>
          </UnorderedList>
        </SubSection>
      </Section>

      {/* Article 13 */}
      <Section>
        <ArticleTitle number="13" title="PROTECTION DES DONNEES PERSONNELLES" />

        <Text mb={4}>
          Dans le cadre du traitement de données à caractère personnel,
          ANDREWAPP s'engage à se conformer aux lois et règlements en vigueur
          encadrant les traitements de données à caractère personnel, en ce
          compris la Loi « Informatique, fichiers et libertés » du 6 janvier
          1978 modifiée par la loi du 20 juin 2018 et le Règlement (UE) 2016/679
          du 27 avril 2016 relatif à la protection des personnes physiques à
          l'égard du Traitement des Données à Caractère Personnel et à la libre
          circulation de ces données (le « RGPD »).
        </Text>

        <Text mb={4}>
          Les conditions relatives au traitement des données personnelles dans
          le cadre des Services sont précisées en Annexe 2 – Accord sur la
          protection des données.
        </Text>
      </Section>
      <Section>
        <ArticleTitle number="14" title="RÉCLAMATIONS" />

        <Text mb={4}>
          Toute question ou réclamation concernant l'utilisation ou le
          fonctionnement du Backoffice peut être formulée selon les modalités
          suivantes :
        </Text>

        <UnorderedList spacing={3} mb={4}>
          <ListItem>
            Par courrier électronique à l'adresse suivante :{" "}
            <Link href="mailto:contact@andrewapp.fr" color="blue.500">
              contact@andrewapp.fr
            </Link>
          </ListItem>
          <ListItem>Par courrier AndrewApp 32 rue du Bœuf 69005 Lyon</ListItem>
          <ListItem>
            Par téléphone au : 07 89 34 42 76 du Lundi au Vendredi de 9h à 12h
            et de 14h à 17h
          </ListItem>
        </UnorderedList>
      </Section>

      {/* Article 15 */}
      <Section>
        <ArticleTitle number="15" title="DURÉE DE L'ABONNEMENT – RÉSILIATION" />

        <Text mb={4}>
          Les CGS entrent en vigueur à compter de leur acceptation par le
          Partenaire et pour la durée de l'Abonnement.
        </Text>

        <SubSection title="Hypothèses de suspension et de résiliation">
          <SubSection title="Suspension">
            <Text mb={4}>
              En cas de violation continue des présentes dispositions ou des
              lois et règlements en vigueur, ainsi que d'absence de coopération
              et/ou de déloyauté, et s'il y a urgence à faire cesser les
              agissements constatés, ANDREWAPP pourra suspendre de plein droit
              l'Abonnement du Partenaire, sans indemnité au profit du
              Partenaire, sous réserve de lui avoir transmis sur un support
              durable, l'exposé des motifs au moment où la suspension prend
              effet.
            </Text>

            <Text mb={4}>
              Cette décision est portée à la connaissance du Partenaire par
              courriel, par l'intermédiaire d'un support durable indiquant les
              griefs reprochés ainsi que les obligations dont le non-respect est
              allégué.
            </Text>

            <Text mb={4}>
              ANDREWAPP pourra mettre le Profil du Partenaire hors ligne afin
              qu'il régularise les manquements identifiés sous dix (10) jours, à
              compter de la réception du support durable précité.
            </Text>
          </SubSection>

          <SubSection title="Résiliation suite à une suspension">
            <Text mb={4}>
              Toute suspension pourra mener à une résiliation si le Partenaire
              n'apporte pas de réponse satisfaisante aux griefs qui lui sont
              notifiés dans les dix (10) jours suivants le premier jour de
              suspension des Services.
            </Text>
          </SubSection>

          <SubSection title="Résiliation pour faute">
            <Text mb={4}>
              En cas de manquement grave ou répété, par une Partie, à ses
              obligations au titre des présentes, l'Abonnement pourra être
              résilié par l'autre Partie.
            </Text>

            <Text mb={4}>
              Il est expressément convenu que cette résiliation aura lieu de
              plein droit, dix (10) jours après l'envoi d'une mise en demeure de
              s'exécuter, restée sans effet.
            </Text>
          </SubSection>

          <SubSection title="Dénonciation du Contrat">
            <Text mb={4}>
              Chaque Partie pourra dénoncer le contrat à tout moment.
            </Text>

            <Text mb={4}>
              La dénonciation effective du Contrat prendra effet à la fin de la
              période d'engagement contractuel sélectionnée par le Partenaire
              lors de son inscription.
            </Text>
          </SubSection>
        </SubSection>

        <SubSection title="Conséquences de la résiliation">
          <Text mb={4}>
            Toute résiliation, résolution ou annulation de l'Abonnement pour
            quelque motif que ce soit entraîne automatiquement le
            déréférencement des Partenaires des Services, ainsi que l'annulation
            de l'accès aux différents Services proposés au Partenaire.
          </Text>

          <Text mb={4}>
            Cependant, le Partenaire conserve l'accès à son Backoffice pendant
            une durée supplémentaire d'un (1) mois afin de récupérer ses
            données, factures et Contenus. En revanche, il ne peut plus créer de
            nouveaux Profils Patients, de nouveaux parcours d'exercices
            personnalisés ou de nouveaux conseils personnalisés dès la
            résiliation effective.
          </Text>

          <Text mb={4}>
            Les Articles Propriété intellectuelle, Confidentialité et Données à
            caractère personnel ou toute autre article dont les stipulations,
            par nature, exigent de survivre à l'expiration de l'Abonnement,
            resteront en vigueur en cas de résiliation des présentes et ce pour
            une durée complémentaire de cinq (5) ans sauf stipulation expresse
            ou disposition législative ou réglementaire contraire.
          </Text>
        </SubSection>
      </Section>

      {/* Article 16 */}
      <Section>
        <ArticleTitle number="16" title="ASSISTANCE – SUPPORT PARTENAIRE" />

        <Text mb={4}>
          Tout Partenaire peut contacter ANDREWAPP aux Jours Ouvrés, par
          courriel à l'adresse suivante{" "}
          <Link href="mailto:support@andrewapp.fr" color="blue.500">
            support@andrewapp.fr
          </Link>
          .
        </Text>

        <Text mb={4}>
          Tout Partenaire pourra contacter ANDREWAPP concernant :
        </Text>

        <UnorderedList spacing={3} mb={4}>
          <ListItem>Un litige en cours avec un Patient</ListItem>
          <ListItem>Des Anomalies du Backoffice</ListItem>
          <ListItem>
            Des renseignements directement liés à la fourniture des Services et
            notamment concernant le fonctionnement du Backoffice
          </ListItem>
        </UnorderedList>
      </Section>

      {/* Article 17 */}
      <Section>
        <ArticleTitle number="17" title="CONFIDENTIALITÉ" />

        <Text mb={4}>
          Chacune des Parties s'engage à garder strictement confidentiels tous
          les documents et informations de nature juridique, commerciale,
          industrielle, stratégique, technique ou financière relatifs à l'autre
          Partie dont elle aurait eu connaissance à l'occasion de la conclusion
          et de l'exécution du Contrat et à ne pas les divulguer sans l'accord
          écrit préalable de l'autre Partie. Cela inclut toute transmission de
          fichiers ou documents contenant des données à caractère personnel.
        </Text>

        <Text mb={4}>
          Chaque Partie s'engage à n'utiliser les Informations Confidentielles,
          directement ou indirectement, en tout ou partie, que pour la stricte
          exécution des présentes CGS.
        </Text>

        <Text mb={4}>
          Toute divulgation fondée pourra engager la responsabilité de son
          auteur, et ce quelle que soit la cause de la divulgation.
        </Text>

        <Text mb={4}>
          Les obligations de confidentialité stipulées par la présente clause ne
          s'appliquent pas à l'intégralité ou à toute partie des Informations
          Confidentielles dans la mesure où :
        </Text>

        <UnorderedList spacing={3} mb={4}>
          <ListItem>
            Elles étaient légalement détenues par la Partie destinataire avant
            leur divulgation
          </ListItem>
          <ListItem>
            Elles ont été légalement divulguées à la Partie destinataire par une
            tierce partie sans restriction de divulgation
          </ListItem>
          <ListItem>
            Elles sont assujetties à une obligation légale de divulgation par
            tout tribunal compétent, autorité ou administration
          </ListItem>
        </UnorderedList>

        <Text mb={4}>
          La présente clause de confidentialité sera maintenue à l'expiration de
          l'Abonnement pour une durée complémentaire de cinq (5) ans.
        </Text>
      </Section>

      <Section>
        <ArticleTitle
          number="18"
          title="ANTICORRUPTION ET AUTRES ENGAGEMENTS"
        />

        <SubSection title="Anticorruption">
          <Text mb={4}>
            Le Partenaire s'engage, tant pour son propre compte que pour celui
            de ses employés, agents, filiales et sous-traitants éventuels pour
            lesquels il se porte garant, à respecter l'ensemble des lois et
            règlements applicables en matière de lutte contre la corruption, et
            notamment la loi n° 2016-1691 du 9 décembre 2016 relative à la
            transparence, à la lutte contre la corruption et à la modernisation
            de la vie économique ou toute autre loi ou réglementation qui
            viendrait à la remplacer ou la compléter.
          </Text>

          <Text mb={4}>
            La corruption désigne tout comportement par lequel une personne
            sollicite ou accepte un cadeau ou, plus généralement, une faveur, en
            vue d'obtenir ou de conserver un contrat ou tout autre avantage indu
            dans le cadre d'activités nationales ou internationales.
          </Text>

          <Text mb={4}>
            En conséquence, le Partenaire agira de manière professionnelle et
            éthique dans ses relations d'affaires, et en particulier, il
            s'engage à :
          </Text>

          <UnorderedList spacing={3} mb={4}>
            <ListItem>
              S'abstenir de toute activité de corruption de quelque nature que
              ce soit (directe ou indirecte, active ou passive, financière ou
              autre), de trafic d'influence, d'extorsion, de détournement de
              fonds ou de toute autre conduite sanctionnée par la loi
            </ListItem>
            <ListItem>
              Mettre en œuvre et maintenir des politiques et procédures
              adéquates en matière d'éthique et de lutte contre la corruption
            </ListItem>
            <ListItem>
              Informer rapidement ANDREWAPP de tout événement pouvant entraîner
              l'obtention d'un avantage indu, financier ou autre, de quelque
              valeur que ce soit, en relation avec les présentes CGS
            </ListItem>
            <ListItem>
              Fournir toute l'assistance nécessaire à ANDREWAPP pour répondre à
              une demande d'une autorité dûment autorisée en matière de lutte
              contre la corruption
            </ListItem>
          </UnorderedList>
        </SubSection>

        <SubSection title="Non-sollicitation du personnel">
          <Text mb={4}>
            Chacune des Parties renonce à engager ou faire travailler,
            directement ou par personnel interposé, tout collaborateur de
            l'autre Partie. Cet engagement est valable pendant toute la durée de
            l'Abonnement et pendant une durée minimum d'un (1) an après la fin
            de ce dernier.
          </Text>

          <Text mb={4}>
            En cas de non-respect de cette disposition, il est convenu entre les
            Parties que la Partie ayant enfreint l'obligation de
            non-sollicitation devra payer à l'autre Partie, à première demande,
            une somme égale à douze (12) mois du dernier salaire brut mensuel du
            collaborateur embauché.
          </Text>

          <Text mb={4}>
            Cette pénalité trouvera aussi à s'appliquer dans l'hypothèse où le
            collaborateur débauché agirait, pour le compte du Partenaire, en
            tant qu'auto-entrepreneur ou de dirigeant d'une société commerciale.
          </Text>
        </SubSection>

        <SubSection title="Autres exigences">
          <Text mb={4}>
            Le Partenaire s'engage à respecter toutes les exigences qui lui sont
            applicables, notamment aux regards des obligations de déclaration en
            matière sociale et d'emploi, et garantit qu'il n'emploie en aucun
            cas des étrangers non munis de l'autorisation d'exercer une activité
            salariée en France, ou dont l'autorisation n'est pas conforme à
            l'emploi considéré, conformément aux articles L. 8251-1 et suivants
            du Code du travail ou toute autre réglementation équivalente.
          </Text>
        </SubSection>
      </Section>

      {/* Article 19 */}
      <Section>
        <ArticleTitle number="19" title="GARANTIES" />

        <Text mb={4}>
          Chaque Partie s'engage envers et garantit l'autre Partie que :
        </Text>

        <UnorderedList spacing={3} mb={4}>
          <ListItem>
            Elle a le pouvoir et l'autorité d'accepter les présentes CGS, et
            qu'elle assurera et maintiendra, au cours de la relation, l'ensemble
            des autorisations éventuelles nécessaires à l'exécution de ses
            obligations
          </ListItem>
          <ListItem>
            Elle détient, ou s'est vue octroyer les droits lui permettant
            d'utiliser aux fins des présentes CGS, l'ensemble des droits de
            propriété intellectuelle nécessaires au respect de ses obligations
          </ListItem>
          <ListItem>
            Elle exécutera ses obligations en vertu des présentes CGS
            conformément à l'ensemble des lois en vigueur et en démontrant une
            diligence et des compétences raisonnables
          </ListItem>
          <ListItem>
            Elle ne fera ni n'omettra de faire quoi que ce soit pouvant
            entraîner pour l'autre Partie une violation de toute loi ou
            règlement en vigueur
          </ListItem>
          <ListItem>Elle ne dénigrera pas l'autre Partie</ListItem>
        </UnorderedList>
      </Section>

      {/* Article 20 */}
      <Section>
        <ArticleTitle number="20" title="VALIDITÉ DES CGS" />

        <Text mb={4}>
          Si l'une quelconque des stipulations des présentes CGS venait à être
          déclarée nulle au regard d'une disposition législative ou
          réglementaire en vigueur et/ou d'une décision de justice ayant
          autorité de la chose jugée, elle sera réputée non écrite mais
          n'affectera en rien la validité des autres clauses qui demeureront
          pleinement applicables.
        </Text>

        <Text mb={4}>
          Une telle modification ou décision n'autorise en aucun cas le
          Partenaire à méconnaitre les présentes CGS.
        </Text>
      </Section>

      <Section>
        <ArticleTitle number="21" title="DISPOSITIONS GÉNÉRALES" />

        <Text mb={4}>
          Chacune des Parties s'engage à toujours se comporter vis-à-vis de
          l'autre, comme un partenaire loyal et de bonne foi, et notamment à
          porter sans délai à la connaissance de l'autre Partie, tout différend
          ou toute difficulté qu'elle pourrait rencontrer dans le cadre de
          l'exécution des présentes CGS.
        </Text>

        <Text mb={4}>
          Le fait que l'une des Parties n'ait pas exigé l'application d'une
          clause quelconque des présentes CGS, que ce soit de façon permanente
          ou temporaire, ne pourra en aucun cas être considéré comme une
          renonciation à ladite clause.
        </Text>

        <Text mb={4}>
          En cas de difficulté d'interprétation entre l'un quelconque des titres
          figurant en tête des clauses, et l'une quelconque de celles-ci, les
          titres seront déclarés inexistants.
        </Text>
      </Section>

      {/* Article 22 */}
      <Section>
        <ArticleTitle number="22" title="ASSURANCES" />

        <Text mb={4}>
          En acceptant les présentes CGS le Partenaire confirme disposer d'une
          assurance responsabilité civile professionnelle médicale souscrite
          auprès d'une compagnie d'assurance notoirement solvable et s'engage à
          la maintenir à jour pendant toute la durée d'utilisation du
          Backoffice.
        </Text>
      </Section>

      {/* Article 23 */}
      <Section>
        <ArticleTitle number="23" title="COMPÉTENCE ET DROIT APPLICABLE" />

        <Text mb={4} textTransform="uppercase">
          Les présentes CGS ainsi que les relations entre les Parties sont
          régies par le droit français.
        </Text>

        <Text mb={4} textTransform="uppercase">
          En cas de différend survenant entre les Parties au sujet de
          l'interprétation, de l'exécution ou de la résiliation des présentes,
          les Parties s'efforceront de le régler à l'amiable.
        </Text>

        <Text mb={4} textTransform="uppercase">
          Dans l'hypothèse où la tentative de résolution amiable échouerait ou
          ne serait pas envisagée, le litige sera confié au tribunal compétent
          de Lyon.
        </Text>
      </Section>

      <Divider my={8} borderColor="gray.300" />

      <Heading as="h2" size="lg" mb={8} color="gray.800">
        ANNEXE 1 - CONDITIONS TARIFAIRES
      </Heading>

      <Box mb={8}>
        <Text fontWeight="bold" fontSize="xl" mb={4}>
          Andrew Osteo
        </Text>

        <Table variant="simple" mb={8}>
          <Thead>
            <Tr>
              <Th>Type d'abonnement</Th>
              <Th>Tarif</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Abonnement Annuel</Td>
              <Td>358,80 EUROS TTC PAR AN</Td>
            </Tr>
            <Tr>
              <Td>Abonnement Mensuel</Td>
              <Td>39,90 EUROS TTC PAR MOIS</Td>
            </Tr>
          </Tbody>
        </Table>

        <Text fontWeight="bold" fontSize="xl" mb={4}>
          Andrew Kiné
        </Text>

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Type d'abonnement</Th>
              <Th>Tarif</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Abonnement Annuel</Td>
              <Td>358,80 EUROS TTC PAR AN</Td>
            </Tr>
            <Tr>
              <Td>Abonnement Mensuel</Td>
              <Td>39,90 EUROS TTC PAR MOIS</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>

      <Divider my={8} borderColor="gray.300" />

      <Heading as="h2" size="lg" mb={8} color="gray.800">
        ANNEXE 2 - ACCORD SUR LA PROTECTION DES DONNÉES
      </Heading>

      {/* En-tête et parties */}
      <Section>
        <Text fontWeight="bold" mb={4}>
          Entre les soussignés :
        </Text>

        <Text mb={4}>
          Société ANDREWAPP, Société par actions simplifiée, immatriculée au
          Registre du Commerce et des Sociétés de Lyon sous le numéro 952 463
          636, dont le siège social est sis 32 RUE DU BŒUF 69005 LYON
        </Text>

        <Text fontStyle="italic" mb={4}>
          Ci-après, dénommée « ANDREWAPP »,
        </Text>

        <Text fontWeight="bold" fontStyle="italic" mb={4}>
          D'une part
        </Text>

        <Text fontWeight="bold" mb={4}>
          ET
        </Text>

        <Text mb={4}>
          Le thérapeute partenaire d'ANDREWAPP référencé sur l'Application
          Andrew et ayant accepté les Conditions Générales de Services
        </Text>

        <Text fontStyle="italic" mb={4}>
          Ci-après, dénommée le « Partenaire »,
        </Text>

        <Text fontWeight="bold" fontStyle="italic" mb={4}>
          D'autre part
        </Text>

        <Text fontStyle="italic" mb={4}>
          Ci-après, collectivement dénommées « Les Parties »
        </Text>
      </Section>

      {/* Préambule */}
      <Section>
        <Text fontWeight="bold" mb={4}>
          ETANT PRÉALABLEMENT RAPPELÉ QUE :
        </Text>

        <Text mb={4}>
          Le présent Accord, établi en application des articles 26 et 28 du
          règlement (UE) 2016/679 du Parlement européen et du Conseil du 27
          avril 2016, a pour objet de définir les conditions dans lesquelles les
          Parties s'engagent à effectuer les opérations de Traitement de Données
          personnelles définies en Appendice 1 selon les modalités édictées
          ci-après.
        </Text>

        <Text mb={4}>
          Dans le cadre de l'utilisation du Backoffice Andrew et des services
          qui y sont proposés, les Parties s'engagent à respecter la
          réglementation en vigueur applicable au Traitement de Données
          personnelles et, en particulier, le règlement (UE) 2016/679 précité
          applicable depuis le 25 mai 2018 (ci-après, « le Règlement Général sur
          la Protection des Données »), la loi « Informatique et libertés »
          n°78-17 du 6 janvier 1978 modifiée et les réglementations en matière
          de propriété industrielle et intellectuelle.
        </Text>
      </Section>

      {/* Article 1 - Définitions */}
      <ArticleTitle number="1" title="DÉFINITIONS" />
      <Section>
        <Text mb={4}>
          Pour les besoins des présentes et nonobstant toutes autres définitions
          prévues dans l'Accord, les termes suivants auront le sens qui est
          donné ci-dessous :
        </Text>

        <UnorderedList spacing={4} styleType="none" pl={0}>
          <ListItem>
            <Text as="span" fontWeight="bold">
              « Abonnement »
            </Text>{" "}
            : désigne la souscription par le Partenaire aux Services proposés
            par ANDREWAPP et tels que détaillés dans les Conditions Générales de
            Services.
          </ListItem>

          <ListItem>
            <Text as="span" fontWeight="bold">
              « Accord »
            </Text>{" "}
            : Désigne le présent Accord sur la Protection des Données complété
            par les appendices suivants :
            <UnorderedList mt={2} pl={4}>
              <ListItem>
                <Text as="span" fontStyle="italic">
                  Appendice 1
                </Text>{" "}
                : Description des Traitements de données concernés
              </ListItem>
              <ListItem>
                <Text as="span" fontStyle="italic">
                  Appendice 2
                </Text>{" "}
                : Politique de sécurité
              </ListItem>
            </UnorderedList>
          </ListItem>

          <ListItem>
            <Text as="span" fontWeight="bold">
              « Application »
            </Text>{" "}
            : désigne l'application Andrew accessible pour les Partenaires par
            la souscription d'un Abonnement auprès d'ANDREWAPP. L'Application
            est en accès gratuit et librement téléchargeable pour les Patients.
          </ListItem>

          <ListItem>
            <Text as="span" fontWeight="bold">
              « Autorité de régulation »
            </Text>{" "}
            : Désigne toute autorité compétente en matière de protection des
            Données Personnelles.
          </ListItem>

          <Definition term="Backoffice">
            désigne l'interface professionnelle réservée aux Partenaires et
            accessible sur le Site d'Andrew à l'adresse suivante
            www.pro.andrewapp.fr.
          </Definition>

          <Definition term="Conditions Générales de Services ou CGS">
            désigne les conditions contractuelles acceptées par les Partenaires
            lors de l'Abonnement aux Services.
          </Definition>

          <Definition term="Conditions Générales d'Utilisation ou CGU">
            désigne les conditions contractuelles acceptées par les Patients
            lors de leur inscription sur l'Application et encadrant leur
            utilisation de l'Application.
          </Definition>

          <Definition term="Destinataire autorisé">
            Désigne un administrateur, un employé ou un Sous-traitant ultérieur
            qui a un besoin légitime d'accéder aux Données personnelles dans le
            cadre de l'exécution des Services ou de l'Organisation interne
            d'ANDREWAPP.
          </Definition>

          <Definition term="Données personnelles">
            Désigne toute information relative à une personne physique
            identifiée ou qui peut être identifiée comme telle, soit directement
            soit indirectement par regroupement d'informations, par référence à
            un numéro d'identification ou à des éléments qui lui sont propres :
            nom, adresse, numéro de téléphone, adresse IP, adresse email,
            identifiant/login, mot de passe, données de connexion, etc.
          </Definition>

          <Definition term="Finalité autorisée">
            Désigne l'objet du Traitement de Données personnelles mis en œuvre
            par les Parties, conformément à l'Appendice 1.
          </Definition>

          <Definition term="Instructions">
            Désigne l'ensemble des instructions écrites par le Responsable de
            Traitement à destination du Sous-traitant.
          </Definition>

          <Definition term="Règlementation relative à la protection des données">
            Désigne la réglementation en vigueur relative à la protection des
            Données Personnelles et, en particulier :
            <UnorderedList mt={2} pl={4}>
              <ListItem>
                le règlement (UE) 2016/679 du Parlement européen et du Conseil
                du 27 avril 2016 applicable depuis le 25 mai 2018 dit «
                Règlement Général sur la Protection des Données » ou « RGPD »
              </ListItem>
              <ListItem>
                la loi « Informatique et libertés » n°78-17 du 6 janvier 1978
                modifiée
              </ListItem>
              <ListItem>
                toute législation entrant en vigueur et susceptible d'affecter
                les Traitements visés par le présent Accord
              </ListItem>
              <ListItem>
                tout guide de bonnes pratiques et référentiels publié par les
                Autorités de régulation (CNIL) ou le Comité Européen sur la
                Protection des Données
              </ListItem>
            </UnorderedList>
          </Definition>

          <Definition term="Personne concernée">
            Désigne toute personne physique dont les Données personnelles font
            l'objet d'un Traitement.
          </Definition>

          <Definition term="Responsable de Traitement">
            Désigne la personne qui détermine les moyens et les finalités du
            Traitement. Lorsqu'un Traitement conjoint est mis en place par les
            Parties, celles-ci seront regardées comme « Responsables de
            Traitement conjoints » dès lorsqu'elles déterminent conjointement
            les moyens et les finalités du Traitement.
          </Definition>

          <Definition term="Patient">
            désigne le patient auquel le Partenaire propose un suivi
            post-consultation via l'Application.
          </Definition>

          <Definition term="Services">
            désigne l'ensemble des fonctionnalités et services proposés par
            ANDREWAPP aux Partenaires.
          </Definition>

          <Definition term="Site">
            désigne le site internet d'Andrew accessible à partir de l'URL
            www.andrewapp.fr et à partir duquel les Partenaires peuvent créer un
            Compte Partenaire et se connecter au Backoffice.
          </Definition>

          <Definition term="Sous-traitant">
            Désigne la personne physique ou morale amenée à réaliser des
            opérations de Traitement pour le compte et sur instructions du
            Responsable de Traitement. L'entité agissant pour le compte et sur
            instruction du Sous-traitant est qualifiée de « Sous-traitant(s)
            ultérieur(s) ».
          </Definition>

          <Definition term="Traitement">
            Désigne toute opération ou tout ensemble d'opérations effectuées ou
            non à l'aide de procédés automatisés et appliquées à des Données
            personnelles ou des ensembles de Données personnelles, telles que la
            collecte, l'enregistrement, l'organisation, la structuration, la
            conservation, l'adaptation ou la modification, l'extraction, la
            consultation, l'utilisation, la communication par transmission, la
            diffusion ou toute autre forme de mise à disposition, le
            rapprochement ou l'interconnexion, la limitation, l'effacement ou la
            destruction.
          </Definition>

          <Definition term="Violation de Données personnelles">
            Désigne une faille de sécurité qui entraîne accidentellement ou
            illicitement l'accès à ou la destruction, la perte, l'altération, la
            divulgation non autorisée de Données Personnelles transmises,
            stockées ou traitées.
          </Definition>
        </UnorderedList>
      </Section>

      <Section>
        <ArticleTitle number="2" title="DURÉE" />
        <Text mb={4}>
          Le présent Accord entre en vigueur à compter de l'acceptation des
          Conditions Générales de Services par le Partenaire et reste applicable
          pour toute la période de l'Abonnement souscrit par le Partenaire.
        </Text>
      </Section>

      {/* Article 3 */}
      <Section>
        <ArticleTitle number="3" title="RÔLE DES PARTIES" />
        <Text mb={4}>
          Les Parties conviennent qu'ANDREWAPP et le Partenaire auront les
          qualités suivantes :
        </Text>

        <UnorderedList spacing={4} mb={4}>
          <ListItem>
            Dans le cadre de l'exécution des Services, ANDREWAPP est amenée à
            traiter des Données personnelles pour le compte du Partenaire
            lorsque celui-ci crée une fiche Patient pour un Patient ne disposant
            pas encore de Compte Utilisateur : dans ce cas, ANDREWAPP agit en
            tant que Sous-traitant du Partenaire, Responsable du traitement
          </ListItem>
          <ListItem>
            Dans le cadre de l'utilisation de l'Application, ANDREWAPP est
            amenée à traiter les Données personnelles de Patients saisies par le
            Partenaire pour la création de programmes de contenus personnalisés
            et le suivi de l'évolution des symptômes du Patient : dans ce cas,
            le Partenaire et ANDREWAPP auront la qualité de Responsables de
            traitement conjoints
          </ListItem>
          <ListItem>
            Dans le cadre de la mise à disposition de l'Application auprès des
            Patients, ANDREWAPP est amenée à traiter les Données personnelles de
            Patients souhaitant accéder à leur Compte utilisateur : dans ce cas,
            ANDREWAPP agit en tant que Responsable du traitement des données en
            exécution des Conditions générales d'utilisation acceptées par le
            Patient lors de son inscription
          </ListItem>
        </UnorderedList>
      </Section>

      {/* Article 4 */}
      <Section>
        <ArticleTitle number="4" title="CONSIGNES ET CONFORMITÉ" />
        <Text mb={4}>
          Dans le cadre de la mise en œuvre des opérations de traitement,
          ANDREWAPP et le Partenaire s'engagent à :
        </Text>

        <UnorderedList spacing={3} mb={4}>
          <ListItem>
            Respecter la Règlementation relative à la protection des données
          </ListItem>
          <ListItem>
            Coopérer et se conformer aux instructions ou aux décisions de toute
            Autorité de régulation
          </ListItem>
          <ListItem>
            Ne pas faire ou omettre de faire ou permettre que quelque chose soit
            fait qui amènerait l'autre Partie à enfreindre la Loi sur la
            protection des données
          </ListItem>
        </UnorderedList>

        <Text mb={4}>
          En sus des obligations listées au 4.1 du présent Accord, lorsqu'elle
          agit en qualité de Sous-traitant, ANDREWAPP s'engage à :
        </Text>

        <UnorderedList spacing={3} mb={4}>
          <ListItem>
            Traiter uniquement les Données personnelles nécessaires aux
            Finalités autorisées conformément aux Instructions définies en
            Appendice 1, y compris en ce qui concerne les éventuels transferts
            de Données personnelles vers un pays tiers ou à une organisation
            internationale
          </ListItem>
          <ListItem>
            Respecter les Instructions formulées par le Responsable de
            Traitement, et s'assurer de leur respect par les Destinataires
            autorisés
          </ListItem>
          <ListItem>
            Ne pas traiter les Données personnelles à d'autres fins
          </ListItem>
        </UnorderedList>
      </Section>

      {/* Article 5 */}
      <Section>
        <ArticleTitle number="5" title="OBLIGATIONS GÉNÉRALES DES PARTIES" />

        <SubSection title="Sous-traitance">
          <Text mb={4}>
            Description du traitement et instructions du Responsable de
            traitement.
          </Text>

          <Text mb={4}>
            La nature des opérations réalisées sur les données est :
          </Text>

          <UnorderedList spacing={3} mb={4}>
            <ListItem>
              ☒ Hébergement, archivage et sauvegarde des données pour les
              besoins du Service
            </ListItem>
            <ListItem>
              ☒ Réalisation de prestations de maintenance qui peuvent uniquement
              nécessiter l'accès à certaines données personnelles pour résoudre
              bug, bogue, anomalies, mise à jour…
            </ListItem>
            <ListItem>
              ☒ Utilisation pour contacter les Patients aux fins de validation
              de la création de leur compte patient
            </ListItem>
          </UnorderedList>
        </SubSection>

        <SubSection title="Responsabilité autonome">
          <Text mb={4}>
            Dans le cadre des opérations réalisées en qualité de Responsables du
            traitement autonomes, les Parties s'engagent à respecter les
            obligations imposées par la Règlementation relative à la protection
            des données personnelles, et seront individuellement responsables de
            toute non-conformité que leur serait imputable.
          </Text>
        </SubSection>

        <SubSection title="Responsabilité Conjointe">
          <Text mb={4}>
            Dans le cadre des opérations réalisées en qualité de Responsables de
            traitement conjoints, les Parties s'engagent conformément à
            l'article 26 du RGPD, à :
          </Text>

          <UnorderedList spacing={3} mb={4}>
            <ListItem>
              Respecter le principe de licéité du traitement prévu à l'article 6
              du RGPD
            </ListItem>
            <ListItem>
              Traiter les Données personnelles uniquement pour les seules
              finalités des traitements renseignés en Appendice 1
            </ListItem>
            <ListItem>
              Garantir la confidentialité des Données personnelles traitées dans
              le cadre du présent Accord
            </ListItem>
            <ListItem>
              Prendre en compte, s'agissant des outils, produits, applications
              ou services utilisés, les principes de protection des données dès
              la conception et de protection des données par défaut
            </ListItem>
            <ListItem>
              Informer les Personnes concernées conformément aux articles 13 et
              14 du RGPD
            </ListItem>
          </UnorderedList>
        </SubSection>
      </Section>

      {/* Article 8 */}
      <Section>
        <ArticleTitle
          number="8"
          title="DESTINATAIRES DES DONNÉES PERSONNELLES"
        />

        <Text mb={4}>Les Parties garantissent mutuellement qu'elles :</Text>

        <UnorderedList spacing={3} mb={4}>
          <ListItem>
            Restreignent l'accès aux Données personnelles aux seuls
            Destinataires autorisés ayant besoin d'avoir accès aux Données
            personnelles ou d'en connaître
          </ListItem>
          <ListItem>
            Imposent aux Destinataires autorisés des obligations de
            confidentialité et de sécurité équivalentes à celles contenues dans
            le présent Accord
          </ListItem>
          <ListItem>
            Mettent tous les moyens en œuvre pour s'assurer que les
            Sous-traitants ultérieurs respectent la Règlementation relative à la
            protection des données
          </ListItem>
          <ListItem>
            Prennent des mesures raisonnables pour assurer la fiabilité de tous
            les Destinataires autorisés ayant accès aux Données personnelles
          </ListItem>
          <ListItem>
            Ne divulguent aucune Donnée personnelle à une autre personne sans en
            avoir préalablement informé l'autre
          </ListItem>
        </UnorderedList>
      </Section>

      {/* Article 9 */}
      <Section>
        <ArticleTitle
          number="9"
          title="INFORMATION ET DROITS DES PERSONNES CONCERNÉES"
        />

        <Text mb={4}>
          Dans le cadre de la mise en œuvre des Services, les Parties
          conviennent qu'ANDREWAPP informe les Personnes concernées des
          opérations de Traitement au travers de la Politique de confidentialité
          accessibles au sein de l'Application.
        </Text>

        <Text mb={4}>
          S'agissant des Traitements de données opérés par les Parties en
          qualité de Responsables de traitement conjoint, ANDREWAPP est désigné
          comme principal point de contact pour les Personnes concernées au sein
          de la Politique de confidentialité accessible au sein de
          l'Application.
        </Text>

        <Text mb={4}>
          Chaque Partie avise l'autre dans les meilleurs délais, par courrier
          électronique dans un délai de cinq (5) jours ouvrés. Chaque Partie
          informera l'autre Partie de l'avancée du processus de traitement de la
          demande d'exercice de droit.
        </Text>
      </Section>

      {/* Article 10 */}
      <Section>
        <ArticleTitle
          number="10"
          title="TRANSFERT DE DONNÉES EN DEHORS DE L'UNION EUROPÉENNE"
        />

        <Text mb={4}>
          Lorsque les Parties agissent en qualité de Responsables du traitement
          conjoints ou de Sous-traitant, elles s'engagent à ne pas transférer
          les Données personnelles en dehors de l'Union européenne.
        </Text>
      </Section>

      {/* Article 11 */}
      <Section>
        <ArticleTitle number="11" title="RESPONSABILITÉ" />

        <Text mb={4}>
          Chaque Partie est responsable des dommages directs subis par l'autre
          et découlant de la violation par elle, ses employés, ses
          représentants, et, le cas échéant, ses Sous-traitants ultérieurs (y
          compris les Destinataires autorisés) de ses obligations en vertu du
          présent Accord.
        </Text>

        <Text mb={4}>
          A ce titre, chaque Partie s'engage à mettre en œuvre les moyens
          nécessaires et raisonnables pour assurer la sécurité des traitements,
          et sera dès lors responsable des dommages liés à une défaillance de
          sécurité des Données personnelles qui lui serait imputable.
        </Text>
      </Section>

      {/* Article 12 */}
      <Section>
        <ArticleTitle number="12" title="AUDIT ET CONTRÔLE" />

        <Text mb={4}>
          ANDREWAPP met à la disposition du Responsable du traitement les
          informations nécessaires pour démontrer le respect des obligations
          prévues au présent Accord et pour permettre, le cas échéant, la
          réalisation d'audits.
        </Text>

        <Text mb={4}>
          Le Partenaire peut commander la réalisation d'audits objectifs de
          conformité à la Règlement sur la protection des données sur les
          opérations de Traitement réalisées par ANDREWAPP en sa qualité de
          Sous-traitant aux fins de l'exécution des Services dans les conditions
          définies ci-après :
        </Text>

        <UnorderedList spacing={3} mb={4}>
          <ListItem>
            L'audit est diligenté par un auditeur extérieur sélectionné par les
            Parties pour son expertise, son indépendance et son impartialité
          </ListItem>
          <ListItem>
            Les éléments confidentiels confiés à ANDREWAPP par d'autres
            contractants, ou tout autre élément protégé par le secret des
            affaires sera exclu du périmètre de l'audit
          </ListItem>
          <ListItem>
            L'auditeur sélectionné est lié aux Parties par un accord de
            confidentialité et/ou par le secret professionnel
          </ListItem>
          <ListItem>
            Les frais de l'audit de conformité seront à la charge du Partenaire
          </ListItem>
          <ListItem>
            Le Partenaire ne saurait commander des audits de conformité que dans
            la limite d'un (1) audit par an
          </ListItem>
        </UnorderedList>
      </Section>

      {/* Article 13 */}
      <Section>
        <ArticleTitle number="13" title="SORT DES DONNÉES" />

        <Text mb={4}>
          En cas de résiliation de l'Abonnement, le sort des Données
          personnelles des Patients est détaillé au sein des Conditions
          Générales de Services.
        </Text>
      </Section>

      {/* Article 14 */}
      <Section>
        <ArticleTitle number="14" title="DISPOSITIONS GÉNÉRALES" />

        <SubSection title="Modification de l'Accord">
          <Text mb={4}>
            Cet Accord ne peut être modifié que par écrit signé par les
            représentants dûment autorisés de chacune des Parties.
          </Text>

          <Text mb={4}>
            En cas de modification de la Règlementation sur la protection des
            données, il est convenu que les Parties pourront réviser les
            dispositions du présent Accord et négocier de bonne foi pour s'y
            conformer.
          </Text>
        </SubSection>

        <SubSection title="Droit applicable et juridiction">
          <Text mb={4} textTransform="uppercase">
            Les présentes CGS ainsi que les relations entre les Parties sont
            régies par le droit français.
          </Text>

          <Text mb={4} textTransform="uppercase">
            En cas de différend survenant entre les Parties au sujet de
            l'interprétation, de l'exécution ou de la résiliation des présentes,
            les Parties s'efforceront de le régler à l'amiable.
          </Text>

          <Text mb={4} textTransform="uppercase">
            Dans l'hypothèse où la tentative de résolution amiable échouerait ou
            ne serait pas envisagée, le litige sera confié au tribunal
            compétent.
          </Text>
        </SubSection>
      </Section>

      <Divider my={8} borderColor="gray.300" />

      <Heading as="h2" size="lg" mb={8} color="gray.800">
        ANNEXE 3 - MODALITÉS D'HÉBERGEMENT
      </Heading>

      <Section>
        <Text mb={4}>
          La solution d'hébergement des données de santé d'ANDREWAPP doit se
          conformer aux exigences de la loi française qui impose que les données
          de santé à caractère personnel gérées par des professionnels de santé
          soient hébergées chez un hébergeur certifié HDS (Hébergeur de Données
          de Santé).
        </Text>

        <Text mb={4}>
          ANDREWAPP s'engage à faire appel uniquement à des hébergeurs de
          données qualifiés et certifiés HDS conformément au référentiel de
          certification établi par l'ANS (Agence du Numérique en Santé).
        </Text>

        <Text mb={4}>
          Les données de santé présentes sur l'Application et le Backoffice sont
          hébergées dans des datacenters situés dans l'Union Européenne,
          conformément aux exigences du RGPD.
        </Text>

        <Text mb={4}>L'hébergeur s'engage à :</Text>

        <UnorderedList spacing={3} mb={4}>
          <ListItem>
            Garantir la sécurité, la confidentialité et l'intégrité des données
            de santé stockées
          </ListItem>
          <ListItem>
            Mettre en œuvre les mesures techniques et organisationnelles
            appropriées pour protéger les données contre toute destruction,
            perte, altération, divulgation ou accès non autorisé
          </ListItem>
          <ListItem>
            Assurer un niveau de disponibilité et de résilience constant des
            systèmes et services de traitement
          </ListItem>
          <ListItem>
            Mettre en place des procédures de sauvegarde régulières des données
          </ListItem>
          <ListItem>
            Respecter l'ensemble des exigences du référentiel de certification
            HDS
          </ListItem>
        </UnorderedList>

        <Text mb={4}>Les modalités d'hébergement incluent notamment :</Text>

        <UnorderedList spacing={3} mb={4}>
          <ListItem>Des sauvegardes quotidiennes des données</ListItem>
          <ListItem>Un plan de continuité d'activité</ListItem>
          <ListItem>Un plan de reprise d'activité</ListItem>
          <ListItem>Un chiffrement des données en transit et au repos</ListItem>
          <ListItem>Une surveillance continue des systèmes</ListItem>
          <ListItem>
            Des tests réguliers des procédures de sauvegarde et de restauration
          </ListItem>
        </UnorderedList>

        <Text mb={4}>
          En cas de changement d'hébergeur, ANDREWAPP s'engage à :
        </Text>

        <UnorderedList spacing={3} mb={4}>
          <ListItem>
            Sélectionner uniquement un nouvel hébergeur certifié HDS
          </ListItem>
          <ListItem>Assurer une transition sécurisée des données</ListItem>
          <ListItem>
            Maintenir le même niveau de service et de sécurité
          </ListItem>
          <ListItem>
            Informer les Partenaires de tout changement substantiel concernant
            l'hébergement de leurs données
          </ListItem>
        </UnorderedList>

        <Text mb={4} fontWeight="medium">
          La certification HDS de l'hébergeur garantit :
        </Text>

        <UnorderedList spacing={3} mb={4}>
          <ListItem>
            La mise en œuvre d'un système de management de la qualité
          </ListItem>
          <ListItem>
            La mise en œuvre d'un système de management de la sécurité de
            l'information
          </ListItem>
          <ListItem>L'exploitation d'une infrastructure sécurisée</ListItem>
          <ListItem>
            Le respect des exigences spécifiques aux données de santé
          </ListItem>
        </UnorderedList>

        <Text mt={8} fontStyle="italic">
          ANDREWAPP s'engage à maintenir à jour cette annexe et à informer les
          Partenaires de toute modification substantielle des modalités
          d'hébergement.
        </Text>
      </Section>
    </Flex>
  )
}
