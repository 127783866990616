import { Box, Flex, Heading, Text, useDisclosure } from "@chakra-ui/react"
import React, { useRef } from "react"
import convertBreakLineToBr from "utils/convertLineBreakToBr"
import { PatientAppointmentType } from "../../../api/patient"
import AEditButton from "../../../components/AEditButton"
import ASkeleton from "../../../components/ASkeleton"
import EditRecommandation from "./EditRecommandation"

interface IProps {
  isLoading?: boolean
  appointment?: PatientAppointmentType
  setAppointment?: React.Dispatch<
    React.SetStateAction<PatientAppointmentType | null>
  >
  type: "osteoAdvice" | "internalNote" | "secondaryInternalNote"
}

const addButtonText = {
  osteoAdvice: "Note pour le patient",
  internalNote: "Note complémentaire privée",
  secondaryInternalNote:
    "Ajouter une note supplémentaire (non visible par le patient)",
}

const title = {
  osteoAdvice: "Recommandation patient",
  internalNote: "Vos notes privées",
  secondaryInternalNote: "",
}

const emptyText = {
  osteoAdvice: "Aucune recommandation pour le moment",
  internalNote: "Aucune note complémentaire pour le moment",
  secondaryInternalNote: "Aucune note privée pour le moment",
}

const Recommendation: React.FC<IProps> = ({
  isLoading,
  appointment,
  setAppointment,
  type,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)
  if (!appointment || !appointment[type]) return null
  return (
    <>
      <Flex
        pos="relative"
        backgroundColor={"white"}
        borderRadius={8}
        direction={"column"}
        paddingX={4}
        paddingTop={4}
        paddingBottom={3}
        gap={4}
        flex={1}
      >
        <Heading as="h3" fontSize={14}>
          {title[type]}
        </Heading>
        <ASkeleton isLoaded={!isLoading}>
          <Box
            bg={type === "osteoAdvice" ? "common.100" : "osteow.secondary.200"}
            p={2}
            borderRadius={9}
            pos="relative"
            borderColor={
              type === "osteoAdvice" ? "common.300" : "osteow.secondary.400"
            }
            borderWidth={1}
          >
            {appointment[type] ? (
              <Box
                fontSize={14}
                lineHeight="18px"
                dangerouslySetInnerHTML={{
                  __html: convertBreakLineToBr(appointment[type]),
                }}
              />
            ) : (
              <Text fontSize={14} lineHeight="18px" as={"i"}>
                {emptyText[type]}
              </Text>
            )}
          </Box>
          <AEditButton
            pos="absolute"
            top={2}
            right={2}
            onClick={onOpen}
            ariaLabel="Modifier la recommandation"
          />
        </ASkeleton>
      </Flex>
      <EditRecommandation
        onClose={onClose}
        isOpen={isOpen}
        cancelRef={cancelRef}
        appointment={appointment}
        setAppointment={setAppointment}
        type={type}
      />
    </>
  )
}

export default Recommendation
