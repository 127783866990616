import { Flex } from "@chakra-ui/react"
import { VideoGroupCategoryType } from "api/appointmentReason"
import AButton from "components/AButton"
import AInputSearch from "components/AInputSearch"
import React from "react"
import getVideoGroupCategory from "utils/getVideoGroupCategory"

interface IProps {
  categories: VideoGroupCategoryType[]
  categoryFilter: string | null
  setCategoryFilter: React.Dispatch<React.SetStateAction<string | null>>
  setSearch: React.Dispatch<React.SetStateAction<string>>
  restrictions: string[]
  restrictionFilter: string | null
  setRestrictionFilter: React.Dispatch<React.SetStateAction<string | null>>
}
const FilterExercices = ({
  categories,
  categoryFilter,
  setCategoryFilter,
  setSearch,
  restrictions,
  restrictionFilter,
  setRestrictionFilter,
}: IProps) => {
  return (
    <Flex direction="column" gap={8} pt={4}>
      <Flex direction={"column"} gap={4}>
        <AInputSearch
          placeholder="Rechercher un exercice"
          canClear
          setSearch={setSearch}
        />

        <Flex gap={4}>
          {categories.map((category) => (
            <AButton
              text={getVideoGroupCategory(category)}
              variant={"custom"}
              onClick={() => {
                if (categoryFilter === category) setCategoryFilter(null)
                else setCategoryFilter(category)
              }}
              bg={categoryFilter === category ? "black" : "white"}
              color={categoryFilter === category ? "white" : "common.700"}
              _hover={{
                bg: categoryFilter === category ? "#1a0000" : "primary.200",
              }}
              fontWeight={400}
              key={`category_${category}`}
              borderRadius={"8px"}
              border="none"
              fontSize={14}
            />
          ))}

          {restrictions &&
            restrictions.map((restriction) => (
              <AButton
                text={restriction}
                variant={"custom"}
                onClick={() => {
                  setRestrictionFilter(
                    restrictionFilter === restriction ? null : restriction
                  )
                }}
                bg={restrictionFilter === restriction ? "black" : "white"}
                color={
                  restrictionFilter === restriction ? "white" : "common.700"
                }
                _hover={{
                  bg:
                    restrictionFilter === restriction
                      ? "#1a0000"
                      : "primary.200",
                }}
                fontWeight={400}
                borderRadius={"8px"}
                border="none"
                fontSize={14}
                key={`restriction_${restriction}`}
              />
            ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default FilterExercices
