import {
  Flex,
  Image,
  Text,
  Box,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react"
import { TrainingVideoPrograms } from "api/program"
import { TrainingVideoExerciceType } from "api/trainingVideoExerciceType"
import AddExerciceModal from "pages/NewAppointment/components/SelectExercices/components/AddExerciceModal"
import AddUploadedExerciceModal from "pages/NewAppointment/components/SelectExercices/components/AddUploadedExerciceModal"
import { RiPencilLine } from "react-icons/ri"

interface EditableExerciceCardProps {
  values: TrainingVideoPrograms
  setFieldValue: (field: string, value: any) => void
  trainingVideoExerciceType: TrainingVideoExerciceType[]
  isAsymmetrical: boolean
}

const FullExerciceCard = ({
  //trainingVideoProgram,
  values,
  setFieldValue,
  trainingVideoExerciceType,
  isAsymmetrical,
}: EditableExerciceCardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Flex
        flex={1}
        border={"1px"}
        borderColor={"common.200"}
        borderRadius={"10px"}
        direction={"column"}
        p={2}
        gap={"10px"}
        pos={"relative"}
      >
        <IconButton
          onClick={onOpen}
          variant="ghost"
          aria-label="Modifier"
          color="common.500"
          icon={<RiPencilLine />}
          _hover={{ bg: "none" }}
          pos={"absolute"}
          right={0}
          top={0}
        />
        <Flex w={"full"} gap={"10px"}>
          <Flex
            w={"64px"}
            height={"64px"}
            borderRadius={"8px"}
            overflow={"hidden"}
          >
            <Image
              src={
                values.trainingVideo?.bunnyCDN?.preview ??
                values.trainingVideoUploaded?.bunnyCDN.preview
              }
              alt={
                values.trainingVideo?.title ??
                values.trainingVideoUploaded?.title
              }
              width="100%"
              height="100%"
              objectFit="cover"
            />
          </Flex>
          <Flex direction={"column"}>
            <Text fontSize={14} fontWeight={700}>
              {values.trainingVideo?.group[0].title ??
                values.trainingVideoUploaded?.title}
            </Text>
            <Text fontSize={12}>
              niveau{" "}
              {values.trainingVideo?.level ??
                values.trainingVideoUploaded?.level}
            </Text>
            <Flex gap={2}>
              {isAsymmetrical && values.side === null && (
                <Text fontSize={12} color={"primary.800"}>
                  Veuillez sélectionner le côté
                </Text>
              )}
              {values.side !== null && (
                <Box
                  bg={"primary.200"}
                  border={"none"}
                  borderRadius={"4px"}
                  p={1}
                >
                  <Text fontSize={12}>
                    {values.side === "both"
                      ? "De chaque côté"
                      : values.side === "left"
                      ? "Côté gauche"
                      : values.side === "right"
                      ? "Côté droit"
                      : ""}
                  </Text>
                </Box>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex
          w={"full"}
          bg={"common.200"}
          justify={"space-between"}
          fontSize={12}
          borderRadius={"4px"}
          px={2}
        >
          <Flex align={"center"} gap={1}>
            <Text fontWeight={700} fontSize={14} maxW={"35px"}>
              {values.series}
            </Text>
            <Flex gap={1}>
              <Text>séries</Text>
            </Flex>
          </Flex>
          {values.duration === null ? (
            values.type?.key === "untilFailure" ? (
              <Flex gap={1}>
                <Text fontWeight="bold">jusqu'à l'échec</Text>
              </Flex>
            ) : (
              <Flex gap={1}>
                <Text fontWeight="bold">{values.repetitions}</Text>
                <Text>répétitions</Text>
              </Flex>
            )
          ) : (
            <Flex gap={1}>
              <Text fontWeight="bold">{values.duration}</Text>
              <Text>temps (sec)</Text>
            </Flex>
          )}
          <Flex align={"center"} gap={1}>
            <Text fontWeight={700} fontSize={14} maxW={"35px"}>
              {values.restDuration}
            </Text>
            <Flex gap={1}>
              <Text>repos (en s)</Text>
            </Flex>
          </Flex>
          {values.weight && (
            <Flex align={"center"} gap={1}>
              <Text fontWeight={700} fontSize={14} maxW={"35px"}>
                {values.weight}
              </Text>
              <Flex gap={1}>
                <Text fontWeight={700}>kg</Text>
                <Text>charge</Text>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
      {isOpen && values.trainingVideo && (
        <AddExerciceModal
          isOpen={isOpen}
          onClose={onClose}
          videoGroupId={values.trainingVideo.group[0].id}
          setFieldValue={setFieldValue}
          isOpenedInProgram={true}
          videoProgram={values}
        />
      )}
      {isOpen && values.trainingVideoUploaded && (
        <AddUploadedExerciceModal
          isOpen={isOpen}
          onClose={onClose}
          video={values.trainingVideoUploaded}
          setFieldValue={setFieldValue}
          isOpenedInProgram={true}
          videoProgram={values}
        />
      )}
    </>
  )
}

export default FullExerciceCard
