import { Box, Flex, Heading, Image, Link, Text } from "@chakra-ui/react"
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom"
import arrowBack from "../../assets/arrow-back.svg"
import React, { useEffect, useMemo } from "react"
import { useQuery } from "@tanstack/react-query"
import {
  PatientAppointmentType,
  CheckupType,
  getPatient,
  PatientType,
} from "../../api/patient"
import ASkeleton from "../../components/ASkeleton"
import Infos from "../Patient/components/Infos"
import Recommendation from "./components/Recommendation"
import AButton from "../../components/AButton"
import { format, isAfter, isBefore } from "date-fns"
import AppointmentCard from "../Patient/components/AppointmentCard"
import { AddIcon } from "@chakra-ui/icons"
import AppointmentStats from "./components/AppointmentStats"
import Training from "./components/Training"
import addTwoMonths from "../../utils/addTwoMonth"
import { useAppContext } from "AppContext"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import { retryCollaboratorUnauthorized } from "utils/collaboratorUnauthorized"
import useOnCollaboratorUnauthorized from "hooks/useOnCollaboratorUnauthorized"
import { retryPatientNoAccessRight } from "utils/patientNoAccessRight"
import useOnPatientNoAccessRight from "hooks/useOnPatientNoAccessRight"
import AppointmentMedias from "pages/Appointment/components/AppointmentMedias"
import getUtcDate from "utils/getUtcDate"
import useIsJobKineOrPodo from "hooks/useIsJobKineOrPodo"
import Stats from "pages/Patient/components/Stats"

const Appointment = () => {
  const { patientId, appointmentId } = useParams()
  const navigate = useNavigate()
  const [patient, setPatient] = React.useState<PatientType | undefined>(
    undefined
  )

  const onPatientNoAccessRight = useOnPatientNoAccessRight()
  const onCollaboratorUnauthorized = useOnCollaboratorUnauthorized()
  const { currentScope } = useAppContext()
  const [currentAppointment, setCurrentAppointment] =
    React.useState<PatientAppointmentType | null>(null)
  const getPatientParams = {
    patientId,
    ...getCurrentScopeParams(currentScope),
  }

  const { isLoading } = useQuery<PatientType>(
    ["getPatient", patientId],
    () => getPatient(getPatientParams),
    {
      enabled: !!patientId,
      retry: (failureCount, error: any) =>
        retryPatientNoAccessRight(failureCount, error) &&
        retryCollaboratorUnauthorized(failureCount, error),
      onSuccess: (data) => {
        setPatient(data)
      },
      onError: (error: any) => {
        onPatientNoAccessRight(error)
        onCollaboratorUnauthorized(error)
      },
    }
  )

  // if the appointment has an explorer media with tag not starting with "bebe_" then it is not a baby appointment
  const isStandardAppointment = currentAppointment?.explorerMedias?.some(
    (media) => !media.tag || !media.tag?.key?.startsWith("bebe_")
  )

  useEffect(() => {
    setCurrentAppointment(
      !appointmentId
        ? null
        : patient?.appointments?.find(
            (appointment) => appointment.id === parseInt(appointmentId)
          ) ?? null
    )
  }, [appointmentId, patient])

  const appointmentCheckups = useMemo(() => {
    if (!currentAppointment) return []

    return (
      patient?.checkups?.filter(
        (checkup: CheckupType) =>
          isAfter(
            new Date(checkup.createdAt),
            new Date(currentAppointment.meetingDate)
          ) &&
          isBefore(
            new Date(checkup.createdAt),
            addTwoMonths(new Date(currentAppointment.meetingDate))
          )
      ) || []
    )
  }, [patient, currentAppointment])

  const isJobKineOrPodo = useIsJobKineOrPodo()

  if (!appointmentId || !currentAppointment) return null

  const isAnAncientOsteo =
    currentAppointment.job === null || currentAppointment.job === undefined
      ? true
      : isJobKineOrPodo && currentAppointment.job.key === "osteo"

  return (
    <Box flex={1} mr={6}>
      <>
        <Flex>
          <Box flex={1}>
            <ASkeleton isLoaded={!isLoading}>
              <Heading as="h1" mt={7} mb={8} fontSize={28} color="tertiary.500">
                {patient?.firstname} {patient?.lastname}
              </Heading>
            </ASkeleton>
            <Flex>
              <Infos
                isLoading={isLoading}
                patient={patient}
                setPatient={setPatient}
              />
            </Flex>
            <Heading mt={7} mb={8} fontSize={28} color="tertiary.500">
              Consultation du{" "}
              {format(
                getUtcDate(currentAppointment?.meetingDate),
                "dd/MM/yyyy"
              )}
            </Heading>
            <Flex gap={4} direction={"row"} mb={4} wrap={"wrap"}>
              <Recommendation
                appointment={currentAppointment}
                setAppointment={setCurrentAppointment}
                type="internalNote"
              />
              <Recommendation
                appointment={currentAppointment}
                setAppointment={setCurrentAppointment}
                type="secondaryInternalNote"
              />
              <Recommendation
                appointment={currentAppointment}
                setAppointment={setCurrentAppointment}
                type="osteoAdvice"
              />
            </Flex>
            {currentAppointment.trainingPlaylist &&
              currentAppointment.trainingPlaylist.exercices.length !== 0 && (
                <Training
                  appointment={currentAppointment}
                  patientId={patientId}
                  setAppointment={setCurrentAppointment}
                />
              )}
            <AppointmentMedias
              selectedMedias={currentAppointment.explorerMedias ?? []}
              appointment={currentAppointment}
              patientId={patientId}
            />
            <Stats patient={patient} />
          </Box>
          <Box w={348} ml={6}>
            {isAnAncientOsteo ? (
              <AButton
                text="Nouveau motif de consultation"
                fontFamily="Montserrat"
                variant="kine"
                mb={6}
                leftIcon={<AddIcon fontSize={12} />}
                onClick={() => {
                  navigate(`/patients/${patientId}/appointments/new/treament/0`)
                }}
              />
            ) : (
              <AButton
                text="Créer une consultation"
                px={120}
                py={3}
                mt={3}
                mb={10}
                w="100%"
                fontFamily="Montserrat"
                onClick={() => {
                  navigate(`/patients/${patientId}/appointments/new`)
                }}
                leftIcon={<AddIcon fontSize={12} />}
              />
            )}

            <Box py={4} pl={4} pr={27} bg="primary.200" borderRadius={9}>
              <Heading mb={3} fontSize={18}>
                Consultations
              </Heading>
              {patient?.appointments
                ?.sort((a, b) =>
                  isBefore(new Date(a.createdAt), new Date(b.createdAt))
                    ? 1
                    : -1
                )
                .map(({ id, meetingDate, osteo, academie, center }) => (
                  <AppointmentCard
                    key={id}
                    link={`/patients/${patientId}/appointments/${id}`}
                    date={format(getUtcDate(meetingDate), "dd/MM/yyyy")}
                    name={`${osteo.firstname} ${osteo.lastname}`}
                    academie={academie}
                    center={center}
                    isSelected={id === parseInt(appointmentId)}
                  />
                ))}
            </Box>
          </Box>
        </Flex>
      </>
    </Box>
  )
}

export default Appointment
