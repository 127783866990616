import { Box } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { getStripeConnectLoginLink } from "api/teleconsultation"
import React from "react"

interface IProps {
  accountId: string
}

const StripeConnectLoginButton = ({ accountId }: IProps) => {
  const { data } = useQuery({
    queryKey: ["stripeConnectLoginLink", accountId],
    queryFn: () => getStripeConnectLoginLink(accountId),
  })
  if (!data?.url) return null
  return (
    <a
      href={data!.url}
      target="_blank"
      rel="noreferrer"
      style={{ display: "block" }}
    >
      <Box
        bg="transparent"
        color="primary.800"
        borderColor="primary.800"
        border="1px solid"
        borderRadius="52px"
        w="fit-content"
        px={4}
        py={2}
        fontWeight="bold"
        _hover={{
          bg: "primary.800",
          color: "white",
          borderColor: "primary.400",
        }}
        _focus={{
          bg: "primary.800",
          color: "white",
          borderColor: "primary.400",
        }}
      >
        Voir mon profil Stripe
      </Box>
    </a>
  )
}

export default StripeConnectLoginButton
