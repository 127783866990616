import { useNavigate, useParams } from "react-router-dom"
import { updateAppointment } from "api/appointments"
import { useMutation, useQuery } from "@tanstack/react-query"
import useToast from "hooks/useToast"
import React, { useEffect, useState } from "react"
import { getPatient, PatientAppointmentType, PatientType } from "api/patient"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import { useAppContext } from "AppContext"
import { retryPatientNoAccessRight } from "utils/patientNoAccessRight"
import { retryCollaboratorUnauthorized } from "utils/collaboratorUnauthorized"
import useOnPatientNoAccessRight from "hooks/useOnPatientNoAccessRight"
import useOnCollaboratorUnauthorized from "hooks/useOnCollaboratorUnauthorized"
import EducationalContent from "pages/NewAppointment/components/EducationalContent/EducationalContent"
import { EducationalContentType } from "api/educationalContent"

const EditEducationalContent = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { patientId, appointmentId } = useParams()
  const { currentScope } = useAppContext()
  const updateAppointmentMutation = useMutation(updateAppointment)

  const toast = useToast()
  const navigate = useNavigate()

  const [currentAppointment, setCurrentAppointment] =
    React.useState<PatientAppointmentType | null>(null)
  const [selectedEducationalContents, setSelectedEducationalContents] =
    useState<EducationalContentType[]>([])

  const getPatientParams = {
    patientId,
    ...getCurrentScopeParams(currentScope),
  }
  const onPatientNoAccessRight = useOnPatientNoAccessRight()
  const onCollaboratorUnauthorized = useOnCollaboratorUnauthorized()

  const { isLoading } = useQuery<PatientType>(
    ["getPatient", patientId],
    () => getPatient(getPatientParams),
    {
      enabled: !!patientId,
      retry: (failureCount, error: any) =>
        retryPatientNoAccessRight(failureCount, error) &&
        retryCollaboratorUnauthorized(failureCount, error),
      onSuccess: (data) => {
        setCurrentAppointment(
          !appointmentId
            ? null
            : data?.appointments?.find(
                (appointment) => appointment.id === parseInt(appointmentId)
              ) ?? null
        )
      },
      onError: (error: any) => {
        onPatientNoAccessRight(error)
        onCollaboratorUnauthorized(error)
      },
    }
  )

  useEffect(() => {
    if (currentAppointment) {
      if (currentAppointment.educationalContents) {
        setSelectedEducationalContents(currentAppointment.educationalContents)
      }
    }
  }, [currentAppointment])
  const isAppointmentKineType =
    currentAppointment?.treatment &&
    currentAppointment?.job !== null &&
    (currentAppointment?.job.key === "kine" ||
      currentAppointment?.job.key === "podo")
  const onBack = () => {
    if (isAppointmentKineType) {
      navigate(
        `/patients/${patientId}/treatment/${currentAppointment.treatment?.id}/appointment/${currentAppointment.id}`
      )
      return
    }

    navigate(`/patients/${patientId}/appointments/${appointmentId}`)
  }

  const onNext = async () => {
    if (currentAppointment && currentAppointment.id) {
      await updateAppointmentMutation.mutateAsync(
        {
          id: currentAppointment.id.toString(),
          data: {
            educationalContents: selectedEducationalContents,
          },
        },
        {
          onSuccess: () => {
            toast({
              status: "success",
              title: "Modifications enregistrées",
            })
          },
          onError: () => {
            toast({
              status: "error",
              title: "Une erreur s'est produite",
            })
          },
        }
      )
      if (isAppointmentKineType) {
        navigate(
          `/patients/${patientId}/treatment/${currentAppointment.treatment?.id}/appointment/${currentAppointment.id}`
        )
      } else {
        navigate(`/patients/${patientId}/appointments/${appointmentId}`)
      }
    }
  }

  if (!currentAppointment) return null

  return (
    <EducationalContent
      onNext={onNext}
      onBack={onBack}
      selectedEducationalContent={selectedEducationalContents}
      setSelectedEducationalContent={setSelectedEducationalContents}
      isSubmitting={isSubmitting}
    />
  )
}
export default EditEducationalContent
