import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import {
  createTeleconsultation,
  CreateTeleconsultationType,
  editTeleconsultation,
  EditTeleconsultationType,
  TeleconsultationInputType,
} from "api/teleconsultation"
import { Field, Form, Formik, FormikHelpers } from "formik"
import * as Yup from "yup"
// import getMeQueryKeys from "constants/queryKeys/getMe"
import { InfoOutlineIcon } from "@chakra-ui/icons"
import { PatientType } from "api/patient"
import { TeleconsultationType } from "api/teleconsultation"
import { useAppContext } from "AppContext"
import ASubmitButton from "components/ASubmitButton"
import SearchBar from "components/SearchBar"
import { set } from "date-fns"
import useToast from "hooks/useToast"
import { useState } from "react"
import userHasCenterInCurrentScope from "../../utils/currentScope/userHasCenterInCurrentScope"

const INITIAL_VALUES_NO_TELECONSULTATION = {
  scheduledDate: "",
  startTime: "",
  endTime: "",
  price: 0,
  isPaid: false,
  isFinished: false,
  privateNote: "",
} as TeleconsultationInputType

const VALIDATION_SCHEMA = Yup.object().shape({
  scheduledDate: Yup.string().required("Date requise"),
  startTime: Yup.string().required("Heure de début requise"),
  price: Yup.number()
    .min(0, "Le montant ne peut être négatif")
    .integer()
    .required("Un montant est requis"),
  isPaid: Yup.boolean().required("Statut requis"),
  isFinished: Yup.boolean().required("Statut requis"),
})

// HELPERS
const getInitialValues = (
  teleconsultation?: TeleconsultationType
): TeleconsultationInputType => {
  if (teleconsultation === undefined) {
    return INITIAL_VALUES_NO_TELECONSULTATION
  }
  const { scheduledDate, ...rest } = teleconsultation

  const date = new Date(scheduledDate)

  return {
    ...rest,
    scheduledDate: date.toISOString().split("T")[0],
    startTime:
      date.getHours().toString().padStart(2, "0") +
      ":" +
      date.getMinutes().toString().padStart(2, "0"),
  }
}

// COMPONENTS
interface TeleconsultationModalProps {
  isOpen: boolean
  onClose: () => void
  teleconsultation?: TeleconsultationType
  isEditing: boolean
  osteoId: number
  patientId?: string
}
const TeleconsultationModal = ({
  teleconsultation,
  isOpen,
  onClose,
  isEditing = false,
  osteoId,
  patientId,
}: TeleconsultationModalProps) => {
  const { currentScope, user } = useAppContext()
  const initialValues = getInitialValues(teleconsultation)
  const [patient, setPatient] = useState<PatientType | undefined>(undefined)
  const toast = useToast()

  const queryClient = useQueryClient()

  const addOrEditMutation = useMutation({
    mutationFn: (
      values: CreateTeleconsultationType | EditTeleconsultationType
    ) => {
      const mutationValues = {
        ...values,
        osteo: osteoId,
        ...(userHasCenterInCurrentScope(user, currentScope)
          ? { center_id: currentScope?.id }
          : {}),
      }

      return teleconsultation && osteoId
        ? editTeleconsultation({
            ...mutationValues,
            id: teleconsultation.id,
          })
        : createTeleconsultation(mutationValues)
    },
  })

  const convertToCreateType = (
    values: TeleconsultationInputType & { startTime: string }
  ): CreateTeleconsultationType | EditTeleconsultationType => {
    const { scheduledDate, startTime, ...rest } = values

    const date = set(new Date(scheduledDate), {
      hours: parseInt(startTime.split(":")[0]),
      minutes: parseInt(startTime.split(":")[1]),
    })

    const formattedValues = {
      ...rest,
      scheduledDate: date.toISOString(),
    }
    if (teleconsultation !== undefined) {
      setPatient(teleconsultation.patient)
      return {
        ...formattedValues,
        patient: teleconsultation.patient.id,
        osteo: osteoId,
      } as EditTeleconsultationType
    }
    if (patientId !== undefined) {
      return {
        ...formattedValues,
        patient: parseInt(patientId),
        osteo: osteoId,
      } as CreateTeleconsultationType
    }
    if (!patient || patient.id === undefined) {
      throw new Error("Patient is undefined or has no id")
    }
    return {
      ...formattedValues,
      patient: patient.id,
      osteo: osteoId,
    } as CreateTeleconsultationType
  }

  const onSubmit = async (
    values: TeleconsultationInputType,
    formikHelpers: FormikHelpers<TeleconsultationInputType>
  ) => {
    try {
      const createTypeValues = convertToCreateType(values)
      await mutateTeleconsultation(createTypeValues, formikHelpers)
    } catch (error) {
      console.error(error)
      toast({
        status: "error",
        title: "Une erreur s'est produite",
      })
    } finally {
      formikHelpers.setSubmitting(false)
    }
  }

  const mutateTeleconsultation = async (
    values: CreateTeleconsultationType,
    { setSubmitting }: FormikHelpers<TeleconsultationInputType>
  ) => {
    return addOrEditMutation.mutateAsync(values, {
      onSuccess: async (submittedTeleconsultation) => {
        await queryClient.invalidateQueries(["getAllTeleconsultation"])

        await queryClient.invalidateQueries({
          queryKey: [
            "getTeleconsultationByPatientId",
            patientId?.toString() || "",
          ],
          exact: true,
        })

        toast({
          status: "success",
          title: teleconsultation
            ? "Téléconsultation reprogrammée"
            : "Téléconsultation créée",
        })
        setPatient(undefined)
        onClose()
      },
      onSettled: () => {
        setSubmitting(false)
      },
    })
  }
  return (
    <Modal
      motionPreset="slideInBottom"
      onClose={() => {
        setPatient(undefined)
        onClose()
      }}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxW={377} borderRadius={8} p={"24px"}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={VALIDATION_SCHEMA}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <ModalCloseButton aria-label="Fermer" />
              <ModalHeader mb={6} p={0} fontSize={18}>
                {teleconsultation ? "Reprogrammer" : "Nouvelle"}{" "}
                téléconsultation
              </ModalHeader>
              <Flex direction={"column"} gap={"12px"} w={"full"}>
                <Field name="scheduledDate">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={
                        form.errors.scheduledDate && form.touched.scheduledDate
                      }
                    >
                      <Flex
                        w={"full"}
                        justify={"space-between"}
                        align={"center"}
                      >
                        <FormLabel fontWeight={700}>Date</FormLabel>
                        <Input
                          {...field}
                          placeholder="Date"
                          bg="common.100"
                          type="date"
                          w={"fit-content"}
                          min={new Date().toISOString().split("T")[0]}
                        />
                      </Flex>
                      <FormErrorMessage>
                        {form.errors.scheduledDate}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="startTime">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={
                        form.errors.startTime && form.touched.startTime
                      }
                    >
                      <Flex
                        w={"full"}
                        justify={"space-between"}
                        align={"center"}
                      >
                        <FormLabel fontWeight={700}>Heure de début</FormLabel>
                        <Input
                          {...field}
                          placeholder="Heure de début"
                          bg="common.100"
                          type="time"
                          w={110}
                          min={
                            form.values.scheduledDate ===
                            new Date().toISOString().split("T")[0]
                              ? new Date().toLocaleTimeString("fr-FR", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })
                              : undefined
                          }
                        />
                      </Flex>
                      <FormErrorMessage>
                        {form.errors.startTime}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                {!isEditing ? (
                  <Field name="price">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.price && form.touched.price}
                      >
                        <Flex
                          w={"full"}
                          justify={"space-between"}
                          align={"center"}
                        >
                          <FormLabel fontWeight={700}>Montant</FormLabel>
                          <Flex align={"center"} gap={2} alignItems="baseline">
                            <Input
                              {...field}
                              placeholder="Montant"
                              bg="common.100"
                              type="number"
                              w={100}
                            />
                            <Text
                              fontSize={14}
                              fontWeight="bold"
                              color="common.500"
                            >
                              euros
                            </Text>
                          </Flex>
                        </Flex>
                        <FormErrorMessage>{form.errors.price}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                ) : (
                  <Flex align={"center"} gap={2}>
                    <Icon as={InfoOutlineIcon} fontSize={20} />
                    <Text>Un email sera envoyé au patient pour l'avertir</Text>
                  </Flex>
                )}

                {!isEditing && patientId === undefined && (
                  <Field name="patient">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.patient && form.touched.patient}
                      >
                        <Flex
                          w={"full"}
                          justify={"space-between"}
                          align={"center"}
                        >
                          <FormLabel fontWeight={700}>Patient</FormLabel>
                          <SearchBar
                            setPatient={(selectedPatient) => {
                              setPatient(selectedPatient)
                              setFieldValue("patient", selectedPatient?.id)
                            }}
                            bg="common.100"
                          />
                        </Flex>
                        <FormErrorMessage>
                          {form.errors.patient}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                )}
              </Flex>
              <ModalFooter justifyContent="center" p={0} mt={6}>
                <ASubmitButton
                  text={teleconsultation ? "Appliquer" : "Envoyer demande"}
                  py={3}
                  px={28}
                  isLoading={isSubmitting}
                  variant="primary_redesign"
                  w={"full"}
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default TeleconsultationModal
