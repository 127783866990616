import { Box, Flex, Image, Spinner, Text, useToast } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { curryIncludesInsensitive } from "utils/includesInsensitive"
import {
  getAppointmentReason,
  SelectedExerciceType,
  TrainingVideoGroupsType,
  VideoGroupCategoryType,
  SelectedUploadedExerciceType,
  SelectedVideoOrderType,
  GetAppointmentReasonParams,
  AppointmentReasonTypePaginated,
  getUploadedVideosForAppointmentReason,
  getLikedVideosForAppointmentReason,
} from "api/appointmentReason"
import { ZonePathologieType } from "pages/NewAppointment/newAppointment.mock"

import { getCommonPrograms, getMyPrograms, ProgramType } from "api/program"
import TabExercices from "pages/Exercices/components/ExercicesLists/components/TabExecices/TabExercices"
import FilterExercices from "pages/Exercices/components/ExercicesLists/components/FilterExercices/FilterExercices"
import FilterPrograms from "pages/Exercices/components/ExercicesLists/components/FilterPrograms/FilterPrograms"
import VideoGroup from "pages/Exercices/components/ExercicesLists/components/VideoGroup/VideoGroup"
import ProgramCard from "pages/Exercices/components/ExercicesLists/components/ProgramCard/ProgramCard"
import { niceDisplayVideoGroup } from "utils/niceDisplayVideoGroup"
import AddEditableProgramModal from "pages/NewAppointment/components/SelectExercices/components/AddProgramModal/AddEditableProgramModal"
import AUploadedExerciceCard from "components/AUploadedExerciceCard/AUploadedExerciceCard"
import SelectedExercices from "pages/NewAppointment/components/SelectExercices/components/SelectedExercices/SelectedExercices"
import arrowBack from "assets/arrow-back.svg"
import useJobIsKineOrPodo from "hooks/useIsJobKineOrPodo"
import { useMutation, useQuery } from "@tanstack/react-query"
import ATablePagination from "components/Table/ATablePagination/ATablePagination"
import { useDebounce } from "use-debounce"
import {
  sortByJob,
  sortByTimeUsed,
} from "pages/NewProgram/components/SelectProgramExercices"

export const VIDEOS_TAB = 0
export const PROGRAMME_TAB = 1
export const FAVORIS_TAB = 2

// COMPONENTS
interface IProps {
  onBack: () => void
  onNext: () => void
  reason: ZonePathologieType | undefined
  selectedExercices: SelectedExerciceType[]
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  selectedUploadedExercices: SelectedUploadedExerciceType[]
  setSelectedUploadedExercices: React.Dispatch<
    React.SetStateAction<SelectedUploadedExerciceType[]>
  >
  selectedVideoOrder: SelectedVideoOrderType[]
  setSelectedVideoOrder: React.Dispatch<
    React.SetStateAction<SelectedVideoOrderType[]>
  >
  isForKineOrPodo?: boolean
}

const EditSelectExercices: React.FC<IProps> = ({
  onBack,
  onNext,
  selectedExercices,
  setSelectedExercices,
  selectedUploadedExercices,
  setSelectedUploadedExercices,
  selectedVideoOrder,
  setSelectedVideoOrder,
  reason,
  isForKineOrPodo = false,
}) => {
  const [search, setSearch] = useState("")
  const [videoGroups, setVideoGroups] = useState<TrainingVideoGroupsType[]>([])

  const [categories, setCategories] = useState<VideoGroupCategoryType[]>([])
  const [categoryFilter, setCategoryFilter] = useState<string | null>(null)
  const toast = useToast()

  const [restrictionFilter, setRestrictionFilter] = useState<string | null>(
    null
  )
  const [selectedProgram, setSelectedProgram] = useState<ProgramType | null>(
    null
  )
  const [likedVideoGroups, setLikedVideoGroups] = useState<
    TrainingVideoGroupsType[]
  >([])

  const [selectedTab, setSelectedTab] = useState(VIDEOS_TAB)

  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [appointmentReason, setAppointmentReason] = useState<
    undefined | AppointmentReasonTypePaginated
  >(undefined)

  const [debouncedSearch] = useDebounce(search, 500)

  const restrictions = videoGroups.reduce(
    (acc, { restrictions }) => [
      ...acc,
      ...(restrictions ?? []).reduce(
        (restrictionsAcc, { name }) =>
          acc.includes(name) ? restrictionsAcc : [...restrictionsAcc, name],
        [] as string[]
      ),
    ],
    [] as string[]
  )
  const isJobKineOrPodo = useJobIsKineOrPodo()
  const { mutate, isLoading: isAppointmentReasonLoading } = useMutation({
    mutationKey: ["appointmentReason", reason?.id, 1],
    mutationFn: (params: GetAppointmentReasonParams) =>
      getAppointmentReason(params),
    onSuccess: (response) => {
      if (reason?.id) {
        setAppointmentReason(response.data)
      } else {
        setAppointmentReason(undefined)
      }
      setVideoGroups(
        niceDisplayVideoGroup(
          response.data.trainingVideoGroups.sort(
            isJobKineOrPodo ? sortByJob : sortByTimeUsed
          )
        )
      )
      setPageCount(response.data.pagination.pageCount)
      setLikedVideoGroups(
        response.data.trainingVideoGroups.filter(
          (trainingVideoGroup) =>
            trainingVideoGroup.osteosLiking &&
            trainingVideoGroup.osteosLiking.length > 0
        )
      )
    },
  })

  const { data: uploadedVideos } = useQuery({
    queryKey: ["uploadedVideos", reason?.id ?? -1],
    queryFn: () => getUploadedVideosForAppointmentReason(reason?.id ?? -1),
  })

  const { data: likedVideos } = useQuery({
    queryKey: ["likedVideos", reason?.id ?? -1],
    queryFn: () => getLikedVideosForAppointmentReason(reason?.id ?? -1),
  })

  // Effet pour déclencher la mutation quand les params changent
  useEffect(() => {
    setPage(1)
    mutate({
      reasonId: reason?.id ?? -1,
      page: 1,
    })
  }, [reason?.id])
  useEffect(() => {
    mutate({
      reasonId: reason?.id ?? -1,
      page,
    })
  }, [page])

  useEffect(() => {
    if (reason?.id) setPageCount(0)
  }, [reason?.id])

  const { data: programs } = useQuery({
    queryKey: ["getMyPrograms"],
    queryFn: getMyPrograms,
  })
  const { data: commonPrograms } = useQuery({
    queryKey: ["getCommonPrograms"],
    queryFn: getCommonPrograms,
  })

  useEffect(() => {
    const listOfCategories = videoGroups
      .map((videoGroup) => videoGroup.category)
      .filter((elt) => elt !== null)

    const uniqueCategories = listOfCategories.filter(
      (category, index) => listOfCategories.indexOf(category) === index
    )

    setCategories(uniqueCategories)
  }, [videoGroups])

  useEffect(() => {
    // reset filters
    setCategoryFilter(null)
  }, [reason])

  const filterWithCategoriesRestrictionsAndSearch = (
    list: TrainingVideoGroupsType[]
  ) => {
    let filteredList = list
    if (categoryFilter !== null) {
      filteredList = filteredList.filter(
        ({ category }) => category === categoryFilter
      )
    }
    if (restrictionFilter !== null) {
      filteredList = filteredList.filter(({ restrictions }) =>
        restrictions.some(({ name }) => name === restrictionFilter)
      )
    }
    return filteredList
  }

  const filterPrograms = (list: ProgramType[]) => {
    const newList = list.filter(
      (program) =>
        program.trainingVideoPrograms &&
        program.trainingVideoPrograms.length > 0
    )
    const curriedIncludesInsensitive = curryIncludesInsensitive(search)
    let filteredList = newList
    if (search !== "") {
      filteredList = filteredList.filter(({ title }) =>
        curriedIncludesInsensitive(title)
      )
    }
    if (reason) {
      filteredList = filteredList.filter(({ appointmentReasons }) =>
        appointmentReasons.find(
          (currentReason) => currentReason.id === reason.id
        )
      )
    }
    return filteredList
  }

  const [sortedFilteredVideoGroups, setSortedFilteredVideoGroups] = useState<
    TrainingVideoGroupsType[]
  >([])

  const [sortedFilteredPrograms, setSortedFilteredPrograms] = useState<
    ProgramType[]
  >([])
  const [sortedCommonPrograms, setSortedCommonPrograms] = useState<
    ProgramType[]
  >([])

  useEffect(() => {
    setSortedFilteredVideoGroups(
      filterWithCategoriesRestrictionsAndSearch(videoGroups)
    )
  }, [videoGroups, categoryFilter, restrictionFilter, search])

  useEffect(() => {
    const filteredPrograms = filterPrograms(programs ?? [])
    setSortedFilteredPrograms(filteredPrograms)
  }, [search, reason, programs])

  useEffect(() => {
    const filteredProgram = filterPrograms(commonPrograms ?? [])
    setSortedCommonPrograms(filteredProgram)
  }, [search, reason, commonPrograms])

  useEffect(() => {
    setPage(1)
    mutate({
      reasonId: reason?.id ?? -1,
      page: 1,
      search: debouncedSearch,
    })
  }, [debouncedSearch])

  const filteredUploadedVideo = uploadedVideos?.filter((video) =>
    video.title.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <Box p="16px" width="full" pb={"120px"}>
      <Flex>
        <Flex direction="column" pos="sticky" top={4} h="fit-content">
          <Flex gap={4} align={"center"} mb={6}>
            <Flex
              cursor="pointer"
              onClick={onBack}
              p="8px"
              bgColor="primary.200"
              w="fit-content"
              display="block"
              rounded="9px"
              pos="sticky"
              top={4}
            >
              <Image src={arrowBack} width="24px" height="24px" />
            </Flex>
          </Flex>
        </Flex>

        <Box flex={1} px="60px" position="relative">
          <TabExercices
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            isPageExercies
            appointmentReason={reason?.id}
          />
          {selectedTab === 0 && (
            <FilterExercices
              categories={categories}
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
              setSearch={setSearch}
              restrictions={restrictions}
              restrictionFilter={restrictionFilter}
              setRestrictionFilter={setRestrictionFilter}
            />
          )}
          {selectedTab === 1 && <FilterPrograms setSearch={setSearch} />}

          <Flex justifyContent="flex-start" flexWrap="wrap" gap="16px" mt={4}>
            {selectedTab === 0 && (
              <Flex flexDir={"column"} gap={"16px"} w="full">
                {filteredUploadedVideo?.length &&
                filteredUploadedVideo?.length > 0 &&
                isForKineOrPodo ? (
                  <Box>
                    <Text fontWeight={700} fontSize={16} mb={4}>
                      Vos exercices
                    </Text>
                    <Flex
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      gap="16px"
                      w="full"
                    >
                      {filteredUploadedVideo.map((video, i) => (
                        <AUploadedExerciceCard
                          key={`uploaded_video_${video.id}`}
                          video={video}
                          selectedExercices={selectedUploadedExercices}
                          setSelectedExercices={setSelectedUploadedExercices}
                          setSelectedVideoOrder={setSelectedVideoOrder}
                          isForKineOrPodo={isForKineOrPodo}
                          appointmentReason={reason?.id}
                        />
                      ))}
                    </Flex>
                  </Box>
                ) : null}
                {isAppointmentReasonLoading ? (
                  <Flex
                    flex={1}
                    px="60px"
                    position="relative"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Spinner color="primary.500" />
                  </Flex>
                ) : (
                  <Box w="full">
                    {filteredUploadedVideo?.length &&
                    filteredUploadedVideo?.length > 0 &&
                    sortedFilteredVideoGroups.length > 0 ? (
                      <Text fontWeight={700} fontSize={16} mb={4}>
                        Autres exercices
                      </Text>
                    ) : null}
                    <Flex
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      gap="16px"
                      w="full"
                    >
                      {sortedFilteredVideoGroups.map((videoGroup, index) => (
                        <VideoGroup
                          key={`video_group_${videoGroup.id}_${index}`}
                          videoGroup={videoGroup}
                          selectedExercices={selectedExercices}
                          setSelectedExercices={setSelectedExercices}
                          setSelectedVideoOrder={setSelectedVideoOrder}
                          isForKineOrPodo={isForKineOrPodo}
                          isLiked={
                            likedVideos?.some(
                              (likedVideo) => likedVideo.id === videoGroup.id
                            ) ?? false
                          }
                        />
                      ))}
                    </Flex>
                    {selectedTab === 0 && (
                      <Box mt={4}>
                        <ATablePagination
                          pageCount={pageCount}
                          onPageChange={setPage}
                          page={page}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Flex>
            )}

            {selectedTab === 1 && (
              <Box w="full" pb={16}>
                {sortedFilteredPrograms.length > 0 && (
                  <Box>
                    <Text fontWeight={700} fontSize={16}>
                      Vos programmes
                    </Text>
                    <Flex
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      gap="1%"
                      mt={4}
                    >
                      {sortedFilteredPrograms.map((program) => (
                        <ProgramCard
                          key={`program_${program.id}`}
                          program={program}
                          selectedProgram={selectedProgram}
                          setSelectedProgram={setSelectedProgram}
                        />
                      ))}
                    </Flex>
                  </Box>
                )}
                {sortedCommonPrograms.length > 0 ? (
                  <Box>
                    <Text fontWeight={700} fontSize={16}>
                      Autres programmes
                    </Text>
                    <Flex
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      gap="1%"
                      mt={4}
                    >
                      {sortedCommonPrograms.map((program) => (
                        <ProgramCard
                          key={`program_${program.id}`}
                          program={program}
                          selectedProgram={selectedProgram}
                          setSelectedProgram={setSelectedProgram}
                        />
                      ))}
                    </Flex>
                  </Box>
                ) : null}
              </Box>
            )}
            {selectedTab === 2 &&
              likedVideos?.map((videoGroup) => (
                <VideoGroup
                  key={`liked_video_group_${videoGroup.id}`}
                  videoGroup={videoGroup}
                  reason={reason}
                  isLiked={true}
                />
              ))}
          </Flex>
        </Box>

        {(selectedExercices || selectedUploadedExercices) && (
          <SelectedExercices
            selectedExercices={selectedExercices}
            setSelectedExercices={setSelectedExercices}
            selectedUploadedExercices={selectedUploadedExercices}
            setSelectedUploadedExercices={setSelectedUploadedExercices}
            selectedVideoOrder={selectedVideoOrder}
            setSelectedVideoOrder={setSelectedVideoOrder}
            showOnlyDuration={!isForKineOrPodo}
            onNext={onNext}
          />
        )}
      </Flex>
      {selectedProgram && (
        <AddEditableProgramModal
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
          setSelectedExercices={setSelectedExercices}
          setSelectedUploadedExercices={setSelectedUploadedExercices}
          setSelectedVideoOrder={setSelectedVideoOrder}
        />
      )}
    </Box>
  )
}

export default EditSelectExercices
