import { Flex, Heading } from "@chakra-ui/react"
import AButton from "components/AButton"
import {
  updateSubscription,
  StripeProductType,
  customerPortal,
} from "api/subscriptions"
import { useMutation } from "@tanstack/react-query"
import { useAppContext } from "AppContext"
import { useRef, useState } from "react"

import useToast from "hooks/useToast"
import {
  MIN_SEATS,
  SESSION_ID_KEY,
  STRIPE_CUSTOMER_ID_KEY,
} from "constants/subscription"
import { useNavigate } from "react-router-dom"

import getSubPricePerPeriodPerSeat from "utils/price/getSubPricePerPeriodPerSeat"
import formatCurrency from "utils/price/formatCurrency"
import { DEFAULT_CURRENCY_CODE } from "constants/currencies"
import OsteoSubscriptionCard from "components/Subscription/OsteoSubscriptionCard"
import MoveToBusinessSubscriptionDialog from "components/Subscription/Osteo/MoveToBusinessSubscriptionDialog"

interface OsteoSubscriptionProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  stripeProducts?: StripeProductType[]
  isLoadingStripeProducts: boolean
}
const OsteoSubscription = ({
  isOpen,
  onOpen,
  onClose,
  stripeProducts,
  isLoadingStripeProducts,
}: OsteoSubscriptionProps) => {
  const { user } = useAppContext()

  const subscription = user?.stripeSubscription
  const subscriptionPrice = subscription
    ? getSubPricePerPeriodPerSeat(subscription)
    : undefined
  const subscriptionPriceReadable =
    subscription?.plan.interval === "month"
      ? `${subscriptionPrice?.monthly} ${formatCurrency(
          subscription.currency
        )} / mois`
      : `${subscriptionPrice?.yearly} ${formatCurrency(
          subscription?.currency ?? DEFAULT_CURRENCY_CODE
        )} / an`

  const toast = useToast()
  const navigate = useNavigate()
  const [seats, setSeats] = useState(MIN_SEATS)

  const cancelRef = useRef<HTMLButtonElement>(null)
  const customerPortalMutation = useMutation(customerPortal)
  const updateSubscriptionMutation = useMutation(updateSubscription, {
    onSuccess: () => {
      // query invalidation is already handled on target page
      navigate({
        pathname: "/center",
        search: new URLSearchParams({
          [SESSION_ID_KEY]: user!.osteo.sessionId!,
          [STRIPE_CUSTOMER_ID_KEY]: user!.stripeCustomer!.id,
        }).toString(),
      })
      toast({
        status: "success",
        title: "Abonnement modifié avec succès",
      })
      onClose()
    },
  })

  const currentStripeProduct = stripeProducts?.find(
    ({ id }) => subscription?.plan.product === id
  )

  const onMoveToBusinessSubscribe = (priceId: string) => {
    if (user === null) return
    updateSubscriptionMutation.mutate({
      quantity: seats,
      priceId,
      email: user.email,
      subscriptionId: user.stripeSubscription!.id, // on devrait forcément avoir une souscription individuelle
    })
  }

  if (!user?.stripeCustomer?.id || user?.osteo?.isTester) {
    return null
  }

  return (
    <>
      <OsteoSubscriptionCard
        variant="individual"
        price={subscriptionPriceReadable}
        action={
          <AButton
            variant="tertiary"
            text="Gérer mon abonnement"
            width={347}
            onClick={() =>
              customerPortalMutation.mutate(
                {
                  customerId: user.stripeCustomer?.id!,
                },
                {
                  onSuccess: (data) => {
                    window.location.href = data
                  },
                }
              )
            }
          />
        }
      >
        {subscription && user?.stripeCustomer?.id && (
          <Flex direction="column" gap={6}>
            <Heading fontSize={22} fontWeight={700}>
              Vous collaborez en équipe ?
            </Heading>
            <AButton
              variant="secondary"
              text="Découvrez le forfait Centre"
              width={347}
              isLoading={isLoadingStripeProducts}
              onClick={onOpen}
            />
          </Flex>
        )}
      </OsteoSubscriptionCard>
      {currentStripeProduct && (
        <MoveToBusinessSubscriptionDialog
          cancelRef={cancelRef}
          isOpen={isOpen}
          onClose={onClose}
          onSubscribe={onMoveToBusinessSubscribe}
          seats={seats}
          setSeats={setSeats}
          isLoading={updateSubscriptionMutation.isLoading}
          stripeProducts={stripeProducts!}
        />
      )}
    </>
  )
}

export default OsteoSubscription
