import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react"
import {
  SelectedUploadedExerciceType,
  SelectedVideoOrderType,
} from "api/appointmentReason"
import AUploadedExerciceCardBase from "components/AUploadedExerciceCard/Base"
import AddUploadedExerciceModal from "pages/NewAppointment/components/SelectExercices/components/AddUploadedExerciceModal"
import React, { useEffect, useState } from "react"

interface IProps {
  video: SelectedUploadedExerciceType
  selectedExercices?: SelectedUploadedExerciceType[]
  setSelectedExercices?: React.Dispatch<
    React.SetStateAction<SelectedUploadedExerciceType[]>
  >
  setSelectedVideoOrder?: React.Dispatch<
    React.SetStateAction<SelectedVideoOrderType[]>
  >
  isForKineOrPodo?: boolean
  appointmentReason?: number | undefined
}

const AUploadedExerciceCard: React.FC<IProps> = ({
  video,
  selectedExercices,
  setSelectedExercices,
  setSelectedVideoOrder,
  isForKineOrPodo = false,
  appointmentReason,
}) => {
  const [isSelecting, setIsSelecting] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const isSelected = !selectedExercices
    ? false
    : selectedExercices.some((exercice) => exercice.id === video.id)

  const onClickSelection = isSelected ? () => null : () => setIsSelecting(true)

  const handleOsteoAdd = () => {
    if (!setSelectedExercices || !setSelectedVideoOrder) return

    setSelectedVideoOrder((prev) => [
      ...prev,
      {
        id: video.id,
        type: "uploaded_video",
      },
    ])

    setSelectedExercices((prev) => [
      ...prev,
      {
        ...video,
        duration: video.config.duration,
        series: null,
        repetitions: null,
        restDuration: null,
        type: null,
        canUseWeight: false,
        weight: null,
        recommendation: video.config.description,
      },
    ])
  }

  const onAdd = () => {
    if (!setSelectedExercices) return
    if (isForKineOrPodo) {
      onOpen()
      return
    } else {
      handleOsteoAdd()
      return
    }
  }
  useEffect(() => {
    setIsSelecting(false)
  }, [isSelected])

  return (
    <>
      <Box bg="white" borderRadius="10px" p={2} w={"calc(33% - 9px)"}>
        <Flex flexDir={"column"} h="full">
          <Text fontWeight={700} fontSize={14} pb={2}>
            {video.title}
          </Text>
          <AUploadedExerciceCardBase
            video={video}
            isSelecting={isSelecting}
            isSelected={isSelected}
            isDisabled={isSelected}
            onClick={onClickSelection}
            onAdd={onAdd}
            isAddCard={selectedExercices !== undefined}
            appointmentReason={appointmentReason}
          />
        </Flex>
      </Box>
      {isOpen && setSelectedExercices && (
        <AddUploadedExerciceModal
          isOpen={isOpen}
          onClose={onClose}
          video={video}
          setSelectedExercices={setSelectedExercices}
          setSelectedVideoOrder={setSelectedVideoOrder}
        />
      )}
    </>
  )
}

export default AUploadedExerciceCard
