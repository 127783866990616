import { Box, Flex, Image } from "@chakra-ui/react"
import React, { useState } from "react"
import corpsFront from "../../../../../../assets/corps-front.svg"
import corpsBack from "../../../../../../assets/corps-back.svg"
import AZonePathologieMarker from "./components/AZonePathologieMarker"
import { ZonePathologieType } from "pages/NewAppointment/newAppointment.mock"
import { AppointmentReasonType } from "api/appointmentReason"
import ClinicalPictures from "components/Treatment/ClinicalPictures"
import Reason from "pages/NewAppointment/components/SelectExercices/components/Reason"

interface IProps {
  datas: ZonePathologieType[]
  selectedData: ZonePathologieType | undefined
  setSelectedData: (zone: ZonePathologieType | undefined) => void
  isForKineOrPodo?: boolean
  reason?: AppointmentReasonType | undefined
  hideClinicalPictures?: boolean
  selectedClinicalPicture?: string | null
  setSelectedClinicalPicture?: React.Dispatch<
    React.SetStateAction<string | null>
  >
  isBabySwitchOn?: boolean
}

const ZonesPathologies: React.FC<IProps> = ({
  datas,
  selectedData,
  setSelectedData,
  isForKineOrPodo = false,
  reason,
  hideClinicalPictures = false,
  selectedClinicalPicture,
  setSelectedClinicalPicture,
  isBabySwitchOn,
}) => {
  const [reasonType, setReasonType] = useState<"zones" | "pathologies" | null>(
    "zones"
  )
  const handleClick = (currentElt: ZonePathologieType) => {
    if (setSelectedClinicalPicture) {
      setSelectedClinicalPicture(null)
    }
    if (selectedData && selectedData.id === currentElt.id) {
      setSelectedData(undefined)
    } else {
      setSelectedData(currentElt)
    }
  }

  const hasSwitch =
    datas.some((data) => data.type === "zone") &&
    datas.some((data) => data.type === "pathologie")

  const showClinicalPictures =
    isForKineOrPodo &&
    selectedData &&
    reason &&
    reason?.clinicalPictures &&
    !hideClinicalPictures &&
    selectedClinicalPicture !== undefined &&
    setSelectedClinicalPicture !== undefined

  const filteredData =
    reasonType === "zones"
      ? datas.filter((data) => data.type === "zone")
      : datas.filter((data) => data.type === "pathologie")

  return (
    <Box>
      {hasSwitch && (
        <Reason
          reason={reasonType}
          setReason={setReasonType}
          isBabySwitchOn={isBabySwitchOn ?? false}
        />
      )}
      <Flex
        mb={75}
        direction="column"
        overflowY={"auto"}
        h="100vh"
        minW="300px"
        maxH={"calc(100vh - 180px)"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "primary.300",
            borderRadius: "24px",
          },
        }}
      >
        <Flex gap="33px">
          <Box pos="relative">
            <div>
              <Image
                src={corpsFront}
                alt="Corps de devant"
                userSelect="none"
                w="128px"
                h="393"
              />
              {filteredData
                .filter(
                  (data) => data.schema === 1 || data.secondarySchema === 1
                )
                .map(({ name, id, left, top, zIndex, code }, i) => (
                  <AZonePathologieMarker
                    name={name}
                    code={code}
                    left={left}
                    top={top}
                    key={`front_${id}`}
                    zIndex={zIndex}
                    state={selectedData?.id === id ? "active" : "default"}
                    onClick={() =>
                      handleClick(
                        filteredData.filter(
                          (data) =>
                            data.schema === 1 || data.secondarySchema === 1
                        )[i]
                      )
                    }
                  />
                ))}
            </div>
          </Box>
          <Box pos="relative">
            <Image
              src={corpsBack}
              alt="Corps de derrière"
              userSelect="none"
              w="128px"
              h="393"
            />
            {filteredData
              .filter((data) => data.schema === 2 || data.secondarySchema === 2)
              .map(({ name, id, left, top, zIndex, code }, i) => (
                <AZonePathologieMarker
                  name={name}
                  code={code}
                  left={left}
                  top={top}
                  key={`back_${id}`}
                  zIndex={zIndex}
                  state={selectedData?.id === id ? "active" : "default"}
                  onClick={() =>
                    handleClick(
                      filteredData.filter(
                        (data) =>
                          data.schema === 2 || data.secondarySchema === 2
                      )[i]
                    )
                  }
                />
              ))}
          </Box>
        </Flex>
        {showClinicalPictures && (
          <ClinicalPictures
            appointmentReasonTitle={selectedData.name}
            appointmentReason={reason}
            selectedClinicalPicture={selectedClinicalPicture}
            setSelectedClinicalPicture={setSelectedClinicalPicture}
          />
        )}
      </Flex>
    </Box>
  )
}

export default ZonesPathologies
