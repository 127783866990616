import { DeleteIcon, TimeIcon } from "@chakra-ui/icons"
import {
  Flex,
  Heading,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import {
  SelectedExerciceType,
  SelectedVideoOrderType,
} from "api/appointmentReason"
import { deleteProgram, ProgramType } from "api/program"
import AButton from "components/AButton"
import useJobIsOsteo from "hooks/useJobIsOsteo"
import useToast from "hooks/useToast"
import React, { FC } from "react"
import { getTotalDuration } from "utils/getTotalDuration"

interface AddProgramModalProps {
  selectedProgram: ProgramType
  setSelectedProgram: React.Dispatch<React.SetStateAction<ProgramType | null>>
  selectedExercices?: SelectedExerciceType[]
  setSelectedExercices?: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  setSelectedVideoOrder?: React.Dispatch<
    React.SetStateAction<SelectedVideoOrderType[]>
  >
}

const AddProgramModal: FC<AddProgramModalProps> = ({
  selectedProgram,
  setSelectedProgram,
  selectedExercices,
  setSelectedExercices,
  setSelectedVideoOrder,
}) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const deleteProgramMutation = useMutation({
    mutationFn: deleteProgram,
    onSuccess: async () => {
      await queryClient.refetchQueries(["getMyPrograms"])
      deleteModalOnClose()
      toast({
        status: "success",
        title: "Exercice supprimé avec succès",
      })
      setSelectedProgram(null)
    },
    onError: () => {
      toast({
        status: "error",
        title: "Une erreur est survenue lors de la suppression",
      })
    },
  })

  const handleDelete = () => {
    deleteProgramMutation.mutate(selectedProgram.id)
  }

  const isJobOsteo = useJobIsOsteo()
  const handleAddProgram = () => {
    if (!selectedExercices || !setSelectedExercices) return

    const videosToAdd: SelectedExerciceType[] =
      selectedProgram.trainingVideoPrograms
        .filter((tvp: any) => !tvp.trainingVideoUploaded && tvp.trainingVideo)
        .map((tvp: any) => ({
          ...tvp.trainingVideo,
          duration: tvp.duration,
          series: tvp.series || 1,
          repetitions: tvp.repetitions,
          restDuration: tvp.restDuration || 0,
          type: tvp.type,
          side: tvp.side,
          weight: tvp.weight,
          recommendation: tvp.recommendation,
          videoGroup: tvp.trainingVideo.group?.[0].id,
        }))

    const videosOrderToAdd: SelectedVideoOrderType[] =
      selectedProgram.trainingVideoPrograms.map((tvp: any, index: number) => ({
        id: tvp.trainingVideoUploaded
          ? tvp.trainingVideoUploaded.id
          : tvp.trainingVideo.id,
        type: tvp.trainingVideoUploaded ? "uploaded_video" : "video",
      }))
    setSelectedExercices((prev) => [...prev, ...videosToAdd])
    if (setSelectedVideoOrder) {
      setSelectedVideoOrder((prev) => [...prev, ...videosOrderToAdd])
    }
    setSelectedProgram(null)
  }
  const {
    isOpen: deleteModalIsOpen,
    onOpen: deleteModalOnOpen,
    onClose: deleteModalOnClose,
  } = useDisclosure()

  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        position="fixed"
        top="0px"
        left="0px"
        width="full"
        height="100vh"
        bg="rgba(0, 0, 0, 0.4)"
        zIndex={100}
      >
        <Flex
          bg="white"
          zIndex={100}
          p="32px"
          flexDirection="column"
          gap="32px"
          width="728px"
          rounded="8px"
          maxH="90vh"
          overflow="auto"
          sx={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "primary.300",
              borderRadius: "24px",
            },
          }}
        >
          <Flex justifyContent="space-between" w="full" position="relative">
            <Heading as="h6" fontSize="18px" fontWeight={700}>
              {selectedProgram.title}
            </Heading>
            <Flex
              p={2}
              pos="absolute"
              top={2}
              right={4}
              justifyContent="center"
              alignItems="center"
              bg="gray.100"
              rounded="4px"
              cursor="pointer"
            >
              <DeleteIcon color="danger.500" onClick={deleteModalOnOpen} />
            </Flex>

            {isJobOsteo && (
              <Flex
                bg="common.200"
                px="8px"
                py="2px"
                gap={1}
                rounded="24px"
                fontSize="12px"
                alignItems="center"
              >
                <TimeIcon />
                <Text fontWeight={600}>
                  {getTotalDuration(selectedProgram.trainingVideoPrograms)}
                </Text>
              </Flex>
            )}
          </Flex>

          <Flex justifyContent="flex-start" gap="12px" flexWrap="wrap">
            {selectedProgram.trainingVideoPrograms.map(
              (trainingVideoProgram) => (
                <Flex
                  key={trainingVideoProgram.id}
                  flexDirection="column"
                  maxWidth="200px"
                >
                  <Image
                    src={
                      trainingVideoProgram.trainingVideo?.bunnyCDN?.preview ??
                      trainingVideoProgram.trainingVideoUploaded?.bunnyCDN
                        .preview
                    }
                    width="200px"
                    rounded="8px"
                    mb="8px"
                  />
                  <Text fontSize="18px" fontWeight={700}>
                    {trainingVideoProgram.trainingVideo?.group[0].title ??
                      trainingVideoProgram.trainingVideoUploaded?.title}
                  </Text>
                  <Text>
                    niveau{" "}
                    {trainingVideoProgram.trainingVideo?.level ??
                      trainingVideoProgram.trainingVideoUploaded?.level}
                  </Text>
                </Flex>
              )
            )}
            <Flex justifyContent="end" gap="16px" w="full" mt="16px">
              <AButton
                text={
                  !selectedExercices || !setSelectedExercices
                    ? "Fermer"
                    : "Annuler"
                }
                variant="tertiary"
                onClick={() => {
                  setSelectedProgram(null)
                }}
              />
              {selectedExercices && setSelectedExercices && (
                <AButton text="Ajouter" onClick={handleAddProgram} />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Modal isOpen={deleteModalIsOpen} onClose={deleteModalOnClose} isCentered>
        <ModalOverlay bg="blackAlpha.400" />
        <ModalContent p={8}>
          <Text>Voulez-vous vraiment supprimer ce programme ?</Text>

          <Flex gap={4} justifyContent="flex-end" mt={8}>
            <AButton
              text="Annuler"
              variant="tertiary"
              onClick={deleteModalOnClose}
            />
            <AButton
              text="Supprimer"
              variant="primary"
              onClick={handleDelete}
            />
          </Flex>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddProgramModal
