import { Heading, Text } from "@chakra-ui/react"
import useStoreStripeConnectAccount from "hooks/useStoreStripeConnectAccount"
import { useEffect } from "react"
import { Link } from "react-router-dom"

const StripeConnectSuccess = () => {
  const { clearStripeConnectAccount, isDone } = useStoreStripeConnectAccount()

  useEffect(() => {
    if (isDone) clearStripeConnectAccount()
  }, [isDone, clearStripeConnectAccount])

  if (isDone)
    return (
      <div>
        <Heading as="h1" color="tertiary.500">
          Félicitations !
        </Heading>
        <Text my={4}>Votre compte Stripe a été créé avec succès.</Text>
        <Text>
          Vous pouvez maintenant créer des téléconsultations et recevoir des
          paiements.
        </Text>
      </div>
    )

  return (
    <div>
      <Heading as="h1" color="tertiary.500">
        Compte Stripe en cours de validation ou annulé
      </Heading>
      <Text my={4}>
        Vous venez de terminer la création de votre compte Stripe ? La
        validation de votre compte est en cours de traitement par leurs
        services. Nous vous invitons à rafraichir la page ou revenir un peu plus
        tard. Si vous n'arrivez pas à terminer le processus, contactez notre
        support.
        <br />
        <br />
        Si vous avez quitté le processus d'inscription avant la fin, vous
        pourrez recommencer plus tart la création de votre compte Stripe.
      </Text>
      <Link to="/teleconsultation">
        <u>Retourner à la téléconsultation</u>
      </Link>
    </div>
  )
}

export default StripeConnectSuccess
