import { Flex } from "@chakra-ui/react"
import AInputSearch from "components/AInputSearch"
import React from "react"

interface IProps {
  setSearch: React.Dispatch<React.SetStateAction<string>>
}
const FilterPrograms = ({ setSearch }: IProps) => {
  return (
    <Flex direction="column" gap={8} my={4}>
      <Flex direction={"column"} gap={4}>
        <AInputSearch
          placeholder="Rechercher un programme"
          canClear
          setSearch={setSearch}
        />
      </Flex>
    </Flex>
  )
}

export default FilterPrograms
