import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom"
import Patient from "./pages/Patient/Patient"
import NewAppointment from "./pages/NewAppointment/NewAppointment"
import Login from "./pages/Login/Login"
import { useAppContext } from "./AppContext"
import { useQuery } from "@tanstack/react-query"
import { getMe } from "./api/user"
import { getSettings } from "./api/settings"
import NewPatient from "./pages/NewPatient/NewPatient"
import Appointment from "./pages/Appointment/Appointment"
import InitiatePassword from "./pages/InitiatePassword/InitiatePassword"
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword"
import ResetPassword from "./pages/ResetPassword/ResetPassword"
import { Center as ChakraCenter, Spinner } from "@chakra-ui/react"
import useToast from "./hooks/useToast"
import Explorer from "./pages/Explorer/Explorer"
import Topic from "./pages/Topic/Topic"
import Podcasts from "./pages/Podcasts/Podcasts"
import Podcast from "./pages/Podcast/Podcast"
import Subscribe from "./pages/Subscribe/Subscribe"
import Register from "./pages/Register/Register"
import Canceled from "./pages/Canceled/Canceled"
import Profile from "./pages/Profile/Profile"
import Invitation from "./pages/Invitation/Invitation"
import ELearning from "./pages/ELearning/ELearning"
import Statistiques from "./pages/Statistiques/Statistiques"
import UnderMaintenance from "./pages/UnderMaintenance/UnderMaintenance"
import SubscriptionPaused from "pages/SubscriptionPaused/SubscriptionPaused"
import Academy from "./pages/Academy/Academy"
import Center from "pages/Center/Center"
import Treatments from "./pages/Treatment/Treatments"
import { sendinBlueIdentify } from "./utils/sendinBlueEvents"
import getMeQueryKeys from "./constants/queryKeys/getMe"
import useNeedsHardReload from "hooks/useNeedsHardReload"
import userHasNoPersonalAccount from "utils/user/userHasNoPersonalAccount"
import userHasAcademiesOrCenters from "utils/user/userHasAcademiesOrCenters"
import CollaboratorDeactivated from "pages/CollaboratorDeactivated/CollaboratorDeactivated"
import useCenterSubscription from "hooks/useCenterSubscription"
import ThirdPartyLogin from "pages/ThirdPartyLogin/ThirdPartyLogin"
import Cooption from "pages/Cooption/Cooption"
import BadGeolocation from "pages/BadGeolocation/BadGeolocation"
import EnableGeolocation from "pages/EnableGeolocation/EnableGeolocation"
import AGeolocationLoader from "components/Geolocation/AGeolocationLoader"
import useStudentGeolocationEffect from "hooks/useStudentGeolocation/effect"
import EditAppointment from "pages/EditAppointment/EditAppointment"
import useCenterIsTester from "hooks/useCenterIsTester"
import useForumIsMissingData from "hooks/useForumIsMissingData"
import FillMissingForumData from "pages/FillMissingForumData/FillMissingForumData"
import useHasAcceptedCGS from "./hooks/useHasAcceptedCGS"
import CGS from "./pages/CGS/CGS"
import RecentAppointments from "./pages/RecentAppointments/RecentAppointments"
import Dashboard from "pages/Dashboard/Dashboard"
import { useEffect } from "react"
import mixpanel from "mixpanel-browser"
import canSendToMixpanel from "./utils/canSendToMixpanel"
import userHasAcademyInCurrentScope from "utils/currentScope/userHasAcademyInCurrentScope.ts"
import userHasCenterInCurrentScope from "utils/currentScope/userHasCenterInCurrentScope"
import Exercices from "pages/Exercices/Exercices"
import NewProgram from "pages/NewProgram/NewProgram"
import DevelopperPatientele from "pages/DevelopperPatientele/DevelopperPatientele"
import Messages from "pages/Messages/Messages"
import NewMessage from "pages/NewMessage/NewMessage"
import EditMessage from "pages/EditMessage/EditMessage"
import Teleconsultation from "pages/Teleconsultation/Teleconsultation"
import CurrentTeleconsultation from "pages/Teleconsultation/CurrentTeleconsultation"
import PatientKine from "./pages/PatientKine/PatientKine"
import StripeConnectSuccess from "pages/StripeConnectSuccess/StripeConnectSuccess"
import TeleconsultationPaymentSuccess from "pages/Teleconsultation/TeleconsultationPaymentSuccess"
import PatientTeleconsultation from "pages/Teleconsultation/PatientTeleconsultation"
import EditExplorerAppointment from "pages/EditAppointment/EditExplorerAppointment"
import EditEducationalContent from "pages/EditAppointment/EditEducationalContent"
import { getTeleconsultationAccess } from "api/teleconsultation"
import useIsJobKineOrPodo from "hooks/useIsJobKineOrPodo"
import useJobIsOsteo from "hooks/useJobIsOsteo"

// COMPONENTS
const ARoutes = () => {
  const {
    jwt,
    setJwt,
    user,
    currentScope,
    cleverLogin,
    hasTeleconsultationAccess,
    setHasTeleconsultationAccess,
  } = useAppContext()
  const isAcademyAdmin =
    currentScope?.type === "academy" &&
    user?.osteo.adminAcademies?.some(
      (academy) => academy.id === currentScope.id
    )
  useQuery(
    ["getTeleconsultationAccess", currentScope?.id],
    () =>
      getTeleconsultationAccess(
        currentScope?.type === "center" ? currentScope.id : undefined
      ),
    {
      enabled: Boolean(user),
      onSuccess: (data) => setHasTeleconsultationAccess(data?.granted ?? false),
    }
  )

  const isJobKineOrPodo = useIsJobKineOrPodo()
  const isJobOsteo = useJobIsOsteo()

  const navigate = useNavigate()
  const toast = useToast()
  const location = useLocation()
  if (process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN !== undefined) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
      track_pageview: true,
      persistence: "localStorage",
    })
  }

  //handle view changement for mixpanel
  useEffect(() => {
    if (canSendToMixpanel()) {
      if (user !== null) {
        mixpanel.identify(user.id.toString())
      }
      mixpanel.track(location.pathname)
    }
  }, [location])

  const {
    isLoading: isLoadingSettings,
    data: dataSettings,
    isError: isErrorSettings,
  } = useQuery(["getSettings"], getSettings, {
    onError: () => {
      toast({
        status: "error",
        title: "Une erreur s'est produite",
      })
    },
  })
  const underMaintenance = dataSettings?.attributes?.under_maintenance
  const shouldShowUnderMaintenance = underMaintenance || isErrorSettings
  const queryGetMeEnabled = !isLoadingSettings && !shouldShowUnderMaintenance

  useNeedsHardReload()

  const { isLoading } = useQuery(getMeQueryKeys.me(jwt!), getMe, {
    retry: false,
    onSuccess: (data) => {
      cleverLogin(data)
      var properties = {
        NOM: data.osteo.lastname,
        PRENOM: data.osteo.firstname,
        IS_OSTEOW: data.osteo.isOsteow,
        HAS_USED_TRIAL: data.osteo.hasUsedTrial,
        IS_PRO: true,
        IS_CENTER: userHasCenterInCurrentScope(data, currentScope),
        IS_ACADEMIE: userHasAcademyInCurrentScope(data, currentScope),
        JOB: data.osteo?.jobs?.[0]?.id,
      }

      sendinBlueIdentify(data.email, properties)
    },
    onError: (error: any) => {
      if (error.response?.status === 403) {
        toast({
          status: "error",
          title:
            "Le compte utilisé est relié à un patient et ne peut pas accéder à cette application",
        })
      }

      setJwt(null)
      navigate("/")
    },
    enabled: Boolean(jwt) && queryGetMeEnabled,
  })
  const {
    hasCurrentCenter,
    isLoading: isLoadingCenterSubscription,
    isError: isErrorCenterSubscription,
    active: isCenterSubscriptionActive,
  } = useCenterSubscription()

  const {
    isStudent,
    hasGeolocationCheck,
    error,
    isLoading: isLoadingStudentGeolocation,
    isImprovingAccuracy,
    can,
  } = useStudentGeolocationEffect()

  const centerIsTester = useCenterIsTester()
  const isFromForum = useForumIsMissingData()
  const hasAcceptedCGS = useHasAcceptedCGS()

  if (shouldShowUnderMaintenance) {
    // MAINTENANCE
    return (
      <Routes>
        <Route path="/under-maintenance" element={<UnderMaintenance />} />
        <Route path="*" element={<Navigate to="/under-maintenance" />} />
      </Routes>
    )
  }

  if (!jwt) {
    // NOT CONNECTED
    return (
      <Routes>
        <Route path="/external-login" element={<ThirdPartyLogin />} />
        <Route path="/invitation" element={<Invitation />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/teleconsultation/payment-success"
          element={<TeleconsultationPaymentSuccess />}
        />
        <Route
          path="/patient/teleconsultation/:id"
          element={<PatientTeleconsultation />}
        />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    )
  }

  if (
    !user ||
    isLoading ||
    (hasCurrentCenter && isLoadingCenterSubscription) ||
    hasTeleconsultationAccess === undefined
  ) {
    // GLOBAL LOADERS
    return (
      <ChakraCenter h="100vh">
        <Spinner color="primary.500" />
      </ChakraCenter>
    )
  }
  if (isFromForum) {
    return (
      <Routes>
        <Route path="/forum" element={<FillMissingForumData />} />
        <Route path="*" element={<Navigate to="/forum" />} />
      </Routes>
    )
  }
  if (!hasAcceptedCGS) {
    return (
      <Routes>
        <Route path="/cgs" element={<CGS />} />
        <Route path="*" element={<Navigate to="/cgs" />} />
      </Routes>
    )
  }
  if (userHasNoPersonalAccount(user) && !userHasAcademiesOrCenters(user)) {
    // NOT SUBSCRIBED OR CREATING CENTER
    return (
      <Routes>
        <Route path="/external-login" element={<ThirdPartyLogin />} />
        <Route path="/invitation" element={<Invitation />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/center" element={<Center />} />
        <Route path="*" element={<Navigate to="/subscribe" />} />
      </Routes>
    )
  }
  if (!user?.passwordInitiated) {
    // PASSWORD NOT INITIATED
    return (
      <Routes>
        <Route path="/external-login" element={<ThirdPartyLogin />} />
        <Route path="/invitation" element={<Invitation />} />
        <Route path="/change-password" element={<InitiatePassword />} />
        <Route path="*" element={<Navigate to="/change-password" replace />} />
      </Routes>
    )
  }
  if (hasCurrentCenter && !centerIsTester) {
    // CENTER ERROR CASES
    if (isErrorCenterSubscription) {
      return (
        <Routes>
          <Route path="/invitation" element={<Invitation />} />
          <Route
            path="/collaborator-deactivated"
            element={<CollaboratorDeactivated />}
          />
          <Route
            path="*"
            element={<Navigate to="/collaborator-deactivated" />}
          />
        </Routes>
      )
    }
    if (!isCenterSubscriptionActive) {
      return (
        <Routes>
          <Route path="/invitation" element={<Invitation />} />
          <Route path="/subscription-paused" element={<SubscriptionPaused />} />
          <Route path="*" element={<Navigate to="/subscription-paused" />} />
        </Routes>
      )
    }
  }
  if (isStudent && hasGeolocationCheck) {
    // STUDENT GEOLOCATION
    if (isLoadingStudentGeolocation) {
      return <AGeolocationLoader isImprovingAccuracy={isImprovingAccuracy} />
    }
    if (error) {
      return (
        <Routes>
          <Route path="/invitation" element={<Invitation />} />
          <Route path="/enable-geolocation" element={<EnableGeolocation />} />
          <Route path="*" element={<Navigate to="/enable-geolocation" />} />
        </Routes>
      )
    }
    if (!can) {
      return (
        <Routes>
          <Route path="/invitation" element={<Invitation />} />
          <Route path="/bad-geolocation" element={<BadGeolocation />} />
          <Route path="*" element={<Navigate to="/bad-geolocation" />} />
        </Routes>
      )
    }
  }

  // DEFAULT ROUTES
  return (
    <Routes>
      <Route path="/cgs" element={<CGS />} />
      <Route path="/external-login" element={<ThirdPartyLogin />} />
      <Route path="/invitation" element={<Invitation />} />
      <Route path="/canceled" element={<Canceled />} />
      <Route path="/profile" element={<Profile />} />
      {!currentScope && <Route path="/cooption" element={<Cooption />} />}
      {isAcademyAdmin && <Route path="/academy" element={<Academy />} />}
      <Route path="/center" element={<Center />} />
      <Route path="/e-learning" element={<ELearning />} />
      {isJobOsteo && (
        <Route
          path="/developper-patientele"
          element={<DevelopperPatientele />}
        />
      )}
      <Route path="/statistiques" element={<Statistiques />} />
      <Route path="/patients/new" element={<NewPatient />} />
      <Route path="/dashboard" element={<RecentAppointments />} />
      <Route path="/topics" element={<Topic />} />
      <Route path="/podcasts" element={<Podcasts />} />
      <Route path="/podcasts/:id" element={<Podcast />} />
      <Route path="/explorer" element={<Explorer />} />
      <Route path="/exercices" element={<Exercices />} />
      <Route path="/accountStripeLinked" element={<StripeConnectSuccess />} />
      <Route
        path="/teleconsultation/payment-success"
        element={<TeleconsultationPaymentSuccess />}
      />
      {isJobKineOrPodo ? (
        <>
          {hasTeleconsultationAccess && (
            <Route
              path="/teleconsultation/:id"
              element={<CurrentTeleconsultation />}
            />
          )}
          {hasTeleconsultationAccess && (
            <Route path="/teleconsultation" element={<Teleconsultation />} />
          )}

          <Route path="/patients/:patientId" element={<PatientKine />} />
          <Route
            path="/patients/:patientId/appointments/:appointmentId/edit-educational-content"
            element={<EditEducationalContent />}
          />
        </>
      ) : (
        <>
          <Route path="/patients/:patientId" element={<Patient />} />
        </>
      )}

      <Route
        path="/patients/:patientId/appointments/:appointmentId"
        element={<Appointment />}
      />
      <Route
        path="/patients/:patientId/appointments/:appointmentId/edit"
        element={<EditAppointment />}
      />
      <Route
        path="/patients/:patientId/appointments/:appointmentId/edit-explorer"
        element={<EditExplorerAppointment />}
      />

      <Route
        path="/patients/:patientId/treatment/:treatmentId"
        element={<Treatments />}
      />

      <Route
        path="/patients/:patientId/treatment/:treatmentId/appointment/:appointmentId"
        element={<Treatments />}
      />

      <Route
        path="/patients/:patientId/appointments/new/treament/:treatmentId"
        element={<NewAppointment />}
      />
      <Route
        path="/patient/teleconsultation/:id"
        element={<PatientTeleconsultation />}
      />
      <Route
        path="/patients/:patientId/appointments/new"
        element={<NewAppointment />}
      />
      <Route path="/programs/new" element={<NewProgram />} />
      <Route path="/messages" element={<Messages />} />
      <Route path="/messages/new" element={<NewMessage />} />
      <Route path="/messages/edit/:messageId" element={<EditMessage />} />

      <Route path="/" element={<Dashboard />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default ARoutes
