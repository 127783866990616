import useJobIsKineOrPodo from "hooks/useIsJobKineOrPodo"
import SubscribeKine from "pages/Subscribe/components/SubscribeKine"
import SubscribeOsteo from "pages/Subscribe/components/SubscribeOsteo"

const Subscribe = () => {
  const isKineOrPodo = useJobIsKineOrPodo()

  return isKineOrPodo ? <SubscribeKine /> : <SubscribeOsteo />
}

export default Subscribe
