import { extendTheme } from "@chakra-ui/react"
import { tooltipTheme } from "components/RedefTheme/tooltip"

const theme = extendTheme({
  colors: {
    primary: {
      100: "#feeddf",
      200: "#fdd7c0",
      300: "#fabba0",
      400: "#f5a087",
      500: "#ef7560",
      600: "#cd4f46",
      700: "#ac3031",
      800: "#8a1e28",
      900: "#721222",
    },
    secondary: {
      100: "#f1fdf3",
      200: "#e4fbea",
      300: "#d2f3de",
      400: "#c1e7d2",
      500: "#a8d8c3",
      600: "#7ab9a4",
      700: "#549b8a",
      800: "#357d73",
      900: "#206764",
    },
    tertiary: {
      100: "#f9e9da",
      200: "#f4cfb6",
      300: "#dfa68a",
      400: "#c07b64",
      500: "#974837",
      600: "#813128",
      700: "#6c1d1b",
      800: "#571115",
      900: "#480a12",
    },
    common: {
      100: "#F5F6F7",
      200: "#EBEDEE",
      300: "#CBCCCF",
      400: "#9B9C9F",
      500: "#5C5D5F",
      600: "#434751",
      700: "#2E3444",
      800: "#1D2337",
      900: "#11172D",
    },
    valid: {
      100: "#D1F8CD",
      200: "#9DF19D",
      300: "#66D772",
      400: "#3DB054",
      500: "#107C30",
      600: "#0B6A31",
      700: "#085930",
      800: "#05472C",
      900: "#033B29",
    },
    warning: {
      100: "#FCEDCB",
      200: "#FAD698",
      300: "#F1B663",
      400: "#E3953C",
      500: "#D16704",
      600: "#B34F02",
      700: "#963A02",
      800: "#792801",
      900: "#641C00",
    },
    danger: {
      100: "#FCDDD1",
      200: "#F9B5A5",
      300: "#EF8276",
      400: "#E05352",
      500: "#CC202E",
      600: "#AF1731",
      700: "#921032",
      800: "#760A30",
      900: "#61062E",
    },
    tomato: {
      300: "#FEEBE7",
      900: "#E54D2E",
    },
    osteow: {
      secondary: {
        200: "#FEEFC5",
        400: "#F9D690",
      },
    },
  },
  fonts: {
    heading: `'Montserrat', sans-serif`,
    body: `'Mulish', sans-serif`,
  },
  styles: {
    global: {
      "html, body": {
        color: "common.700",
      },
    },
  },
  components: {
    Tooltip: tooltipTheme,
  },
})

export default theme
