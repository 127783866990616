import EditSelectExercices from "pages/EditAppointment/components/EditSelectExercices"
import { useNavigate, useParams } from "react-router-dom"
import {
  SelectedExerciceType,
  SelectedUploadedExerciceType,
  SelectedVideoOrderType,
} from "api/appointmentReason"
import { updateAppointment } from "api/appointments"
import { useMutation, useQuery } from "@tanstack/react-query"
import useToast from "hooks/useToast"
import React, { useEffect, useState } from "react"
import { getPatient, PatientAppointmentType, PatientType } from "api/patient"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import { useAppContext } from "AppContext"
import { retryPatientNoAccessRight } from "utils/patientNoAccessRight"
import { retryCollaboratorUnauthorized } from "utils/collaboratorUnauthorized"
import useOnPatientNoAccessRight from "hooks/useOnPatientNoAccessRight"
import useOnCollaboratorUnauthorized from "hooks/useOnCollaboratorUnauthorized"

const EditAppointment = () => {
  const { patientId, appointmentId } = useParams()
  const { currentScope } = useAppContext()
  const updateAppointmentMutation = useMutation(updateAppointment)
  const toast = useToast()
  const navigate = useNavigate()
  const [selectedExercices, setSelectedExercices] = useState<
    SelectedExerciceType[]
  >([])
  const [selectedUploadedExercices, setSelectedUploadedExercices] = useState<
    SelectedUploadedExerciceType[]
  >([])
  const [selectedVideoOrder, setSelectedVideoOrder] = useState<
    SelectedVideoOrderType[]
  >([])
  const getPatientParams = {
    patientId,
    ...getCurrentScopeParams(currentScope),
  }
  const [appointment, setAppointment] =
    React.useState<PatientAppointmentType | null>(null)
  const onPatientNoAccessRight = useOnPatientNoAccessRight()
  const onCollaboratorUnauthorized = useOnCollaboratorUnauthorized()

  useQuery<PatientType>(
    ["getPatient", patientId],
    () => getPatient(getPatientParams),
    {
      enabled: !!patientId,
      retry: (failureCount, error: any) =>
        retryPatientNoAccessRight(failureCount, error) &&
        retryCollaboratorUnauthorized(failureCount, error),
      onSuccess: (data) => {
        setAppointment(
          !appointmentId
            ? null
            : data?.appointments?.find(
                (appointment) => appointment.id === parseInt(appointmentId)
              ) ?? null
        )
      },
      onError: (error: any) => {
        onPatientNoAccessRight(error)
        onCollaboratorUnauthorized(error)
      },
    }
  )

  useEffect(() => {
    if (appointment) {
      if (!appointment.trainingPlaylist) return
      const appointmentExercices: SelectedExerciceType[] =
        appointment.trainingPlaylist.exercices
          .map((exercice) => {
            if (!exercice.trainingVideoUploaded && exercice.osteoSelection) {
              const { osteoSelection, ...rest } = exercice
              return {
                ...rest,
                ...osteoSelection,
              }
            }
          })
          .filter((ex) => ex) as SelectedExerciceType[]
      const appointmentExercicesUploaded: SelectedUploadedExerciceType[] =
        appointment.trainingPlaylist.exercices
          .map((exercice) => {
            if (exercice.trainingVideoUploaded) {
              const { trainingVideoUploaded, ...rest } = exercice
              return {
                ...rest,
                ...trainingVideoUploaded,
              }
            }
          })
          .filter((ex) => ex) as SelectedUploadedExerciceType[]

      setSelectedExercices(appointmentExercices)
      setSelectedUploadedExercices(appointmentExercicesUploaded)
      setSelectedVideoOrder(
        appointment.trainingPlaylist.exercices.map((exercice) => {
          return {
            id: exercice.trainingVideoUploaded
              ? exercice.trainingVideoUploaded.id
              : exercice.osteoSelection!.id,
            type: exercice.trainingVideoUploaded ? "uploaded_video" : "video",
          }
          // return {
          //   id: exercice.trainingVideoUploaded : exercice.trainingVideoUploaded.id : exercice.osteoSelection.id,
          //   type: exercice.trainingVideoUploaded ? "uploaded_video" : "video",
          // }
        })
      )
    }
  }, [appointment])

  if (!appointment) return null

  const isAppointmentKineType =
    appointment.treatment &&
    appointment.job !== null &&
    (appointment.job.key === "kine" || appointment.job.key === "podo")

  const onBack = () => {
    if (isAppointmentKineType) {
      navigate(
        `/patients/${patientId}/treatment/${appointment.treatment?.id}/appointment/${appointment.id}`
      )
      return
    }

    navigate(`/patients/${patientId}/appointments/${appointmentId}`)
  }

  const onNext = async () => {
    if (appointment && appointment.id) {
      await updateAppointmentMutation.mutateAsync(
        {
          id: appointment.id.toString(),
          data: {
            videoExercices: selectedExercices.map((exercice) => ({
              duration: exercice.duration,
              videoGroup: exercice.group[0].id,
              osteoSelection: exercice.id,
              series: exercice.series,
              repetitions: exercice.repetitions,
              restDuration: exercice.restDuration,
              type: exercice.type,
              side: exercice.side,
              weight: exercice.weight,
            })),
            videoUploadedExercices: selectedUploadedExercices.map(
              (exercice) => ({
                duration: exercice.duration,
                series: exercice.series,
                repetitions: exercice.repetitions,
                restDuration: exercice.restDuration,
                type: exercice.type,
                weight: exercice.weight,
                trainingVideoUploaded: exercice.id,
              })
            ),
            videoOrder: selectedVideoOrder,
          },
        },
        {
          onSuccess: () => {
            toast({
              status: "success",
              title: "Modifications enregistrées",
            })
          },
          onError: () => {
            toast({
              status: "error",
              title: "Une erreur s'est produite",
            })
          },
        }
      )
      if (
        appointment.treatment &&
        appointment.job !== null &&
        (appointment.job.key === "kine" || appointment.job.key === "podo")
      ) {
        navigate(
          `/patients/${patientId}/treatment/${appointment.treatment?.id}/appointment/${appointment.id}`
        )
      } else {
        navigate(`/patients/${patientId}/appointments/${appointmentId}`)
      }
    }
  }

  if (appointment?.trainingPlaylist?.reasons?.length === 0)
    return (
      <EditSelectExercices
        onNext={onNext}
        onBack={onBack}
        selectedExercices={selectedExercices}
        selectedUploadedExercices={selectedUploadedExercices}
        setSelectedExercices={setSelectedExercices}
        setSelectedUploadedExercices={setSelectedUploadedExercices}
        selectedVideoOrder={selectedVideoOrder}
        setSelectedVideoOrder={setSelectedVideoOrder}
        isForKineOrPodo={isAppointmentKineType}
        reason={undefined}
      />
    )
  else
    return (
      <EditSelectExercices
        onNext={onNext}
        onBack={onBack}
        selectedExercices={selectedExercices}
        selectedUploadedExercices={selectedUploadedExercices}
        setSelectedExercices={setSelectedExercices}
        setSelectedUploadedExercices={setSelectedUploadedExercices}
        selectedVideoOrder={selectedVideoOrder}
        setSelectedVideoOrder={setSelectedVideoOrder}
        isForKineOrPodo={isAppointmentKineType}
        reason={undefined}
      />
    )
}
export default EditAppointment
