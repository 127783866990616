import { AddIcon } from "@chakra-ui/icons"
import {
  Box,
  Divider,
  FormLabel,
  Textarea,
  Image,
  Flex,
} from "@chakra-ui/react"
import AButton from "components/AButton"
import React, { useId } from "react"
import newNote from "../../../../../assets/icons/add_to_photos.svg"

type InternalNotesProps = {
  internalNote: string | null
  setInternalNote: React.Dispatch<React.SetStateAction<string | null>>
  secondaryInternalNote: string | null
  setSecondaryInternalNote: React.Dispatch<React.SetStateAction<string | null>>
}

const InternalNotes = ({
  internalNote,
  setInternalNote,
  secondaryInternalNote,
  setSecondaryInternalNote,
}: InternalNotesProps) => {
  const [hasInternalNote, setHasInternalNote] = React.useState(
    internalNote ? true : false
  )
  const [hasSecondaryInternalNote, setHasSecondaryInternalNote] =
    React.useState(secondaryInternalNote ? true : false)
  const textareaId = useId()
  return (
    <Flex
      direction="column"
      gap="16px"
      maxW="240px"
      px="16px"
      w="full"
      position="relative"
    >
      <Box
        borderLeft="1px solid"
        borderColor="primary.300"
        h="100vh"
        w="full"
        position="absolute"
        top="-90px"
        left="-16px"
      />
      {hasInternalNote ? (
        <Box>
          <FormLabel
            fontFamily="Montserrat"
            fontWeight="bold"
            htmlFor={textareaId}
            fontSize={14}
            mb={4}
          >
            Vos notes privées
          </FormLabel>
          <Textarea
            id={textareaId}
            placeholder="Note complémentaire..."
            value={internalNote || ""}
            bgColor="#FEEFC5"
            border="1px solid"
            borderColor="#F9D690"
            minHeight="184px"
            onChange={(e) => setInternalNote(e.target.value)}
          />
          {!hasSecondaryInternalNote && (
            <AButton
              text="Nouvelle note"
              mt={4}
              onClick={() => setHasSecondaryInternalNote(true)}
              leftIcon={<Image src={newNote} w="18px" h="18px" />}
              variant="tertiary"
              borderColor={"tertiary.500"}
              color={"tertiary.500"}
              w="full"
            />
          )}
        </Box>
      ) : (
        <AButton
          text="Nouvelle note"
          mb={12}
          onClick={() => setHasInternalNote(true)}
          leftIcon={<Image src={newNote} w="18px" h="18px" />}
          variant="tertiary"
          borderColor={"tertiary.500"}
          color={"tertiary.500"}
          w="full"
        />
      )}

      {hasSecondaryInternalNote && (
        <Box>
          <Textarea
            id={textareaId}
            placeholder="Note supplémentaire..."
            value={secondaryInternalNote || ""}
            bgColor="#FEEFC5"
            border="1px solid"
            borderColor="#F9D690"
            minHeight="184px"
            w="full"
            onChange={(e) => setSecondaryInternalNote(e.target.value)}
          />
        </Box>
      )}
    </Flex>
  )
}

export default InternalNotes
