import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import {
  deleteTeleconsultation,
  remindTeleconsultationPayment,
  TeleconsultationType,
} from "api/teleconsultation"
import AButton from "components/AButton"
import { addMinutes, format } from "date-fns"
import useToast from "hooks/useToast"
import { IoEllipsisHorizontalSharp } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import createICSContent from "utils/calendar/createICSEvent"
import downloadICS from "utils/calendar/downloadICS"
import formatDateTime from "utils/calendar/formatDateTime"
import getUtcDate from "utils/getUtcDate"

interface TeleconsultationCardProps {
  teleconsultation: TeleconsultationType
  isSelected?: boolean
  onClick?: () => void
  handleOpenEditModal: (teleconsultation: TeleconsultationType) => void
  patientId?: string
}
const TeleconsultationCard = ({
  teleconsultation,
  isSelected = false,
  onClick,
  handleOpenEditModal,
  patientId,
}: TeleconsultationCardProps) => {
  const toast = useToast()
  const now = new Date()
  const date = new Date(teleconsultation.scheduledDate)
  const day = date.getDate().toString()
  const options: Intl.DateTimeFormatOptions = { month: "short" }
  const month = date.toLocaleDateString("fr-FR", options)
  const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const canStart =
    isSelected && !teleconsultation.isFinished && !teleconsultation.isCancelled //&& date.toDateString() === now.toDateString()
  const deleteTeleconsultationMutation = useMutation(deleteTeleconsultation)
  const remindTeleconsultationPaymentMutation = useMutation(
    remindTeleconsultationPayment
  )
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure()

  const {
    isOpen: isOpenNoteModal,
    onClose: onCloseNoteModal,
    onOpen: onOpenNoteModal,
  } = useDisclosure()

  const canDownloadEvent = !teleconsultation.isFinished

  const handleAddToCalendar = () => {
    const endDateTime = addMinutes(date, 30)

    const event = {
      start: formatDateTime(date),
      end: formatDateTime(endDateTime),
      title: `Téléconsultation avec ${teleconsultation.patient.firstname} ${teleconsultation.patient.lastname}`,
      location: "En ligne",
      description: "Téléconsultation via l'application",
    }

    const icsContent = createICSContent(event)
    const uuid = crypto.randomUUID().slice(0, 5)
    downloadICS(icsContent, `teleconsultation_${uuid}.ics`)

    toast({
      title: "Événement téléchargé",
      description: "Vous pouvez maintenant l'ajouter à votre calendrier",
      status: "success",
      duration: 3000,
      isClosable: true,
    })
  }

  const dateFormatted = new Date(teleconsultation.scheduledDate)
  const time =
    dateFormatted.getHours().toString().padStart(2, "0") +
    ":" +
    dateFormatted.getMinutes().toString().padStart(2, "0")
  const handleDeleteTeleconsultation = () => {
    deleteTeleconsultationMutation.mutate(teleconsultation.id, {
      onSuccess: async () => {
        toast({
          title: "Téléconsultation annulée",
          status: "success",
        })
        onDeleteModalClose()
        await queryClient.invalidateQueries({
          queryKey: ["getAllTeleconsultation"],
        })
      },
    })
  }

  const handleRetryTeleconsultationPayment = () => {
    remindTeleconsultationPaymentMutation.mutate(teleconsultation.id, {
      onSuccess: (data) => {
        if (data.success) {
          toast({
            title: data.message,
            status: "success",
          })
        } else {
          toast({
            title: data.message,
            status: "error",
          })
        }
      },
      onError: () => {
        toast({
          title: "Une erreur est survenue lors de la relance du paiement.",
          status: "error",
        })
      },
    })
  }

  return (
    <Flex
      bg={"white"}
      borderRadius={10}
      padding={"8px"}
      direction={"column"}
      w={"310px"}
      border={isSelected ? "1px solid" : "none"}
      borderColor={"tomato.900"}
      onClick={onClick}
      cursor={isSelected ? "default" : "pointer"}
      gap={"8px"}
    >
      {patientId !== undefined && teleconsultation.isFinished ? (
        <Flex w={"full"} justify={"space-between"} p={2} position={"relative"}>
          <Text fontWeight={"bold"}>
            {format(getUtcDate(teleconsultation.scheduledDate), "dd/MM/yyyy")}
          </Text>
          <Text>
            {teleconsultation.isPaid ? "Réglée" : "Paiement en attente"}
          </Text>
          {isSelected &&
            !teleconsultation.isPaid &&
            !teleconsultation.isCancelled && (
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<IoEllipsisHorizontalSharp />}
                  position="absolute"
                  top={-3}
                  right={-3}
                  onClick={(e) => e.stopPropagation()}
                  bg={"transparent"}
                  _hover={{ bg: "transparent" }}
                  _focus={{ bg: "transparent" }}
                  _active={{ bg: "transparent" }}
                />
                <MenuList>
                  {teleconsultation.isFinished && !teleconsultation.isPaid && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation()
                        handleRetryTeleconsultationPayment()
                      }}
                    >
                      Relancer le paiement
                    </MenuItem>
                  )}
                  {teleconsultation.privateNote && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation()
                        onOpenNoteModal()
                      }}
                    >
                      Voir la note
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            )}
        </Flex>
      ) : (
        <Flex w={"full"}>
          <Flex justify={"start"} gap={"12px"} position={"relative"} w={"full"}>
            <Flex
              direction={"column"}
              bg={"tomato.300"}
              borderRadius={8}
              fontWeight={"bold"}
              alignItems={"center"}
              justify={"center"}
              minW={"56px"}
              minH={"56px"}
            >
              <Text fontSize={"18px"}>{day}</Text>
              <Text fontSize={"12px"}>{capitalizedMonth}</Text>
            </Flex>
            <Flex
              direction={"column"}
              fontSize={"14px"}
              justify={"center"}
              maxW={"65%"}
            >
              {patientId === undefined && (
                <Flex
                  gap={2}
                  fontWeight={"bold"}
                  w={"full"}
                  overflow={"hidden"}
                >
                  <Text>{teleconsultation.patient.firstname}</Text>
                  <Text>{teleconsultation.patient.lastname}</Text>
                </Flex>
              )}
              <Text w={"full"}>{time}</Text>
            </Flex>

            {isSelected && !teleconsultation.isCancelled && (
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<IoEllipsisHorizontalSharp />}
                  position="absolute"
                  top={-2}
                  right={-2}
                  onClick={(e) => e.stopPropagation()}
                  bg={"transparent"}
                  _hover={{ bg: "transparent" }}
                  _focus={{ bg: "transparent" }}
                  _active={{ bg: "transparent" }}
                />
                <MenuList>
                  {patientId === undefined && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation()
                        navigate(`/patients/${teleconsultation.patient.id}`)
                      }}
                    >
                      Voir la fiche patient
                    </MenuItem>
                  )}
                  {canDownloadEvent && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation()
                        handleAddToCalendar()
                      }}
                    >
                      Télécharger l'évènement
                    </MenuItem>
                  )}
                  {!teleconsultation.isFinished && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation()
                        handleOpenEditModal(teleconsultation)
                      }}
                    >
                      Éditer
                    </MenuItem>
                  )}
                  {teleconsultation.privateNote && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation()
                        onOpenNoteModal()
                      }}
                    >
                      Voir la note privée
                    </MenuItem>
                  )}
                  {teleconsultation.isFinished && !teleconsultation.isPaid && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation()
                        handleRetryTeleconsultationPayment()
                      }}
                    >
                      Relancer le paiement
                    </MenuItem>
                  )}
                  {!teleconsultation.isFinished && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation()
                        onDeleteModalOpen()
                      }}
                    >
                      Annuler
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            )}
          </Flex>
        </Flex>
      )}

      {canStart && (
        <AButton
          text="Rejoindre"
          variant="kine"
          onClick={() => navigate(`/teleconsultation/${teleconsultation.uid}`)}
        />
      )}
      <Modal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW={500} p={4}>
          <ModalHeader>Annuler la téléconsultation</ModalHeader>
          <ModalBody>
            Etes-vous sûr de vouloir annuler la téléconsultation ? Un email sera
            envoyé au patient pour l’avertir
          </ModalBody>
          <ModalFooter gap={4}>
            <AButton
              text="Annuler"
              variant="tertiary"
              py={3}
              px={27}
              onClick={onDeleteModalClose}
            />
            <AButton
              text="Confirmer"
              variant="primary"
              py={3}
              px={4}
              onClick={handleDeleteTeleconsultation}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenNoteModal} onClose={onCloseNoteModal} isCentered>
        <ModalOverlay />
        <ModalContent maxW={500} p={4}>
          <ModalHeader>Note privée</ModalHeader>
          <ModalBody>{teleconsultation.privateNote}</ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
export default TeleconsultationCard
