import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/react"
import useToast from "../../../hooks/useToast"
import { useMutation } from "@tanstack/react-query"
import React from "react"
import { PatientAppointmentType } from "../../../api/patient"
import AButton from "../../../components/AButton"
import { updateAppointment } from "api/appointments"

interface IProps {
  isOpen: boolean
  onClose: () => void
  cancelRef: React.MutableRefObject<null>
  appointment?: PatientAppointmentType
  setAppointment?: React.Dispatch<
    React.SetStateAction<PatientAppointmentType | null>
  >
}

const NewPlaylistValidity: React.FC<IProps> = ({
  isOpen,
  onClose,
  cancelRef,
  appointment,
  setAppointment,
}) => {
  const updateAppointmentMutation = useMutation(updateAppointment)

  const toast = useToast()

  const onSubmit = async () => {
    if (appointment && appointment.id) {
      await updateAppointmentMutation.mutateAsync(
        {
          id: appointment.id.toString(),
          data: {
            expiry: 2,
          },
        },
        {
          onSuccess: () => {
            toast({
              status: "success",
              title: "Modifications enregistrées",
            })
            onClose()
          },
          onError: () => {
            toast({
              status: "error",
              title: "Une erreur s'est produite",
            })
          },
        }
      )
    }
  }

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent maxW={724} borderRadius={8} p={8}>
        <AlertDialogHeader mb={4} p={0} fontSize={18}>
          Prolonger la date d'expiration des exercices conseillés
        </AlertDialogHeader>
        <AlertDialogBody p={0}>
          Le patient pourra accéder à sa playlist encore 2 mois supplémentaires
          à partir d'aujourd'hui
        </AlertDialogBody>
        <AlertDialogFooter justifyContent="flex-end" p={0} mt={6}>
          <AButton
            ref={cancelRef}
            onClick={onClose}
            text="Annuler"
            variant="tertiary"
            py={3}
            px={27}
          />
          <AButton
            ml={3}
            text="Enregistrer les modifications"
            py={3}
            px={4}
            onClick={onSubmit}
            type="submit"
          />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default NewPlaylistValidity
