import { getSubscriptions, StripeProductType } from "api/subscriptions"
import { useQuery } from "@tanstack/react-query"
import { useAppContext } from "AppContext"
import userAdminsCenterGroupInCurrentScope from "utils/currentScope/userAdminsCenterGroupInCurrentScope"
import userHasSubscription from "utils/subscription/userHasSubscription"
import OsteoSubscriptionBilling from "components/Billing/Osteo/SubscriptionBilling"
import CenterSubscriptionBilling from "components/Billing/Center/SubscriptionBilling"
import useJobIsOsteo from "hooks/useJobIsOsteo"
import useJobIsKine from "hooks/useJobIsKine"

const Billing = () => {
  const { user, currentScope, hasTeleconsultationAccess } = useAppContext()

  const { data: stripeProducts, isLoading: isLoadingStripeProducts } = useQuery<
    StripeProductType[]
  >(["getSubscriptions"], getSubscriptions)

  const userHasIndividualSubscription = userHasSubscription(user)
  const isCenterAdmin = userAdminsCenterGroupInCurrentScope(user, currentScope)
  const isOsteo = useJobIsOsteo()
  const isKine = useJobIsKine()
  if (isOsteo) {
    if (userHasIndividualSubscription) {
      return (
        <OsteoSubscriptionBilling
          type="osteo"
          isLoadingStripeProducts={isLoadingStripeProducts}
          stripeProducts={stripeProducts}
        />
      )
    }

    if (isCenterAdmin) {
      return (
        <CenterSubscriptionBilling
          type="osteo"
          isLoadingStripeProducts={isLoadingStripeProducts}
          stripeProducts={stripeProducts}
        />
      )
    }
  }
  if (hasTeleconsultationAccess) {
    if (userHasIndividualSubscription) {
      return (
        <OsteoSubscriptionBilling
          type={isKine ? "kine_teleconsultation" : "podo_teleconsultation"}
          isLoadingStripeProducts={isLoadingStripeProducts}
          stripeProducts={stripeProducts}
        />
      )
    }
    if (isCenterAdmin) {
      return (
        <CenterSubscriptionBilling
          type={isKine ? "kine_teleconsultation" : "podo_teleconsultation"}
          isLoadingStripeProducts={isLoadingStripeProducts}
          stripeProducts={stripeProducts}
        />
      )
    }
  }

  if (userHasIndividualSubscription) {
    return (
      <OsteoSubscriptionBilling
        type={isKine ? "kine" : "podo"}
        stripeProducts={stripeProducts}
        isLoadingStripeProducts={isLoadingStripeProducts}
      />
    )
  }
  if (isCenterAdmin) {
    return (
      <CenterSubscriptionBilling
        type={isKine ? "kine" : "podo"}
        stripeProducts={stripeProducts}
        isLoadingStripeProducts={isLoadingStripeProducts}
      />
    )
  }

  return null
}

export default Billing
