import { Box, Flex, Heading, Image, Link, Text } from "@chakra-ui/react"
import React from "react"
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import Stats from "./components/Stats"
import arrowBack from "../../assets/arrow-back.svg"
import Infos from "./components/Infos"
import LastAppointment from "./components/LastAppointment"
import { useQuery } from "@tanstack/react-query"
import { getPatient, PatientType } from "../../api/patient"
import ASkeleton from "../../components/ASkeleton"
import AButton from "../../components/AButton"
import { AddIcon } from "@chakra-ui/icons"
import AppointmentCard from "./components/AppointmentCard"
import { format, isBefore } from "date-fns"
import { useAppContext } from "AppContext"
import useOnPatientNoAccessRight from "hooks/useOnPatientNoAccessRight"
import { retryPatientNoAccessRight } from "utils/patientNoAccessRight"
import userHasNoPersonalAccount from "../../utils/user/userHasNoPersonalAccount"
import userHasPatientOnDifferentAccount from "utils/multi/userHasPatientOnDifferentAccount"
import useJobIsOsteo from "hooks/useJobIsOsteo"

const Patient = () => {
  const navigate = useNavigate()
  const { patientId, appointmentId } = useParams()
  const { currentScope, user, setCurrentScope, setCenterAdminCurrentScope } =
    useAppContext()
  const [patient, setPatient] = React.useState<PatientType | undefined>(
    undefined
  )
  const onPatientNoAccessRight = useOnPatientNoAccessRight()
  const [searchParams] = useSearchParams()
  const osteoId = searchParams.get("osteoId")
  const centerId = searchParams.get("centerId")
  const academyId = searchParams.get("academyId")
  const hasPersonalAccount = !userHasNoPersonalAccount(user)
  const patientParams = userHasPatientOnDifferentAccount({
    osteoId,
    centerId,
    academyId,
    hasPersonalAccount,
    user,
    currentScope,
    setCurrentScope,
    setCenterAdminCurrentScope,
    patientId,
  })

  const { isLoading } = useQuery<PatientType>(
    ["getPatient", patientId],
    () => getPatient(patientParams),
    {
      enabled: !!patientId,
      retry: (failureCount, error) =>
        retryPatientNoAccessRight(failureCount, error),
      onSuccess: (data) => {
        setPatient(data)
        if (!data || !data.appointments) return
        const lastAppointmentIndex = data.appointments.length - 1
        const lastAppointment = data.appointments?.[lastAppointmentIndex]
        if (lastAppointment) {
          navigate(`/patients/${data.id}/appointments/${lastAppointment.id}`)
        }
      },
      onError: (error: any) => onPatientNoAccessRight(error),
    }
  )
  const canCreateAppointmentForOsteo = useJobIsOsteo()
  return (
    <Box flex={1} mr={6}>
      <Link as={RouterLink} to="/dashboard">
        <Flex alignItems="center">
          <Image src={arrowBack} mr={2} />
          <Text>Retour</Text>
        </Flex>
      </Link>

      <Flex>
        <Box flex={1}>
          <ASkeleton isLoaded={!isLoading}>
            <Heading as="h1" mt={7} mb={8} fontSize={28} color="tertiary.500">
              {patient?.firstname} {patient?.lastname}
            </Heading>
          </ASkeleton>
          <Flex>
            <Infos
              isLoading={isLoading}
              patient={patient}
              setPatient={setPatient}
              marginRight={4}
            />
            <LastAppointment patient={patient} />
          </Flex>
          <Stats patient={patient} />
        </Box>
        <Box w={348} ml={6}>
          {canCreateAppointmentForOsteo && (
            <AButton
              text="Créer une consultation"
              px={120}
              py={3}
              mt={3}
              mb={10}
              w="100%"
              fontFamily="Montserrat"
              onClick={() => {
                navigate(`/patients/${patientId}/appointments/new`)
              }}
              leftIcon={<AddIcon fontSize={12} />}
            />
          )}

          <Box py={4} pl={4} pr={27} bg="primary.200" borderRadius={9}>
            <Heading mb={3} fontSize={18}>
              Consultations
            </Heading>
            {patient?.appointments
              ?.sort((a, b) =>
                isBefore(new Date(a.createdAt), new Date(b.createdAt)) ? 1 : -1
              )
              .map(({ id, meetingDate, osteo, academie, center }) => (
                <AppointmentCard
                  key={id}
                  link={`/patients/${patientId}/appointments/${id}`}
                  date={format(new Date(meetingDate), "dd/MM/yyyy")}
                  name={`${osteo.firstname} ${osteo.lastname}`}
                  academie={academie}
                  center={center}
                  isSelected={false}
                />
              ))}
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default Patient
